export default {
  path: 'club/information',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'club_information',
      component: require('@club-information/js/components/ClubInformationList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'club_information_create',
      component: require('@club-information/js/components/ClubInformationCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'club_information_edit',
      component: require('@club-information/js/components/ClubInformationEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
