<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('pages.titles.list') }}
        </h3>
        <div class="card-options" v-if="this.$app.user.can('create pages')">
          <b-button @click="modalCreate" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('pages.buttons.create') }}
          </b-button>
        </div>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="pages.search"
        delete-route="pages.destroy"
        action-route="pages.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="true"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(name)="row">
            <router-link
              v-if="row.item.can_edit"
              :to="`/pages/${row.item.id}/edit`"
              v-text="row.value"
            ></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template v-slot:cell(header)="row">
            <c-switch
              v-if="row.item.can_edit"
              :checked="row.value"
              @change="onHeaderToggle(row.item.id)"
            ></c-switch>
          </template>
          <template v-slot:cell(footer)="row">
            <c-switch
              v-if="row.item.can_edit"
              :checked="row.value"
              @change="onFooterToggle(row.item.id)"
            ></c-switch>
          </template>
          <template v-slot:cell(enabled)="row">
            <c-switch
              v-if="row.item.can_edit"
              :checked="row.value"
              @change="onActiveToggle(row.item.id)"
            ></c-switch>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              v-if="row.item.can_edit"
              size="sm"
              variant="primary"
              :to="`/pages/${row.item.id}/edit`"
              v-b-tooltip.hover
              :title="$t('buttons.edit')"
              class="mr-1"
            >
              <i class="fe fe-edit"></i>
            </b-button>
            <b-button
              v-if="row.item.can_delete && row.item.deletable"
              size="sm"
              variant="danger"
              v-b-tooltip.hover
              :title="$t('buttons.delete')"
              @click.stop="onDelete(row.item.id)"
            >
              <i class="fe fe-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
    <modals-container></modals-container>
  </div>
</template>

<script>
import axios from 'axios'
import PageCreateForm from './PageCreateForm.vue'
export default {
  title: '',
  name: 'PageList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'name',
          label: this.$t('validation.attributes.name'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'order',
          label: this.$t('validation.attributes.order'),
          class: 'text-right data-position',
          sortable: true
        },
        {
          key: 'identifier',
          label: this.$t('validation.attributes.identifier'),
          class: 'text-center'
        },
        {
          key: 'header',
          label: this.$t('pages.labels.header'),
          class: 'text-center'
        },
        {
          key: 'footer',
          label: this.$t('pages.labels.footer'),
          class: 'text-center'
        },
        {
          key: 'enabled',
          label: this.$t('validation.attributes.enabled'),
          class: 'text-center'
        },
        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('pages.actions.destroy'),
        enable: this.$t('pages.actions.enable'),
        disable: this.$t('pages.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('pages.titles.list')
  },
  methods: {
    modalCreate() {
      this.$modal.show(
        PageCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ page: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('pages.enabled', { page: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onHeaderToggle(id) {
      axios
        .post(this.$app.route('pages.header', { page: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onFooterToggle(id) {
      axios
        .post(this.$app.route('pages.footer', { page: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    }
  }
}
</script>
