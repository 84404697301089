<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('geo.titles.list') }}
        </h3>
        <div class="card-options" v-if="this.$app.user.can('create countries')">
          <!--<b-button @click="modalCreate" variant="success" size="sm">
           <i class="fas fa-plus"></i>
            {{ $t('countries.buttons.create') }}
          </b-button>-->
        </div>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="countries.search"
        delete-route="countries.destroy"
        action-route="countries.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="name"
          :sort-desc="false"
        >
          <template v-slot:cell(name)="row">
            <div>
              <a
                href="#"
                @click="CountryRecord(row.item.id)"
                class=""
                v-if="row.item.can_edit"
              >
                {{ row.value }}
              </a>
              <span v-else v-text="row.value"></span>
            </div>
            <div v-text="row.item.native"></div>
          </template>
          <template v-slot:cell(flag)="row">
            <img
              class="country-flag"
              :src="getFlag(row.item.iso_code)"
              :alt="row.item.name"
            />
          </template>
          <template v-slot:cell(continent)="row">
            <span
              v-if="row.item.continent.name"
              v-text="row.item.continent.name"
            ></span>
          </template>
          <template v-slot:cell(states)="row">
            <div>
              <b-button
                v-if="row.item.can_edit"
                :to="`/countries/${row.item.id}/states`"
                class="ipsAction ipsAction-150"
              >
                {{ $t('geo.buttons.states') }}
                <span class="btn-icon btn-icon-right">
                  <i class="fas fa-chevron-right"></i>
                </span>
              </b-button>
            </div>
            <div class="st-count-wrap">
              <div class="st-count st-count-total">
                <span
                  v-b-tooltip.hover
                  :title="$t('geo.tooltips.states_count')"
                >
                  {{ row.item.statistic.states_count }}
                </span>
              </div>
              <div
                class="st-count "
                :class="
                  row.item.statistic.states_active_count > 0
                    ? 'st-active'
                    : 'st-active-null'
                "
              >
                <span
                  v-b-tooltip.hover
                  :title="$t('geo.tooltips.states_active_count')"
                >
                  {{ row.item.statistic.states_active_count }}
                </span>
              </div>
              <div
                class="st-count"
                :class="
                  row.item.statistic.states_inactive_count > 0
                    ? 'st-inactive'
                    : 'st-inactive-null'
                "
              >
                <span
                  v-b-tooltip.hover
                  :title="$t('geo.tooltips.states_inactive_count')"
                >
                  {{ row.item.statistic.states_inactive_count }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell(cities)="row">
            <div>
              <b-button
                v-if="row.item.can_edit"
                :to="`/countries/${row.item.id}/cities`"
                class="ipsAction ipsAction-150"
              >
                {{ $t('geo.buttons.cities') }}
                <span class="btn-icon btn-icon-right">
                  <i class="fas fa-chevron-right"></i>
                </span>
              </b-button>
            </div>
            <div class="st-count-wrap">
              <div class="st-count st-count-total">
                <span
                  v-b-tooltip.hover
                  :title="$t('geo.tooltips.cities_count')"
                >
                  {{ row.item.statistic.cities_count }}
                </span>
              </div>
              <div
                class="st-count"
                :class="
                  row.item.statistic.cities_active_count > 0
                    ? 'st-active'
                    : 'st-active-null'
                "
              >
                <span
                  v-b-tooltip.hover
                  :title="$t('geo.tooltips.cities_active_count')"
                >
                  {{ row.item.statistic.cities_active_count }}
                </span>
              </div>
              <div
                class="st-count"
                :class="
                  row.item.statistic.cities_inactive_count > 0
                    ? 'st-inactive'
                    : 'st-inactive-null'
                "
              >
                <span
                  v-b-tooltip.hover
                  :title="$t('geo.tooltips.cities_inactive_count')"
                >
                  {{ row.item.statistic.cities_inactive_count }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell(geo)="row">
            <div>
              <b-button
                v-if="row.item.can_edit"
                :to="`/countries/${row.item.id}/geo`"
                class="ipsAction ipsAction-150"
              >
                {{ $t('geo.buttons.geo-data') }}
                <span class="btn-icon btn-icon-right">
                  <i class="fas fa-chevron-right"></i>
                </span>
              </b-button>
            </div>
            <div class="st-count-wrap">
              <div class="st-count st-count-total">
                <span v-b-tooltip.hover :title="$t('geo.tooltips.geo_count')">
                  {{ row.item.statistic.geo_count }}
                </span>
              </div>
              <div
                class="st-count"
                :class="
                  row.item.statistic.geo_active_count > 0
                    ? 'st-active'
                    : 'st-active-null'
                "
              >
                <span
                  v-b-tooltip.hover
                  :title="$t('geo.tooltips.geo_active_count')"
                >
                  {{ row.item.statistic.geo_active_count }}
                </span>
              </div>
              <div
                class="st-count"
                :class="
                  row.item.statistic.geo_inactive_count > 0
                    ? 'st-inactive'
                    : 'st-inactive-null'
                "
              >
                <span
                  v-b-tooltip.hover
                  :title="$t('geo.tooltips.geo_inactive_count')"
                >
                  {{ row.item.statistic.geo_inactive_count }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell(enabled)="row">
            <c-switch
              v-if="row.item.can_edit"
              :checked="row.value"
              @change="onActiveToggle(row.item.id)"
            ></c-switch>
          </template>
          <template v-slot:cell(area)="row">
            <span v-text="row.value"></span> km²
          </template>
          <template v-slot:cell(checked)="row">
            <span v-if="row.value" class="badge badge-success">
              {{ trans('core.label.yes') }}
            </span>
            <span v-else class="badge badge-danger">
              {{ trans('core.label.no') }}
            </span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              size="sm"
              variant="primary"
              v-b-tooltip.hover
              :title="trans('core.button.edit')"
              class="mr-1"
              @click="CountryRecord(row.item.id)"
            >
              <i class="fas fa-edit"></i>
            </b-button>
            <b-dropdown
              size="sm"
              text=""
              class="adm-btn-dropdown action-btn-dropdown hidden"
            >
              <b-dropdown-item @click="CountryAdjust(row.item.id)">
                <span class="icon-holder">
                  <i class="fas fa-sliders-h"></i>
                </span>
                <span class="label-holder">{{
                  trans('geo.buttons.adjust')
                }}</span>
              </b-dropdown-item>
              <!--              <b-dropdown-item @click.stop="onDelete(row.item.id)">
                <span class="icon-holder icon-danger">
                  <i class="fas fa-trash-alt"></i>
                </span>
                <span class="label-holder label-delete">{{
                  trans('core.button.delete')
                }}</span>
              </b-dropdown-item>-->
            </b-dropdown>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import CountryCreateForm from './CountryCreateForm.vue'
import CountryRecord from '@geo/js/components/modals/CountryRecord'
import CountryAdjust from '@geo/js/components/modals/CountryAdjust'
export default {
  title: '',
  name: 'CountryList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'name',
          label: this.$t('geo.labels.country_name'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'flag',
          label: this.$t('geo.labels.country_flag'),
          sortable: true,
          class: 'data-flag'
        },
        {
          key: 'iso_code',
          label: this.$t('geo.labels.code'),
          sortable: true,
          class: 'data-code'
        },
        {
          key: 'capital',
          label: this.$t('geo.labels.capital'),
          sortable: true,
          class: 'data-capital'
        },
        {
          key: 'continent',
          label: this.$t('geo.labels.continent'),
          sortable: true,
          class: 'data-continent'
        },
        {
          key: 'geo',
          label: this.$t('geo.buttons.geo-data'),
          sortable: true,
          class: 'data-geo'
        },
        /*        {
          key: 'states',
          label: this.$t('geo.labels.country_states'),
          sortable: true,
          class: 'data-states'
        },*/
        {
          key: 'slug',
          label: this.$t('geo.labels.country-slug'),
          sortable: true,
          class: 'data-name text-left'
        },
        {
          key: 'cities',
          label: this.$t('geo.labels.country_cities'),
          sortable: true,
          class: 'data-cities'
        },
        /*        {
          key: 'rates',
          label: 'Rates',
          sortable: true,
          class: 'data-name'
        },*/
        /*        {
          key: 'phone',
          label: this.$t('geo.labels.country_phone'),
          sortable: true,
          class: 'data-phone'
        },*/
        {
          key: 'enabled',
          label: this.$t('validation.attributes.enabled'),
          sortable: true,
          class: 'text-center'
        },
        {
          key: 'checked',
          label: 'Checked',
          sortable: true,
          class: 'text-center'
        },

        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('geo.actions.destroy'),
        enable: this.$t('geo.actions.enable'),
        disable: this.$t('geo.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('geo.titles.list')
  },
  methods: {
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    modalCreate() {
      this.$modal.show(
        CountryCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ country: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('countries.enabled', { country: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    CountryRecord(id) {
      this.$modal.show(
        CountryRecord,
        {
          modal: true,
          id: id
        },
        {
          name: 'country-record-modal',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    CountryAdjust(id) {
      this.$modal.show(
        CountryAdjust,
        {
          modal: true,
          id: id
        },
        {
          name: 'country-adjust-modal',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: 8px;
}
</style>
