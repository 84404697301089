import axios from 'axios'

export function createActions(route) {
  return {
    LOAD_COUNTERS: ({ commit }) => {
      return new Promise(resolve => {
        axios
          .all([
            axios.get(route('escorts.pending.counter')),
            axios.get(route('escorts.revise.counter')),
            axios.get(route('escorts.draft.counter')),
            axios.get(route('escorts.correct.counter')),
            axios.get(route('escorts.suspended.counter')),
            axios.get(route('escorts.all.counter')),
            axios.get(route('agencies.pending.counter')),
            axios.get(route('agencies.revise.counter')),
            axios.get(route('agencies.draft.counter')),
            axios.get(route('agencies.correct.counter')),
            axios.get(route('agencies.suspended.counter')),
            axios.get(route('agencies.all.counter')),
            axios.get(route('brothels.pending.counter')),
            axios.get(route('brothels.revise.counter')),
            axios.get(route('brothels.draft.counter')),
            axios.get(route('brothels.correct.counter')),
            axios.get(route('brothels.suspended.counter')),
            axios.get(route('brothels.all.counter')),
            axios.get(route('parlours.pending.counter')),
            axios.get(route('parlours.revise.counter')),
            axios.get(route('parlours.draft.counter')),
            axios.get(route('parlours.correct.counter')),
            axios.get(route('parlours.suspended.counter')),
            axios.get(route('parlours.all.counter')),
            axios.get(route('clubs.pending.counter')),
            axios.get(route('clubs.revise.counter')),
            axios.get(route('clubs.draft.counter')),
            axios.get(route('clubs.correct.counter')),
            axios.get(route('clubs.suspended.counter')),
            axios.get(route('clubs.all.counter'))
          ])
          .then(
            axios.spread(
              (
                pendingEscortsCount,
                reviseEscortsCount,
                draftEscortsCount,
                correctEscortsCount,
                suspendedEscortsCount,
                allEscortsCount,
                pendingAgenciesCount,
                reviseAgenciesCount,
                draftAgenciesCount,
                correctAgenciesCount,
                suspendedAgenciesCount,
                allAgenciesCount,
                pendingBrothelsCount,
                reviseBrothelsCount,
                draftBrothelsCount,
                correctBrothelsCount,
                suspendedBrothelsCount,
                allBrothelsCount,
                pendingParloursCount,
                reviseParloursCount,
                draftParloursCount,
                correctParloursCount,
                suspendedParloursCount,
                allParloursCount,
                pendingClubsCount,
                reviseClubsCount,
                draftClubsCount,
                correctClubsCount,
                suspendedClubsCount,
                allClubsCount
              ) => {
                commit('SET_COUNTER', {
                  type: 'pendingEscortsCount',
                  counter: pendingEscortsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'reviseEscortsCount',
                  counter: reviseEscortsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'draftEscortsCount',
                  counter: draftEscortsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'correctEscortsCount',
                  counter: correctEscortsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'suspendedEscortsCount',
                  counter: suspendedEscortsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'allEscortsCount',
                  counter: allEscortsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'pendingAgenciesCount',
                  counter: pendingAgenciesCount.data
                })
                commit('SET_COUNTER', {
                  type: 'reviseAgenciesCount',
                  counter: reviseAgenciesCount.data
                })
                commit('SET_COUNTER', {
                  type: 'draftAgenciesCount',
                  counter: draftAgenciesCount.data
                })
                commit('SET_COUNTER', {
                  type: 'correctAgenciesCount',
                  counter: correctAgenciesCount.data
                })
                commit('SET_COUNTER', {
                  type: 'suspendedAgenciesCount',
                  counter: suspendedAgenciesCount.data
                })
                commit('SET_COUNTER', {
                  type: 'allAgenciesCount',
                  counter: allAgenciesCount.data
                })
                commit('SET_COUNTER', {
                  type: 'pendingBrothelsCount',
                  counter: pendingBrothelsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'reviseBrothelsCount',
                  counter: reviseBrothelsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'draftBrothelsCount',
                  counter: draftBrothelsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'correctBrothelsCount',
                  counter: correctBrothelsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'suspendedBrothelsCount',
                  counter: suspendedBrothelsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'allBrothelsCount',
                  counter: allBrothelsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'pendingParloursCount',
                  counter: pendingParloursCount.data
                })
                commit('SET_COUNTER', {
                  type: 'reviseParloursCount',
                  counter: reviseParloursCount.data
                })
                commit('SET_COUNTER', {
                  type: 'draftParloursCount',
                  counter: draftParloursCount.data
                })
                commit('SET_COUNTER', {
                  type: 'correctParloursCount',
                  counter: correctParloursCount.data
                })
                commit('SET_COUNTER', {
                  type: 'suspendedParloursCount',
                  counter: suspendedParloursCount.data
                })
                commit('SET_COUNTER', {
                  type: 'allParloursCount',
                  counter: allParloursCount.data
                })
                commit('SET_COUNTER', {
                  type: 'pendingClubsCount',
                  counter: pendingClubsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'reviseClubsCount',
                  counter: reviseClubsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'draftClubsCount',
                  counter: draftClubsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'correctClubsCount',
                  counter: correctClubsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'suspendedClubsCount',
                  counter: suspendedClubsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'allClubsCount',
                  counter: allClubsCount.data
                })

                resolve()
              }
            )
          )
      })
    }
  }
}
