export default {
  path: 'parlour/ethnicity',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'parlour_ethnicity',
      component: require('@parlour-ethnicity/js/components/ParlourEthnicityList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'parlour_ethnicity_create',
      component: require('@parlour-ethnicity/js/components/ParlourEthnicityCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'parlour_ethnicity_edit',
      component: require('@parlour-ethnicity/js/components/ParlourEthnicityEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
