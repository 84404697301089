import axios from 'axios'

export default {
  props: ['id'],
  data() {
    return {
      validation: {},
      continueEditingState: false,
      pending: false
    }
  },
  computed: {},
  methods: {
    async fetchData() {},
    onModelChanged() {},
    initContinue() {
      this.continueEditingState = true
    },
    feedback(name) {
      if (this.state(name)) {
        return this.validation.errors[name][0]
      }
    },
    state(name) {
      return this.validation.errors !== undefined &&
        this.validation.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    async onSubmit() {
      this.pending = true
      let router = this.$router
      let action = this.$app.route(`${this.resourceRoute}.update`, {
        [this.modelName]: this.id
      })

      let formData = this.$app.objectToFormData(this.model)

      if (!this.isNew) {
        formData.append('_method', 'PATCH')
      }
      try {
        let { data } = await axios.post(action, formData)

        this.pending = false

        this.$app.noty[data.status](data.message)

        if (this.isModal) {
          this.$emit('context-changed')
        }

        if (this.continueEditingState) {
          this.validation.errors = []
          this.fetchData()
        }
      } catch (e) {
        this.pending = false

        // Validation errors
        if (e.response.status === 422) {
          // console.log(e.response.data)
          this.validation = e.response.data
          return
        }

        this.$app.error(e)
      }
    }
  },
  created() {
    this.$eventHub.$on('continue-editing', this.initContinue)
    this.fetchData()
  }
}
