export default {
  path: 'clubs',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'clubs',
      component: require('@clubs/js/components/ClubList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified',
      name: 'clubs',
      component: require('@clubs/js/components/ClubUnverifiedList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'clubs_create',
      component: require('@clubs/js/components/ClubCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'clubs_edit',
      component: require('@clubs/js/components/ClubEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified/:id/edit',
      name: 'clubs_unverified_edit',
      component: require('@clubs/js/components/ClubEditUnverifiedForm').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
