<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              Add Coins
            </h3>
            <div>
              <div role="group" class="form-row form-group form-row-info">
                <label class="col-3 col-form-label">
                  Recipient:
                </label>
                <div class="col-form-label col-form-label-value">
                  {{ recipient }}
                </div>
              </div>

              <div role="group" class="form-row form-group form-row-info">
                <label class="col-3 col-form-label">
                  Current coins balance:
                </label>
                <div class="col-form-label col-form-label-value">
                  {{ balance }}
                </div>
              </div>

              <b-form-group
                label="Coins to add * :"
                label-for="amount"
                label-cols-sm="3"
                description="The number of coins greater than zero must be specified "
              >
                <b-row>
                  <b-col md="5">
                    <b-input-group>
                      <b-input-group-prepend
                        class="input-group-prepend-position"
                      >
                        <b-input-group-text data-toggle>
                          <i class="fas fa-coins"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="amount"
                        name="amount"
                        type="number"
                        step="1"
                        min="0"
                        v-model="model.amount"
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>

              <b-form-group
                label="Reason * :"
                label-for="content"
                :label-cols="3"
                :feedback="feedback('content')"
                description="Example: gift, award ... . This text will be visible to users"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fab fa-autoprefixer"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="content"
                        name="content"
                        v-model="model.content"
                        :state="state('content')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('content') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 24%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 38%"
                  :disabled="pending"
                  @click="AddCoins"
                >
                  {{ trans('core.button.save-and-close') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'AddCoins',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: ''
    },
    type: {
      type: [Number, String],
      default: ''
    },
    module: {
      type: [String],
      default: ''
    },
    moduleModel: {
      type: [String],
      default: ''
    },
    modelPath: {
      type: [String],
      default: ''
    },
    recipient: {
      type: [String],
      default: ''
    },
    balance: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: '',
      resourceRoute: 'coins',
      listPath: '/coins/add',
      model: {
        amount: 0,
        content: ''
      },
      errors: []
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('translations.title.edit-form')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    async AddCoins() {
      let formData = new FormData()
      formData.append('id', this.id)
      formData.append('type', this.type)
      formData.append('module', this.module)
      formData.append('module_model', this.moduleModel)
      formData.append('model_path', this.modelPath)
      formData.append('content', this.model.content)
      formData.append('amount', this.model.amount)
      try {
        let { data } = await axios.post(this.$app.route('coins.add'), formData)
        //this.$refs.datatable.refresh()
        //this.$modal.hide('subscribers-import-modal')
        this.modalClose()
        this.$app.noty[data.status](data.message)
        this.$eventHub.$emit('refresh-data')
      } catch (error) {
        this.errors = error.response.data.errors
      }
    }
  }
}
</script>
