<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card class="attribute-tabs dialog-tabs">
            <h3 class="card-title" slot="header">
              {{ trans('geo.titles.city-edit') }}
            </h3>

            <vue-tabs
              active-tab-color="#363B64"
              active-text-color="white"
              type="pills"
              class="attribute-tabs"
              v-model="activeTab"
            >
              <v-tab title="Data" icon="fas fa-pen">
                <div class="attribute-tab-content wrap-tab-content">
                  <!-- Country name -->
                  <div
                    role="group"
                    class="form-row form-group form-row-info mb-0 mt-0"
                  >
                    <label class="col-3 col-form-label">
                      {{ trans('geo.labels.country_name') }}:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <template v-if="data && data.name">
                        {{ data.country_name }}
                      </template>
                    </div>
                  </div>

                  <!-- Code -->
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      {{ trans('geo.labels.country_code') }}:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      {{ data.country_code }}
                    </div>
                  </div>

                  <!-- City Name -->
                  <b-form-group
                    :label="trans('geo.labels.city-name') + ' *'"
                    label-for="name"
                    label-cols-sm="3"
                    :feedback="feedback('name')"
                  >
                    <b-form-input
                      id="name"
                      name="name"
                      v-model="model.name"
                      :state="state('name')"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('name') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Population -->
                  <b-form-group
                    :label="trans('geo.labels.adjust-by-population') + ' *'"
                    label-for="name"
                    label-cols-sm="3"
                    :feedback="feedback('population')"
                  >
                    <b-form-input
                      id="population"
                      name="population"
                      type="number"
                      v-model="model.population"
                      :state="state('population')"
                      class="form-control-status"
                      min="0"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('population') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Latitude -->
                  <b-form-group
                    :label="trans('geo.labels.latitude')"
                    label-for="latitude"
                    label-cols-sm="3"
                    :feedback="feedback('latitude')"
                  >
                    <b-form-input
                      id="latitude"
                      name="latitude"
                      type="number"
                      step="0.000001"
                      v-model="model.latitude"
                      :state="state('latitude')"
                      class="form-control-status"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('population') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Latitude -->
                  <b-form-group
                    :label="trans('geo.labels.longitude')"
                    label-for="longitude"
                    label-cols-sm="3"
                    :feedback="feedback('longitude')"
                  >
                    <b-form-input
                      id="longitude"
                      name="longitude"
                      type="number"
                      step="0.000001"
                      v-model="model.longitude"
                      :state="state('longitude')"
                      class="form-control-status"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('longitude') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- City Time zone -->
                  <b-form-group
                    label="Time zone"
                    label-for="timezone"
                    label-cols-sm="3"
                    :feedback="feedback('timezone')"
                  >
                    <b-form-input
                      id="timezone"
                      name="timezone"
                      v-model="model.timezone"
                      :state="state('timezone')"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('timezone') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Enabled -->
                  <div class="form-group">
                    <b-row>
                      <b-col lg="9" offset-lg="3">
                        <c-switch
                          name="enabled"
                          v-model="model.enabled"
                          :description="$t('core.label.enabled')"
                        ></c-switch>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- Manual enabled -->
                  <div class="form-group">
                    <b-row>
                      <b-col lg="9" offset-lg="3">
                        <c-switch
                          name="status_manual"
                          v-model="model.status_manual"
                          :description="$t('geo.labels.status-manual')"
                        ></c-switch>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </v-tab>

              <v-tab title="Url" icon="fas fa-link">
                <div class="attribute-tab-content  wrap-tab-content">
                  <b-row class="left-content-center">
                    <b-col xl="12" lg="12">
                      <b-card
                        :key="index"
                        v-for="(locale, index) in this.$app.locales"
                        class="group-lang group-lang-url"
                        v-if="locale.backend"
                      >
                        <!-- Divider -->
                        <b-form-group class="card-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ $t('labels.url') }} - {{ index }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <b-form-group
                          :label="$t('validation.attributes.url')"
                          :label-for="`routes.${index}.slug`"
                          label-cols-sm="3"
                          :feedback="feedback('routes.' + index + '.slug')"
                        >
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-title prepend-lang"
                              :class="[
                                locale.required ? 'prepend-lang-required' : ''
                              ]"
                            >
                              <b-input-group-text
                                data-toggle
                                v-b-tooltip.hover
                                :title="$t(locale.name)"
                              >
                                <span>{{ index }}</span>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              :id="`routes.${index}.slug`"
                              :name="`routes.${index}.slug`"
                              v-model="model.routes[`${index}`]['slug']"
                              :state="state('routes.' + index + '.slug')"
                            ></b-form-input>
                            <b-input-group-append
                              class="input-group-append-title"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fas fa-link"></i>
                              </b-input-group-text>
                            </b-input-group-append>
                            <b-form-feedback>
                              {{ feedback('routes.' + index + '.slug') }}
                            </b-form-feedback>
                          </b-input-group>
                        </b-form-group>

                        <b-form-group
                          :label="$t('validation.attributes.url-target')"
                          label-for="target"
                          :description="
                            $t('validation.attributes.url-target-info')
                          "
                          label-cols-sm="3"
                        >
                          <div
                            class="url-link"
                            v-if="
                              meta.routes &&
                                meta.routes[`${index}`] &&
                                meta.routes[`${index}`]['slug']
                            "
                          >
                            <a
                              :href="
                                $app.appUrlClient +
                                  '/' +
                                  meta.routes[`${index}`]['slug']
                              "
                              target="_blank"
                            >
                              {{ $app.appUrlClient }}/{{
                                meta.routes[`${index}`]['slug']
                              }}
                            </a>
                            <template
                              v-if="
                                meta.routes[`${index}`]['slug'] !==
                                  model.routes[`${index}`]['slug']
                              "
                            >
                              <span class="url-link-new">
                                <i class="fas fa-long-arrow-alt-right"></i>
                                {{ $app.appUrlClient }}/{{
                                  model.routes[`${index}`]['slug']
                                }}
                              </span>
                            </template>
                          </div>
                        </b-form-group>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </v-tab>

              <v-tab title="Seo" icon="fas fa-heading">
                <div class="attribute-tab-content  wrap-tab-content">
                  <b-row class="left-content-center">
                    <b-col xl="12" lg="12">
                      <b-card
                        :key="index"
                        v-for="(locale, index) in this.$app.locales"
                        v-if="locale.backend"
                        class="group-lang group-lang-seo"
                      >
                        <!-- Divider -->
                        <b-form-group class="card-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ $t('labels.seo') }} - {{ index }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <b-form-group
                          :label="$t('labels.meta.title')"
                          :label-for="`metadata.title.${index}`"
                          label-cols-sm="3"
                          :description="$t('labels.meta.title-note')"
                          :feedback="feedback('metadata.title.' + index)"
                        >
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-title prepend-lang"
                              :class="[
                                locale.required ? 'prepend-lang-required' : ''
                              ]"
                            >
                              <b-input-group-text
                                data-toggle
                                v-b-tooltip.hover
                                :title="$t(locale.name)"
                              >
                                <span>{{ index }}</span>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              :id="`metadata.title.${index}`"
                              :name="`metadata.title.${index}`"
                              v-model="model.metadata.title[`${index}`]"
                              :state="state('metadata.title.' + index)"
                            ></b-form-input>
                            <b-input-group-append
                              class="input-group-append-title"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fas fa-window-maximize"></i>
                              </b-input-group-text>
                            </b-input-group-append>
                            <b-form-feedback>
                              {{ feedback('metadata.title.' + index) }}
                            </b-form-feedback>
                          </b-input-group>
                        </b-form-group>
                        <b-form-group
                          :label="$t('labels.meta.description')"
                          :label-for="`metadata.description.${index}`"
                          label-cols-sm="3"
                        >
                          <b-form-textarea
                            :id="`metadata.description.${index}`"
                            :name="`metadata.description.${index}`"
                            :rows="3"
                            v-model="model.metadata.description[`${index}`]"
                          ></b-form-textarea>
                        </b-form-group>
                        <b-form-group
                          :label="$t('labels.meta.keywords')"
                          :label-for="`metadata.keywords.${index}`"
                          label-cols-sm="3"
                        >
                          <b-form-textarea
                            :id="`metadata.keywords.${index}`"
                            :name="`metadata.keywords.${index}`"
                            :rows="3"
                            v-model="model.metadata.keywords[`${index}`]"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </v-tab>
            </vue-tabs>

            <!-- Modal footer -->

            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 50%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 50%"
                  :disabled="pending"
                  @click="save"
                >
                  {{ trans('core.button.save') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  title: '',
  name: 'CityRecord',
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      activeTab: 0,
      isModal: true,
      modelName: 'city',
      resourceRoute: 'cities',
      listPath: '/cities',
      model: {
        name: '',
        timezone: '',
        population: 0,
        latitude: '',
        longitude: '',
        enabled: false,
        status_manual: false,
        // Routes
        routes: {
          lt: {
            slug: ''
          },
          en: {
            slug: ''
          }
        },
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('geo.title.city-edit')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    save() {
      // Setup data.
      let data = {}
      data.name = this.model.name
      data.timezone = this.model.timezone
      data.population = this.model.population
      data.latitude = this.model.latitude
      data.longitude = this.model.longitude
      data.enabled = this.model.enabled
      data.status_manual = this.model.status_manual
      data.routes = this.model.routes
      data.metadata = this.model.metadata

      axios
        .patch(
          this.$app.route('cities.update', {
            city: this.id
          }),
          data
        )
        .then(response => {
          this.refreshData()
          this.modalClose()
          this.$app.noty[response.data.status](response.data.message)
          this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.pending = false
          if (error.response.status === 422) {
            this.validation = error.response.data
            return
          }
          this.$app.error(error)
        })
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  width: 40px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: -3px;
  display: block;
}
</style>
