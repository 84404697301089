<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('subscriptions.titles.create-plan') }}
            </h3>

            <div class="multi-lang multi-lang-open">
              <div
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                v-if="locale.backend"
              >
                <b-form-group
                  label="Plan name *"
                  :label-for="`name.${index}`"
                  :label-cols="3"
                  :feedback="feedback('name.' + index)"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`name.${index}`"
                      :name="`name.${index}`"
                      v-model="model.name[`${index}`]"
                      :state="state('name.' + index)"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fab fa-autoprefixer"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('name.' + index) }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>

            <b-form-group
              label="Dedication for *"
              label-for="type"
              :label-cols="3"
              :feedback="feedback('type')"
            >
              <b-row>
                <b-col md="6">
                  <b-input-group>
                    <b-form-select
                      id="type"
                      name="type"
                      v-model="model.type"
                      :options="types"
                      class="form-control form-control-status select-box-with-arrow"
                    >
                    </b-form-select>
                    <b-form-feedback>
                      {{ feedback('type') }}
                    </b-form-feedback>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label="Comment"
              label-for="comment"
              :label-cols="3"
              description="For internal use only"
              :feedback="feedback('comment')"
            >
              <b-row>
                <b-col md="12">
                  <b-input-group>
                    <b-input-group-prepend class="input-group-prepend-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-comment-alt"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      id="comment"
                      name="comment"
                      v-model="model.comment"
                      :state="state('comment')"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <div class="multi-lang multi-lang-open">
              <div
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                v-if="locale.backend"
              >
                <b-form-group
                  label="Description"
                  :label-for="`description.${index}`"
                  label-cols-sm="3"
                  :feedback="feedback('description.' + index)"
                >
                  <p-richtexteditor
                    name="description"
                    v-model="model.description[`${index}`]"
                  ></p-richtexteditor>
                </b-form-group>
              </div>
            </div>

            <div class="multi-lang multi-lang-open">
              <b-form-group
                label="Trial period"
                label-for="trial_period"
                :label-cols="3"
                :feedback="feedback('trial_period')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fas fa-calendar"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        class="term_max"
                        type="number"
                        id="trial_period"
                        name="trial_period"
                        v-model="model.trial_period"
                        :state="state('trial_period')"
                      ></b-form-input>
                      <b-form-select
                        id="trial_interval"
                        name="trial_interval"
                        v-model="model.trial_interval"
                        :options="term_formats"
                        class="form-control form-control-format select-box-with-arrow"
                      >
                      </b-form-select>
                      <b-form-feedback>
                        {{ feedback('trial_period') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <div class="multi-lang multi-lang-open">
              <b-form-group
                label="Plan period"
                label-for="invoice_period"
                :label-cols="3"
                :feedback="feedback('invoice_period')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fas fa-calendar"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        class="term_max"
                        type="number"
                        id="invoice_period"
                        name="invoice_period"
                        v-model="model.invoice_period"
                        :state="state('invoice_period')"
                      ></b-form-input>
                      <b-form-select
                        id="invoice_interval"
                        name="invoice_interval"
                        v-model="model.invoice_interval"
                        :options="term_formats"
                        class="form-control form-control-format select-box-with-arrow"
                      >
                      </b-form-select>
                      <b-form-feedback>
                        {{ feedback('invoice_period') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <div class="multi-lang multi-lang-open">
              <b-form-group
                label="Price per period"
                label-for="price"
                :label-cols="3"
                :feedback="feedback('price')"
              >
                <b-row>
                  <b-col md="5">
                    <b-input-group>
                      <b-form-input
                        class="price"
                        type="number"
                        id="price"
                        name="price"
                        v-model="model.price"
                        :state="state('price')"
                        step="0.01"
                      ></b-form-input>
                      <b-input-group-append class="input-group-append-title">
                        <b-input-group-text data-toggle>
                          <i class="fas fa-euro-sign"></i>
                        </b-input-group-text>
                      </b-input-group-append>
                      <b-form-feedback>
                        {{ feedback('price') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <b-form-group
              label-cols-sm="3"
              description="If on plan will visible in plan list for selection (Frontend)"
            >
              <b-row>
                <b-col lg="9">
                  <c-switch
                    name="show_center"
                    v-model="model.public"
                    description="Public"
                  ></c-switch>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label-cols-sm="3"
              description="If on plan will visible in plan list for selection (Backend) and if set public on Frontend"
            >
              <b-row>
                <b-col lg="9">
                  <c-switch
                    name="is_active"
                    v-model="model.is_active"
                    description="Active"
                  ></c-switch>
                </b-col>
              </b-row>
            </b-form-group>

            <template v-if="!modal">
              <b-row slot="footer">
                <b-col>
                  <b-button to="/plans" exact variant="danger" size="sm">
                    {{ $t('buttons.back') }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    size="sm"
                    class="float-right"
                    :disabled="pending"
                    v-if="isNew"
                  >
                    {{ $t('buttons.create') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="modal">
              <action-footer-modal
                :page-title="$t('plans.titles.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="true"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'

export default {
  title: '',
  name: 'PlanCreateForm',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'plan',
      resourceRoute: 'plans',
      listPath: '/plans',
      types: {
        escort: 'Escort',
        agency: 'Agency',
        brothel: 'Brothel',
        parlour: 'Parlour',
        club: 'Club',
        client: 'Client (Member)'
      },
      term_formats: ['day', 'month'],
      model: {
        // Data
        name: {},
        description: {},
        is_active: false,
        public: false,
        comment: '',
        type: '',
        trial_period: '',
        trial_interval: 'day',
        invoice_period: '',
        invoice_interval: 'day',
        price: '',
        price_old: '',
        price_label: '',
        currency: 'EUR',
        // Routes
        routes: {}
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('plans.titles.create')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    }
  }
}
</script>
