<template>
  <div class="action-footer">
    <b-row class="justify-content-center">
      <b-col md="6" lg="4" xl="4">
        <template v-if="backEnabled">
          <b-button
            :to="backUrl"
            exact
            variant="secondary"
            size="sm"
            class="float-left"
          >
            {{ $t('buttons.back') }}
          </b-button>
        </template>
      </b-col>
      <b-col md="6" lg="8" xl="8">
        <b-button
          v-if="saveEnabled"
          type="submit"
          variant="success"
          size="sm"
          class="float-right bnt-save"
          :disabled="pending"
          @click="continueSave()"
        >
          {{ $t('buttons.save') }}
        </b-button>
        <b-button
          v-if="saveContinueEnabled"
          type="submit"
          variant="warning"
          size="sm"
          class="float-right bnt-save-continue"
          :disabled="pending"
          @click="continueEditing()"
        >
          {{ $t('buttons.save-continue') }}
        </b-button>
        <b-button
          v-if="deleteEnabled"
          exact
          variant="danger"
          size="sm"
          class="float-right bnt-save-continue"
          @click.stop="deleteRecord()"
        >
          {{ $t('core.button.delete') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ActionFooter',
  props: {
    iden: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    routeDelete: {
      type: String,
      default: ''
    },
    pending: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    backEnabled: {
      type: Boolean,
      default: true
    },
    deleteEnabled: {
      type: Boolean,
      default: false
    },
    backUrl: {
      type: String,
      default: ''
    },
    saveEnabled: {
      type: Boolean,
      default: true
    },
    saveContinueEnabled: {
      type: Boolean,
      default: true
    },
    savePermission: {
      type: String,
      default: ''
    }
  },
  methods: {
    continueSave() {
      this.$eventHub.$emit('continue-save')
    },
    continueEditing() {
      this.$eventHub.$emit('continue-editing')
    },
    async deleteRecord() {
      let result = await window.swal({
        title: this.$t('labels.are_you_sure'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('buttons.cancel'),
        confirmButtonColor: '#dd4b39',
        confirmButtonText: this.$t('buttons.delete')
      })

      if (result.value) {
        try {
          let { data } = await axios
            .delete(this.$app.route(this.routeDelete, this.iden))
            .then(response => {
              if (response.data.status == 'success') {
                this.$app.noty[response.data.status](response.data.message)
                this.$router.replace(this.backUrl)
              }
            })
        } catch (e) {}
      }
    }
  }
}
</script>
