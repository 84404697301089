export default {
  path: 'features',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'features',
      component: require('@subscriptions/js/components/FeatureList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'features_create',
      component: require('@subscriptions/js/components/FeatureCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'features_edit',
      component: require('@subscriptions/js/components/FeatureEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
