<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card class="attribute-tabs dialog-tabs">
            <h3 class="card-title" slot="header">
              Geo data edit
            </h3>

            <vue-tabs
              active-tab-color="#363B64"
              active-text-color="white"
              type="pills"
              class="attribute-tabs"
              v-model="activeTab"
            >
              <v-tab title="Data" icon="fas fa-pen">
                <div class="attribute-tab-content wrap-tab-content">
                  <!-- Country name -->
                  <!--                  <div
                    role="group"
                    class="form-row form-group form-row-info mb-0 mt-0"
                  >
                    <label class="col-3 col-form-label">
                      {{ trans('geo.labels.country_name') }}:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <template v-if="data && data.name">
                        {{ data.country_name }}
                      </template>
                    </div>
                  </div>-->

                  <!-- Code -->
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      {{ trans('geo.labels.country_code') }}:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      {{ data.country_code }}
                    </div>
                  </div>

                  <!-- City Name -->
                  <b-form-group
                    label="Geo record name *"
                    label-for="name"
                    label-cols-sm="3"
                    :feedback="feedback('name')"
                  >
                    <b-form-input
                      id="name"
                      name="name"
                      v-model="model.name"
                      :state="state('name')"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('name') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Population -->
                  <b-form-group
                    :label="trans('geo.labels.adjust-by-population') + ' *'"
                    label-for="name"
                    label-cols-sm="3"
                    :feedback="feedback('population')"
                  >
                    <b-form-input
                      id="population"
                      name="population"
                      type="number"
                      v-model="model.population"
                      :state="state('population')"
                      class="form-control-status"
                      min="0"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('population') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Latitude -->
                  <b-form-group
                    :label="trans('geo.labels.latitude')"
                    label-for="latitude"
                    label-cols-sm="3"
                    :feedback="feedback('lat')"
                  >
                    <b-form-input
                      id="lat"
                      name="lat"
                      type="number"
                      step="0.000001"
                      v-model="model.lat"
                      :state="state('lat')"
                      class="form-control-status"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('lat') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Latitude -->
                  <b-form-group
                    :label="trans('geo.labels.longitude')"
                    label-for="longitude"
                    label-cols-sm="3"
                    :feedback="feedback('long')"
                  >
                    <b-form-input
                      id="long"
                      name="long"
                      type="number"
                      step="0.000001"
                      v-model="model.long"
                      :state="state('long')"
                      class="form-control-status"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('long') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- City Time zone -->
                  <b-form-group
                    label="Time zone"
                    label-for="timezone"
                    label-cols-sm="3"
                    :feedback="feedback('timezone')"
                  >
                    <b-form-input
                      id="timezone"
                      name="timezone"
                      v-model="model.timezone"
                      :state="state('timezone')"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('timezone') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Enabled -->
                  <div class="form-group">
                    <b-row>
                      <b-col lg="9" offset-lg="3">
                        <c-switch
                          name="reviewed"
                          v-model="model.reviewed"
                          description="Reviewed"
                        ></c-switch>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </v-tab>
            </vue-tabs>

            <!-- Modal footer -->

            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 50%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 50%"
                  :disabled="pending"
                  @click="save"
                >
                  {{ trans('core.button.save') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  title: '',
  name: 'GeoRecord',
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      activeTab: 0,
      isModal: true,
      modelName: 'geo',
      resourceRoute: 'geo',
      listPath: '/geo',
      model: {
        name: '',
        timezone: '',
        population: 0,
        lat: '',
        long: '',
        reviewed: false
      },
      data: {},
      meta: {}
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('geo.title.city-edit')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    save() {
      // Setup data.
      let data = {}
      data.name = this.model.name
      data.timezone = this.model.timezone
      data.population = this.model.population
      data.lat = this.model.lat
      data.long = this.model.long
      data.reviewed = this.model.reviewed

      axios
        .patch(
          this.$app.route('geo.update', {
            geo: this.id
          }),
          data
        )
        .then(response => {
          this.refreshData()
          this.modalClose()
          this.$app.noty[response.data.status](response.data.message)
          this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.pending = false
          if (error.response.status === 422) {
            this.validation = error.response.data
            return
          }
          this.$app.error(error)
        })
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  width: 40px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: -3px;
  display: block;
}
</style>
