<template>
  <div class="action-header">
    <div class="col-action">
      <template v-if="backEnabled">
        <b-button
          :to="backUrl"
          exact
          variant="secondary"
          size="sm"
          class="float-none btn-back-small"
        >
          <i class="fas fa-reply"></i>
          {{ $t('buttons.back') }}
        </b-button>
      </template>
    </div>
    <div class="col-content">
      <b-row class="col-content-info">
        <b-col md="8" lg="8" xl="8">
          <template v-if="title">
            {{ title }} <span class="pre-title"> / {{ subtitle }} </span>
          </template>
        </b-col>
        <b-col md="4" lg="4" xl="4"></b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionHeader',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    backEnabled: {
      type: Boolean,
      default: true
    },
    backUrl: {
      type: String,
      default: ''
    }
  },
  methods: {}
}
</script>
