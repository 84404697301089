export default {
  path: 'brothels',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'brothels',
      component: require('@brothels/js/components/BrothelList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified',
      name: 'brothels',
      component: require('@brothels/js/components/BrothelUnverifiedList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'brothels_create',
      component: require('@brothels/js/components/BrothelCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'brothels_edit',
      component: require('@brothels/js/components/BrothelEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified/:id/edit',
      name: 'brothels_unverified_edit',
      component: require('@brothels/js/components/BrothelEditUnverifiedForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
