<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('orders.titles.list') }}
        </h3>
        <!--        <div class="card-options" v-if="this.$app.user.can('create orders')">
          <b-button @click="modalCreate" variant="success" size="sm">
           <i class="fas fa-plus"></i>
            {{ $t('orders.buttons.create') }}
          </b-button>
        </div>-->
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="orders.search"
        delete-route="orders.destroy"
        action-route="orders.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(order_object_type)="row">
            <span v-if="row.item.order_object.type">
              {{ row.item.order_object.type }}
            </span>
          </template>
          <template v-slot:cell(payer_name)="row">
            <a
              v-if="row.item.order_object.url"
              :href="`${$app.appUrlClient}${row.item.order_object.url}`"
              target="_blank"
              class="dash-tr-link"
            >
              <span
                v-text="row.item.order_object.name"
                class="dash-tr-link"
              ></span>
            </a>
            <div class="payer_name" v-else>
              <strong>{{ row.value }}</strong>
            </div>
            <div class="payer_show_name">
              {{ row.item.payer_show_name }}
            </div>
          </template>
          <template v-slot:cell(payer_country)="row">
            <div class="payer_name">
              <strong>{{ row.value }}</strong>
            </div>
            <div class="payer_show_name">
              {{ row.item.payer_city }}
            </div>
          </template>
          <template v-slot:cell(price)="row">
            <span>
              <strong>{{ row.value }}</strong>
              {{ row.item.currency }}
            </span>
          </template>
          <template v-slot:cell(order_object_name)="row">
            <a
              v-if="row.item.order_object.url"
              :href="`${$app.appUrlClient}${row.item.order_object.url}`"
              target="_blank"
              class="dash-tr-link"
            >
              <span
                v-text="row.item.order_object.name"
                class="dash-tr-link"
              ></span>
            </a>
            <span
              v-else
              v-text="row.item.order_object.name"
              class="dash-tr-text"
            ></span>
          </template>
          <template v-slot:cell(order_item)="row">
            <a
              v-if="row.item.order_item.url"
              :href="`${$app.appUrlClient}${row.item.order_item.url}`"
              target="_blank"
              class="dash-tr-link"
            >
              <span
                v-text="row.item.order_item.name"
                class="dash-tr-link"
              ></span>
            </a>
            <span
              v-else
              v-text="row.item.order_item.name"
              class="dash-tr-text"
            ></span>
            <div class="payer_show_name">
              {{ row.item.order_item.type }}
            </div>
          </template>
          <template v-slot:cell(parent_order_id)="row">
            <span v-if="row.item.parent_order_id">
              Automatic
            </span>
            <span v-else>
              Manual
            </span>
          </template>
          <template v-slot:cell(recurring_payment)="row">
            <span v-if="row.value" class="badge badge-success">
              {{ trans('core.label.yes') }}
            </span>
            <span v-else class="badge badge-danger">
              {{ trans('core.label.no') }}
            </span>
          </template>
          <template v-slot:cell(promotion)="row">
            <span>
              <strong>{{ row.value }}</strong>
            </span>
          </template>
          <!--          <template slot="actions" slot-scope="row">
            <b-button
              v-if="row.item.can_edit"
              size="sm"
              variant="primary"
              :to="`/orders/${row.item.id}/edit`"
              v-b-tooltip.hover
              :title="$t('buttons.edit')"
              class="mr-1"
            >
              <i class="fas fa-pencil-alt"></i>
            </b-button>
            <b-button
              v-if="row.item.can_delete"
              size="sm"
              variant="danger"
              v-b-tooltip.hover
              :title="$t('buttons.delete')"
              @click.stop="onDelete(row.item.id)"
            >
              <i class="fas fa-trash-alt"></i>
            </b-button>
          </template>-->
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import OrderCreateForm from './OrderCreateForm.vue'
export default {
  title: '',
  name: 'OrderList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'sid',
          label: '#',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'payer_type',
          label: 'Payer',
          class: 'text-left'
        },
        {
          key: 'payer_name',
          label: 'Payer details',
          class: 'text-left'
        },
        {
          key: 'payer_country',
          label: '',
          class: 'text-left'
        },
        {
          key: 'order_item',
          label: 'Purchase',
          class: 'text-right'
        },
        {
          key: 'price',
          label: 'Price',
          class: 'text-right'
        },
        {
          key: 'parent_order_id',
          label: 'Payment Type',
          class: 'text-center'
        },
        {
          key: 'recurring_payment',
          label: 'Recurring Payment',
          class: 'text-center'
        },
        {
          key: 'promotion',
          label: 'Promotion',
          class: 'text-center'
        },
        {
          key: 'paid_at',
          label: 'Paid At',
          class: 'text-center',
          sortable: true
        }
        /*        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        }*/
        /*        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        }*/
        /*        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }*/
      ]
      /*      actions: {
        destroy: this.$t('orders.actions.destroy'),
        enable: this.$t('orders.actions.enable'),
        disable: this.$t('orders.actions.disable')
      }*/
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('orders.titles.list')
  },
  methods: {
    modalCreate() {
      this.$modal.show(
        OrderCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ order: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('orders.enabled', { order: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    }
  }
}
</script>
