export default {
  path: 'parlours',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'parlours',
      component: require('@parlours/js/components/ParlourList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified',
      name: 'parlours',
      component: require('@parlours/js/components/ParlourUnverifiedList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'parlours_create',
      component: require('@parlours/js/components/ParlourCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'parlours_edit',
      component: require('@parlours/js/components/ParlourEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified/:id/edit',
      name: 'parlours_unverified_edit',
      component: require('@parlours/js/components/ParlourEditUnverifiedForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
