import Vue from 'vue'
import Vuex from 'vuex'

import { createActions } from './actions'
import mutations from './mutations'

Vue.use(Vuex)

export function createStore(route) {
  const actions = createActions(route)

  return new Vuex.Store({
    state: {
      counters: {
        pendingEscortsCount: 0,
        reviseEscortsCount: 0,
        draftEscortsCount: 0,
        activeEscortsCount: 0,
        suspendedEscortsCount: 0,
        allEscortsCount: 0,
        pendingAgenciesCount: 0,
        reviseAgenciesCount: 0,
        draftAgenciesCount: 0,
        activeAgenciesCount: 0,
        suspendedAgenciesCount: 0,
        allAgenciesCount: 0,
        pendingBrothelsCount: 0,
        reviseBrothelsCount: 0,
        draftBrothelsCount: 0,
        activeBrothelsCount: 0,
        suspendedBrothelsCount: 0,
        allBrothelsCount: 0,
        pendingParloursCount: 0,
        reviseParloursCount: 0,
        draftParloursCount: 0,
        activeParloursCount: 0,
        suspendedParloursCount: 0,
        allParloursCount: 0,
        pendingClubsCount: 0,
        reviseClubsCount: 0,
        draftClubsCount: 0,
        activeClubsCount: 0,
        suspendedClubsCount: 0,
        allClubsCount: 0,
        activeUsersCount: 0
      }
    },
    actions,
    mutations
  })
}
