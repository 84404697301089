import Vue from 'vue'
import Router from 'vue-router'

// Containers
import Full from '../containers/Full'

// Views
import Dashboard from '@dashboard/js/components/Dashboard'

Vue.use(Router)

export function createRouter(base, i18n) {
  return new Router({
    mode: 'history',
    base: base,
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      {
        path: '/',
        redirect: '/dashboard',
        name: 'home',
        component: Full,
        meta: {
          label: ''
        },
        children: [
          /*          {
            path: 'search',
            name: 'search',
            component: Search,
            meta: {
              label: i18n.t('labels.search')
            }
          },*/
          {
            path: 'dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
              label: i18n.t('labels.titles.dashboard')
            }
          },
          require('@users/js/routes').default,
          require('@roles/js/routes').default,
          require('@settings/js/routes').default,
          require('@languages/js/routes').default,
          require('@translations/js/routes').default,
          require('@blocks/js/routes').default,
          require('@pages/js/routes').default,
          require('@escorts/js/routes').default,
          require('@agencies/js/routes').default,
          require('@agency-information/js/routes').default,
          require('@agency-services/js/routes').default,
          require('@brothels/js/routes').default,
          require('@brothel-information/js/routes').default,
          require('@brothel-services/js/routes').default,
          require('@clients/js/routes').default,
          require('@clubs/js/routes').default,
          require('@club-information/js/routes').default,
          require('@club-services/js/routes').default,
          require('@club-types/js/routes').default,
          require('@parlours/js/routes').default,
          require('@parlour-information/js/routes').default,
          require('@parlour-services/js/routes').default,
          require('@parlour-ethnicity/js/routes').default,
          require('@banners/js/routes').default,
          require('@banners-links/js/routes').default,
          require('@blog/js/routes').default,
          require('@orders/js/routes').default,
          require('@fake-reports/js/routes').default,
          require('@black-lists/js/routes').default,
          require('@reviews/js/routes').default,
          require('@reviews-escorts/js/routes').default,
          require('@escorts-verifications/js/routes').default,
          require('@email-services/js/routes/email').default,
          require('@email-services/js/routes/send').default,
          require('@email-services/js/routes/message').default,
          require('@geo/js/routes/countries').default,
          require('@geo/js/routes/cities').default,
          require('@subscriptions/js/routes/subscriptions').default,
          require('@subscriptions/js/routes/plans').default,
          require('@subscriptions/js/routes/features').default,
          require('@coins/js/routes/packages').default,
          require('@coins/js/routes/movement').default
        ]
      }
    ]
  })
}
