<template>
  <div class="list-page dashboard-page">
    <!--    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('dashboard.titles.unit') }}
        </h3>
      </template>
    </b-card>-->

    <b-card class="mb-0 card-adj">
      <b-row>
        <b-col mc="7" xl="7">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('escorts.titles.dashboard-escorts') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <!-- Records pending for verification -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('escorts.label.dashboard-pending') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.pendingEscortsCount > 0
                          ? 'd-text-orange'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.pendingEscortsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.pendingEscortsCount > 0
                          ? 'icon-orange'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-hourglass-half"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records rejected and waiting for corrections  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('escorts.label.dashboard-revise') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.reviseEscortsCount > 0
                          ? 'd-text-light-blue'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.reviseEscortsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.reviseEscortsCount > 0
                          ? 'icon-light-blue'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-edit"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records draft -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('escorts.label.dashboard-draft') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block d-text-grey"
                    >
                      {{ this.$store.state.counters.draftEscortsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-grey">
                      <i class="fab fa-firstdraft"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records active  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('escorts.label.dashboard-correct') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.correctEscortsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-light-green">
                      <i class="fas fa-check-circle"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records suspended  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('escorts.label.dashboard-suspended') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.suspendedEscortsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-ban"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records all  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('escorts.label.all-records') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.allEscortsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-violet">
                      <i class="fas fa-people-arrows"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" xl="5">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('escorts.titles.dashboard-latest-verifications') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <b-col md="12" xl="12">
              <b-table
                borderless
                hover
                show-empty
                stacked="sm"
                :fields="fields"
                :items="records"
                sort-by="created_at"
                :sort-desc="true"
                :empty-text="$t('escorts.label.no-results-awaiting')"
                class="dashboard-table"
              >
                <template v-slot:cell(reference)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/escorts/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>
                <!--                <template v-slot:cell(record_name)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/escorts/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>-->
                <template v-slot:cell(status)="row">
                  <span v-html="statusRender(row.value)"></span>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    v-if="row.item.can_edit"
                    size="sm"
                    variant="primary"
                    :to="`/escorts/unverified/${row.item.id}/edit`"
                    v-b-tooltip.hover
                    :title="$t('buttons.edit')"
                    class="mr-1"
                  >
                    <i class="fe fe-edit"></i>
                  </b-button>
                  <!--            <b-button
                    v-if="row.item.can_delete"
                    size="sm"
                    variant="danger"
                    v-b-tooltip.hover
                    :title="$t('buttons.delete')"
                    @click.stop="onDelete(row.item.id)"
                  >
                    <i class="fe fe-trash"></i>
                  </b-button>-->
                </template>
              </b-table>
              <b-button
                to="/escorts/unverified"
                variant="primary btn-all-dashboard"
                class="float-right"
                v-if="records"
              >
                {{ $t('escorts.buttons.all-verifications') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-0 card-adj">
      <b-row>
        <b-col mc="7" xl="7">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('agencies.titles.dashboard-agencies') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <!-- Records pending for verification -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('agencies.label.dashboard-pending') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.pendingAgenciesCount > 0
                          ? 'd-text-orange'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.pendingAgenciesCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.pendingAgenciesCount > 0
                          ? 'icon-orange'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-hourglass-half"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records rejected and waiting for corrections  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('agencies.label.dashboard-revise') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.reviseAgenciesCount > 0
                          ? 'd-text-light-blue'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.reviseAgenciesCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.reviseAgenciesCount > 0
                          ? 'icon-light-blue'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-edit"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records draft -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('agencies.label.dashboard-draft') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block d-text-grey"
                    >
                      {{ this.$store.state.counters.draftAgenciesCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-grey">
                      <i class="fab fa-firstdraft"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records active  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('agencies.label.dashboard-correct') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.correctAgenciesCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-light-green">
                      <i class="fas fa-check-circle"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records suspended  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('agencies.label.dashboard-suspended') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.suspendedAgenciesCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-ban"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records all  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('agencies.label.all-records') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.allAgenciesCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-violet">
                      <i class="fas fa-people-arrows"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" xl="5">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('agencies.titles.dashboard-latest-verifications') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <b-col md="12" xl="12">
              <b-table
                borderless
                hover
                show-empty
                stacked="sm"
                :fields="fields"
                :items="recordsAgencies"
                sort-by="created_at"
                :sort-desc="true"
                :empty-text="$t('agencies.label.no-results-awaiting')"
                class="dashboard-table"
              >
                <template v-slot:cell(reference)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/agencies/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>
                <!--                <template v-slot:cell(record_name)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/agencies/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>-->
                <template v-slot:cell(status)="row">
                  <span v-html="statusRender(row.value)"></span>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    v-if="row.item.can_edit"
                    size="sm"
                    variant="primary"
                    :to="`/agencies/unverified/${row.item.id}/edit`"
                    v-b-tooltip.hover
                    :title="$t('buttons.edit')"
                    class="mr-1"
                  >
                    <i class="fe fe-edit"></i>
                  </b-button>
                  <!--            <b-button
                    v-if="row.item.can_delete"
                    size="sm"
                    variant="danger"
                    v-b-tooltip.hover
                    :title="$t('buttons.delete')"
                    @click.stop="onDelete(row.item.id)"
                  >
                    <i class="fe fe-trash"></i>
                  </b-button>-->
                </template>
              </b-table>
              <b-button
                to="/agencies/unverified"
                variant="primary btn-all-dashboard"
                class="float-right"
                v-if="records"
              >
                {{ $t('agencies.buttons.all-verifications') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-0 card-adj">
      <b-row>
        <b-col mc="7" xl="7">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('brothels.titles.dashboard-brothels') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <!-- Records pending for verification -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('brothels.label.dashboard-pending') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.pendingBrothelsCount > 0
                          ? 'd-text-orange'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.pendingBrothelsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.pendingBrothelsCount > 0
                          ? 'icon-orange'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-hourglass-half"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records rejected and waiting for corrections  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('brothels.label.dashboard-revise') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.reviseBrothelsCount > 0
                          ? 'd-text-light-blue'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.reviseBrothelsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.reviseBrothelsCount > 0
                          ? 'icon-light-blue'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-edit"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records draft -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('brothels.label.dashboard-draft') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block d-text-grey"
                    >
                      {{ this.$store.state.counters.draftBrothelsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-grey">
                      <i class="fab fa-firstdraft"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records active  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('brothels.label.dashboard-correct') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.correctBrothelsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-light-green">
                      <i class="fas fa-check-circle"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records suspended  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('brothels.label.dashboard-suspended') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.suspendedBrothelsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-ban"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records all  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('brothels.label.all-records') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.allBrothelsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-violet">
                      <i class="fas fa-people-arrows"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" xl="5">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('brothels.titles.dashboard-latest-verifications') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <b-col md="12" xl="12">
              <b-table
                borderless
                hover
                show-empty
                stacked="sm"
                :fields="fields"
                :items="recordsBrothels"
                sort-by="created_at"
                :sort-desc="true"
                :empty-text="$t('brothels.label.no-results-awaiting')"
                class="dashboard-table"
              >
                <template v-slot:cell(reference)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/brothels/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>
                <!--                <template v-slot:cell(record_name)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/brothels/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>-->
                <template v-slot:cell(status)="row">
                  <span v-html="statusRender(row.value)"></span>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    v-if="row.item.can_edit"
                    size="sm"
                    variant="primary"
                    :to="`/brothels/unverified/${row.item.id}/edit`"
                    v-b-tooltip.hover
                    :title="$t('buttons.edit')"
                    class="mr-1"
                  >
                    <i class="fe fe-edit"></i>
                  </b-button>
                  <!--            <b-button
                    v-if="row.item.can_delete"
                    size="sm"
                    variant="danger"
                    v-b-tooltip.hover
                    :title="$t('buttons.delete')"
                    @click.stop="onDelete(row.item.id)"
                  >
                    <i class="fe fe-trash"></i>
                  </b-button>-->
                </template>
              </b-table>
              <b-button
                to="/brothels/unverified"
                variant="primary btn-all-dashboard"
                class="float-right"
                v-if="records"
              >
                {{ $t('brothels.buttons.all-verifications') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-0 card-adj">
      <b-row>
        <b-col mc="7" xl="7">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('parlours.titles.dashboard-parlours') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <!-- Records pending for verification -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('parlours.label.dashboard-pending') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.pendingParloursCount > 0
                          ? 'd-text-orange'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.pendingParloursCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.pendingParloursCount > 0
                          ? 'icon-orange'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-hourglass-half"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records rejected and waiting for corrections  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('parlours.label.dashboard-revise') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.reviseParloursCount > 0
                          ? 'd-text-light-blue'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.reviseParloursCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.reviseParloursCount > 0
                          ? 'icon-light-blue'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-edit"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records draft -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('parlours.label.dashboard-draft') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block d-text-grey"
                    >
                      {{ this.$store.state.counters.draftParloursCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-grey">
                      <i class="fab fa-firstdraft"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records active  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('parlours.label.dashboard-correct') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.correctParloursCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-light-green">
                      <i class="fas fa-check-circle"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records suspended  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('parlours.label.dashboard-suspended') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.suspendedParloursCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-ban"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records all  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('parlours.label.all-records') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.allParloursCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-violet">
                      <i class="fas fa-people-arrows"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" xl="5">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('parlours.titles.dashboard-latest-verifications') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <b-col md="12" xl="12">
              <b-table
                borderless
                hover
                show-empty
                stacked="sm"
                :fields="fields"
                :items="recordsParlous"
                sort-by="created_at"
                :sort-desc="true"
                :empty-text="$t('parlours.label.no-results-awaiting')"
                class="dashboard-table"
              >
                <template v-slot:cell(reference)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/parlours/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>
                <!--                <template v-slot:cell(record_name)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/parlours/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>-->
                <template v-slot:cell(status)="row">
                  <span v-html="statusRender(row.value)"></span>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    v-if="row.item.can_edit"
                    size="sm"
                    variant="primary"
                    :to="`/parlours/unverified/${row.item.id}/edit`"
                    v-b-tooltip.hover
                    :title="$t('buttons.edit')"
                    class="mr-1"
                  >
                    <i class="fe fe-edit"></i>
                  </b-button>
                  <!--            <b-button
                    v-if="row.item.can_delete"
                    size="sm"
                    variant="danger"
                    v-b-tooltip.hover
                    :title="$t('buttons.delete')"
                    @click.stop="onDelete(row.item.id)"
                  >
                    <i class="fe fe-trash"></i>
                  </b-button>-->
                </template>
              </b-table>
              <b-button
                to="/parlours/unverified"
                variant="primary btn-all-dashboard"
                class="float-right"
                v-if="records"
              >
                {{ $t('parlours.buttons.all-verifications') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-0 card-adj">
      <b-row>
        <b-col mc="7" xl="7">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('clubs.titles.dashboard-clubs') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <!-- Records pending for verification -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('clubs.label.dashboard-pending') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.pendingClubsCount > 0
                          ? 'd-text-orange'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.pendingClubsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.pendingClubsCount > 0
                          ? 'icon-orange'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-hourglass-half"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records rejected and waiting for corrections  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('clubs.label.dashboard-revise') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.reviseClubsCount > 0
                          ? 'd-text-light-blue'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.reviseClubsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.reviseClubsCount > 0
                          ? 'icon-light-blue'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-edit"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records draft -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('clubs.label.dashboard-draft') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block d-text-grey"
                    >
                      {{ this.$store.state.counters.draftClubsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-grey">
                      <i class="fab fa-firstdraft"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records active  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('clubs.label.dashboard-correct') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.correctClubsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-light-green">
                      <i class="fas fa-check-circle"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records suspended  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('clubs.label.dashboard-suspended') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.suspendedClubsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-ban"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Records all  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('clubs.label.all-records') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.allClubsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-violet">
                      <i class="fas fa-people-arrows"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" xl="5">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('clubs.titles.dashboard-latest-verifications') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <b-col md="12" xl="12">
              <b-table
                borderless
                hover
                show-empty
                stacked="sm"
                :fields="fields"
                :items="recordsClubs"
                sort-by="created_at"
                :sort-desc="true"
                :empty-text="$t('clubs.label.no-results-awaiting')"
                class="dashboard-table"
              >
                <template v-slot:cell(reference)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/clubs/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>
                <!--                <template v-slot:cell(record_name)="row">
                  <router-link
                    v-if="row.item.can_edit"
                    :to="`/clubs/unverified/${row.item.id}/edit`"
                    v-text="row.value"
                  ></router-link>
                  <span v-else v-text="row.value"></span>
                </template>-->
                <template v-slot:cell(status)="row">
                  <span v-html="statusRender(row.value)"></span>
                </template>
                <template v-slot:cell(actions)="row">
                  <b-button
                    v-if="row.item.can_edit"
                    size="sm"
                    variant="primary"
                    :to="`/clubs/unverified/${row.item.id}/edit`"
                    v-b-tooltip.hover
                    :title="$t('buttons.edit')"
                    class="mr-1"
                  >
                    <i class="fe fe-edit"></i>
                  </b-button>
                  <!--            <b-button
                    v-if="row.item.can_delete"
                    size="sm"
                    variant="danger"
                    v-b-tooltip.hover
                    :title="$t('buttons.delete')"
                    @click.stop="onDelete(row.item.id)"
                  >
                    <i class="fe fe-trash"></i>
                  </b-button>-->
                </template>
              </b-table>
              <b-button
                to="/clubs/unverified"
                variant="primary btn-all-dashboard"
                class="float-right"
                v-if="records"
              >
                {{ $t('clubs.buttons.all-verifications') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  title: '',
  name: 'Dashboard',
  data() {
    return {
      fields: [
        {
          key: 'reference',
          label: 'Ticket number',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'record_name',
          label: 'Name',
          sortable: true,
          class: 'data-full-name'
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          class: 'data-status'
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      records: [],
      recordsAgencies: [],
      recordsBrothels: [],
      recordsParlous: [],
      recordsClubs: []
    }
  },
  /*  created() {
      this.$eventHub.$on('refresh-data', this.refreshData)
    },*/
  async created() {
    let { data } = await axios.get(
      this.$app.route('escorts_verifications.latest')
    )
    this.records = data

    let dataAgencies = await axios.get(
      this.$app.route('agencies_verifications.latest')
    )

    this.recordsAgencies = dataAgencies.data

    let dataBrothels = await axios.get(
      this.$app.route('brothels_verifications.latest')
    )
    this.recordsBrothels = dataBrothels.data

    let dataParlours = await axios.get(
      this.$app.route('parlours_verifications.latest')
    )
    this.recordsParlours = dataParlours.data

    let dataClubs = await axios.get(
      this.$app.route('clubs_verifications.latest')
    )
    this.recordsClubs = dataClubs.data

    await this.$store.dispatch('LOAD_COUNTERS')
  },
  mounted() {
    this.$title = this.$t('dashboard.titles.list')
  },
  methods: {
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ dashboard: id })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    statusRender(status) {
      return (
        '<span class="badge badge-' +
        status +
        '">' +
        this.trans('escorts.status.' + status) +
        '<span>'
      )
    }
  }
}
</script>
