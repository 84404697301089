<template>
  <div></div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      editor: null
    }
  },
  watch: {
    value(newValue) {
      if (this.editor && newValue !== this.editor.getData()) {
        this.editor.setData(newValue)
      }
    }
  },
  mounted() {
    this.createInstance()
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy()
    }
  },
  methods: {
    async createInstance() {
      if (!this.editor) {
        try {
          this.editor = await ClassicEditor.create(this.$el, {
            toolbar: [
              'heading',
              'bold',
              'italic',
              'link',
              'bulletedList',
              'numberedList',
              'imageUpload',
              'blockQuote',
              'undo',
              'redo'
            ],
            heading: {
              options: [
                {
                  model: 'paragraph',
                  title: 'Paragraph',
                  class: 'ck-heading_paragraph'
                },
                {
                  model: 'heading1',
                  view: 'h1',
                  title: 'Heading 1',
                  class: 'ck-heading_heading1'
                },
                {
                  model: 'heading2',
                  view: 'h2',
                  title: 'Heading 2',
                  class: 'ck-heading_heading2'
                },
                {
                  model: 'heading3',
                  view: 'h3',
                  title: 'Heading 3',
                  class: 'ck-heading_heading3'
                },
                {
                  model: 'heading4',
                  view: 'h4',
                  title: 'Heading 4',
                  class: 'ck-heading_heading4'
                },
                {
                  model: 'heading5',
                  view: 'h5',
                  title: 'Heading 5',
                  class: 'ck-heading_heading5'
                }
              ]
            },
            image: {
              toolbar: [
                'imageTextAlternative',
                '|',
                'imageStyle:alignLeft',
                'imageStyle:full',
                'imageStyle:alignRight'
              ],
              styles: ['full', 'alignLeft', 'alignRight']
            },
            ckfinder: {
              uploadUrl: this.$app.route('images.upload')
            }
          })
        } catch (e) {
          throw e
        }

        if (this.editor) {
          if (this.value) {
            this.editor.setData(this.value)
          }

          this.editor.model.document.on('change', () => {
            this.$emit('input', this.editor.getData())
          })
        }
      }
    }
  }
}
</script>
