<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center" v-if="model.as_emails">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Test email sending
                </h3>
                <div class="multi-lang multi-lang-open">
                  <form @submit.prevent="testEmailSend">
                    <b-form-group
                      :label="'Test recipient *'"
                      label-for="test_recipient"
                      :label-cols="3"
                      :feedback="feedback('recipient_email')"
                    >
                      <b-row>
                        <b-col md="12">
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-title"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fab fa-autoprefixer"></i>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              id="subject"
                              name="subject"
                              v-model="test_recipient"
                              :state="state('recipient_email')"
                            ></b-form-input>
                            <div class="invalid-feedback" v-if="test_error">
                              {{ test_error }}
                            </div>
                          </b-input-group>
                          <button
                            type="submit"
                            class="btn float-left bnt-save btn-success btn-sm btn-submit mt-3"
                          >
                            {{ $t('email-services.button.send-test-email') }}
                          </button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </form>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Sending options
                </h3>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.recipients')"
                    label-for="recipients"
                    horizontal
                    :label-cols="3"
                    :feedback="feedback('recipients')"
                    :state="state('recipients')"
                  >
                    <b-form-select
                      id="recipients"
                      name="recipients"
                      v-model="model.recipients"
                      :options="recipients"
                    >
                    </b-form-select>

                    <b-form-checkbox-group
                      class="segments-wrap mt-3"
                      stacked
                      v-model="model.segments"
                      name="segments[]"
                      v-if="model.recipients == 'send_to_segments'"
                    >
                      <b-form-checkbox
                        v-for="(segment, segmentIndex) in segments"
                        :key="segmentIndex"
                        :value="segmentIndex"
                      >
                        <span>{{ segment }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <b-form-feedback>
                      {{ feedback('segments') }}
                    </b-form-feedback>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab title="Preview" id="preview">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang group-lang-seo">
                <h3 class="header-card-title card-title" slot="header">
                  Preview
                </h3>
                <b-form-group label="From" label-for="locale" label-cols-sm="3">
                  <div class="data-identifier-block">
                    <span>{{ model.from_name }} - {{ model.from_email }}</span>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Subject"
                  label-for="locale"
                  label-cols-sm="3"
                >
                  <div class="data-identifier-block">
                    <span>{{ model.subject }}</span>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Content"
                  label-for="locale"
                  label-cols-sm="3"
                >
                  <div
                    class="data-identifier-block-html"
                    v-html="model.merged_content"
                  >
                    <span></span>
                  </div>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <send-footer
          :type="modelName"
          :send-enabled="false"
          :back-enabled="true"
          :back-url="backPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </send-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import SendFooter from '@email-services/js/components/SendLists/Elements/SendFooter'
import axios from 'axios'

export default {
  name: `CampaignPreviewForm`,
  components: {
    VueTabs,
    VTab,
    SendFooter
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'send_list',
      resourceRoute: 'send_lists_send',
      listPath: '/send/lists/send',
      backPath: '/send/lists/' + this.id + '/edit',
      returnPath: 'send/lists',
      model: {
        id: '',
        // Data
        name: '',
        subject: '',
        from_name: '',
        from_email: '',
        template_id: null,
        email_service_id: '',
        is_open_tracking: true,
        is_click_tracking: true,
        as_notifications: true,
        as_emails: true,
        content: '',
        merged_content: '',
        // Options
        recipients: 'send_to_segments',
        segments: [],
        schedule: 'now',
        scheduled_at: '',
        behaviour: 'auto',
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      test_recipient: '',
      test_pending: false,
      test_error: '',
      data: {},
      meta: {},
      recipients: {
        send_to_all: 'Send to all',
        send_to_segments: 'Send by recipient types'
      },
      segments: {
        1: 'Escorts',
        2: 'Agencies',
        3: 'Brothels',
        4: 'Parlours',
        5: 'Clubs'
      },
      schedule: [],
      behaviour: []
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('email-services.title.campaign-edit')
    //this.initSegments()
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = 'Send list'
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {},
    async initSegments() {
      let { data } = await axios.get(
        this.$app.route('email_segments.get_segments')
      )
      this.segments = data
    },
    testEmailSending() {
      try {
        this.test_pending = true
        let { data } = axios.post(
          this.$app.route('email_campaign.test_email_sending'),
          {
            campaign_id: this.id,
            recipient_email: this.test_recipient
          }
        )
        this.test_pending = false
        this.$app.noty[data.status](data.message)
        // return []
      } catch (e) {
        this.$app.error(e)
        this.test_pending = false
        // return []
      }
    },
    async testEmailSend() {
      let formData = new FormData()
      formData.append('send_list_id', this.id)
      formData.append('recipient_email', this.test_recipient)

      try {
        let { data } = await axios.post(
          this.$app.route('send_lists.test_email_sending'),
          formData
        )
        this.test_error = ''
        this.$app.noty[data.status](data.message)
        this.test_recipient = ''
      } catch (e) {
        if (e.response.data.errors) {
          // this.$app.noty['error'](e.response.data.message)
          if (
            e.response.data.errors.hasOwnProperty('recipient_email') &&
            e.response.data.errors.recipient_email
          ) {
            this.test_error = e.response.data.errors.recipient_email[0]
          }
        }
        this.$app.error(e.response)
      }
    }
  }
}
</script>
