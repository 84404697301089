<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          <b-button
            to="/countries"
            exact
            variant="secondary"
            size="sm"
            class="float-none btn-back-small btn-back-small-categories"
          >
            <i class="fas fa-reply"></i>
            {{ $t('buttons.back') }}
          </b-button>
          {{ parentElementName }} / Geo
        </h3>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="geo.search"
        :identifier="$route.params.id"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="population"
          :sort-desc="true"
          :sticky-header="true"
        >
          <template v-slot:cell(id)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value"> </span>
            </div>
          </template>

          <!--          <template v-slot:cell(parent_id)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value"></span>
            </div>
          </template>-->

          <!--          <template v-slot:cell(a1code)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value"> </span>
            </div>
          </template>-->

          <!--          <template v-slot:cell(depth)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value"> </span>
            </div>
          </template>-->

          <template v-slot:cell(country)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value"> </span>
            </div>
          </template>

          <!--          <template v-slot:cell(level)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value"> </span>
            </div>
          </template>-->

          <!--          <template v-slot:cell(alternames)="row">
            <template v-if="row.item.reviewed">
              <div :class="row.item.reviewed ? 'inactive' : 'active'">
                <span
                  v-for="(alt, aKey) in JSON.parse(row.value)"
                  :key="aKey"
                  class="badge badge-grey"
                  v-if="aKey < 5"
                >
                  {{ alt }}
                </span>
                <span class="badge badge-grey">
                  ...
                </span>
              </div>
            </template>
            <template v-else>
              <div :class="row.item.reviewed ? 'inactive' : 'active'">
                <span
                  v-for="(alt, aKey) in JSON.parse(row.value)"
                  :key="aKey"
                  class="badge badge-grey"
                >
                  {{ alt }}
                </span>
              </div>
            </template>
          </template>-->
          <template v-slot:cell(city)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value" class="data-name-strong"></span>
            </div>
          </template>
          <template v-slot:cell(city_ascii)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value"></span>
            </div>
          </template>
          <template v-slot:cell(city_alt)="row">
            <div :class="row.item.reviewed ? 'inactive' : 'active'">
              <span v-text="row.value"></span>
            </div>
          </template>
          <template v-slot:cell(population)="row">
            <span
              class="pop-rec pop-big"
              v-if="row.value > 1000000"
              :class="row.item.reviewed ? 'inactive' : 'active'"
            >
              {{ row.value }}
            </span>
            <span
              class="pop-rec pop-medium"
              :class="row.item.reviewed ? 'inactive' : 'active'"
              v-if="row.value < 1000001 && row.value > 100000"
            >
              {{ row.value }}
            </span>
            <span
              class="pop-rec pop-stand"
              :class="row.item.reviewed ? 'inactive' : 'active'"
              v-if="row.value < 100001 && row.value > 10000"
            >
              {{ row.value }}
            </span>
            <span
              class="pop-rec pop-small"
              :class="row.item.reviewed ? 'inactive' : 'active'"
              v-if="row.value < 10001 && row.value > 0"
            >
              {{ row.value }}
            </span>
            <span
              class="pop-rec pop-none"
              v-if="row.value < 1"
              :class="row.item.reviewed ? 'inactive' : 'active'"
            >
              {{ row.value }}
            </span>
          </template>
          <template v-slot:cell(cities_source_status)="row">
            <template v-if="row.item.on_cities">
              <div
                class="pre-list-label"
                :class="row.item.reviewed ? 'inactive' : 'active'"
              >
                City status:
              </div>
              <span v-if="row.value" class="badge badge-success">
                {{ trans('core.label.active') }}
              </span>
              <span v-else class="badge badge-danger">
                {{ trans('core.label.inactive') }}
              </span>
            </template>
          </template>
          <template v-slot:cell(reviewed)="row">
            <div
              class="pre-list-label"
              :class="row.item.reviewed ? 'inactive' : 'active'"
            >
              Reviewed:
            </div>
            <c-switch
              v-if="row.item.can_edit"
              :checked="row.value"
              @change="onReviewedToggle(row.item.id)"
            ></c-switch>
          </template>
          <template v-slot:cell(on_cities)="row">
            <div
              class="pre-list-label"
              :class="row.item.reviewed ? 'inactive' : 'active'"
            >
              On city list:
            </div>
            <c-switch
              v-if="row.item.can_edit"
              :checked="row.value"
              @change="onActiveToggle(row.item.id)"
            ></c-switch>
          </template>
          <template v-slot:cell(actions)="row">
            <a
              size="sm"
              v-b-tooltip.hover
              :title="trans('geo.buttons.show-on-map')"
              class="btn mr-1 btn-primary btn-sm"
              :href="
                `https://maps.google.com/maps?q=${row.item.lat},${row.item.lng}`
              "
              target="_blank"
              v-if="row.item.lat && row.item.lng"
            >
              <i class="fas fa-map-marker-alt"></i>
            </a>
            <b-button
              size="sm"
              variant="primary"
              v-b-tooltip.hover
              :title="trans('core.button.edit')"
              class="mr-1"
              @click="GeoRecord(row.item.id)"
            >
              <i class="fas fa-edit"></i>
            </b-button>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import GeoRecord from '@geo/js/components/modals/GeoRecord'
export default {
  title: '',
  name: 'GeoList',
  data() {
    return {
      routeData: false,
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      parentElement: [],
      parentElementName: '',
      fields: [
        {
          key: 'id',
          label: this.$t('geo.labels.geo-id'),
          sortable: true,
          class: 'data-ids'
        },
        {
          key: 'iso2',
          label: this.$t('geo.labels.country-code'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'country',
          label: 'Country',
          sortable: true,
          class: 'data-name'
        },

        /*        {
          key: 'country_name',
          label: this.$t('geo.labels.country-name'),
          sortable: true,
          class: 'data-name'
        },*/
        /*        {
          key: 'lat',
          label: this.$t('geo.labels.latitude'),
          sortable: true,
          class: 'data-name text-right'
        },
        {
          key: 'long',
          label: this.$t('geo.labels.longitude'),
          sortable: true,
          class: 'data-name text-right'
        },*/
        /*        {
          key: 'city_alt',
          label: this.$t('geo.labels.geo-alternames'),
          sortable: true,
          class: 'data-name'
        },*/
        {
          key: 'admin_type',
          label: 'Admin type',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'city_ascii',
          label: 'Name ASCII',
          sortable: true,
          class: 'data-name data-name-wide'
        },
        {
          key: 'reviewed',
          label: this.$t('geo.labels.reviewed'),
          class: 'text-center'
        },
        {
          key: 'city',
          label: this.$t('geo.labels.name'),
          sortable: true,
          class: 'data-name data-name-wide'
        },

        /*        {
          key: 'adminCode',
          label: 'SubDivision code',
          sortable: true,
          class: 'data-name'
        },*/
        {
          key: 'population',
          label: this.$t('geo.labels.population'),
          sortable: true,
          class: 'data-name text-right data-population'
        },

        /*        {
          key: 'timezone',
          label: this.$t('geo.labels.timezone'),
          sortable: true,
          class: 'data-name'
        },*/
        /*        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },*/

        {
          key: 'on_cities',
          label: 'Listed in cities',
          class: 'text-center'
        },
        {
          key: 'cities_source_status',
          label: 'City status',
          sortable: true,
          class: 'data-required'
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('geo.actions.destroy'),
        enable: this.$t('geo.actions.enable'),
        disable: this.$t('geo.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('geo.titles.city_list')
    // this.$store.commit('categories/setIdentifier', this.data.identifier)
  },
  async beforeMount() {
    //await this.getParentElementName()
  },
  methods: {
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ geo: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('geo.enabled', { geo: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
          this.$refs.datatable.refresh()
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onReviewedToggle(id) {
      axios
        .post(this.$app.route('geo.reviewed', { geo: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
          this.$refs.datatable.refresh()
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    GeoRecord(id) {
      this.$modal.show(
        GeoRecord,
        {
          modal: true,
          id: id
        },
        {
          name: 'geo-record-modal',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    async getParentElementName() {
      let { data } = await axios.get(
        this.$app.route('countries.parent.name', {
          country: this.$route.params.id
        })
      )
      this.parentElementName = data
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: 8px;
}
</style>
