<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('banners.titles.create') }}
            </h3>

            <b-form-group
              :label="$t('banners.field.name') + ' *'"
              label-for="name"
              :label-cols="3"
              :feedback="feedback('name')"
            >
              <b-row>
                <b-col md="8">
                  <b-input-group>
                    <b-input-group-prepend class="input-group-prepend-title">
                      <b-input-group-text data-toggle>
                        <i class="fab fa-autoprefixer"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      id="name"
                      name="name"
                      v-model="model.name"
                      :state="state('name')"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="visible"
                    v-model="model.visible"
                    :description="$t('banners.field.visible')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <template v-if="!modal">
              <b-row slot="footer">
                <b-col>
                  <b-button to="/banners" exact variant="danger" size="sm">
                    {{ $t('buttons.back') }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    size="sm"
                    class="float-right"
                    :disabled="pending"
                    v-if="isNew"
                  >
                    {{ $t('buttons.create') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="modal">
              <action-footer-modal
                :page-title="$t('banners.titles.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="true"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'

export default {
  title: '',
  name: 'BannerCreateForm',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'banner',
      resourceRoute: 'banners',
      listPath: '/banners',
      model: {
        // Data
        name: '',
        visible: false,
        // Routes
        routes: {}
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('banners.titles.create')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    }
  }
}
</script>
