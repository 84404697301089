<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                    v-if="locale.backend"
                  >
                    <b-form-group
                      :label="$t('validation.attributes.name')"
                      :label-for="`name.${index}`"
                      label-cols-sm="3"
                      :feedback="feedback('name.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`name.${index}`"
                          :name="`name.${index}`"
                          v-model="model.name[`${index}`]"
                          :state="state('name.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  :label="$t('validation.attributes.order')"
                  label-for="order"
                  label-cols-sm="3"
                >
                  <b-row>
                    <b-col md="3">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-position"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-arrows-alt"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="order"
                          name="order"
                          type="number"
                          v-model="model.order"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="enabled"
                        v-model="model.enabled"
                        :description="$t('validation.attributes.enabled')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <!--        <v-tab :title="$t('labels.content')" id="content">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-seo"
                v-if="locale.backend"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.content') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('validation.attributes.content')"
                  :label-for="`content.${index}`"
                  label-cols-sm="3"
                  :feedback="feedback('content.' + index)"
                >
                  <p-richtexteditor
                    name="content"
                    v-model="model.content[`${index}`]"
                  ></p-richtexteditor>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>-->

        <!-- conR <v-tab :title="$t('labels.url')" id="url">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-url"
                v-if="locale.backend"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.url') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('validation.attributes.url')"
                  :label-for="`routes.${index}.slug`"
                  label-cols-sm="3"
                  :feedback="feedback('routes.' + index + '.slug')"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`routes.${index}.slug`"
                      :name="`routes.${index}.slug`"
                      v-model="model.routes[`${index}`]['slug']"
                      :state="state('routes.' + index + '.slug')"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-link"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('routes.' + index + '.slug') }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.url-target')"
                  label-for="target"
                  :description="$t('validation.attributes.url-target-info')"
                  label-cols-sm="3"
                >
                  <div class="url-link">
                    <a
                      :href="
                        $app.appUrlClient +
                          '/' +
                          meta.routes[`${index}`]['slug']
                      "
                      target="_blank"
                    >
                      {{ $app.appUrlClient }}/{{
                        meta.routes[`${index}`]['slug']
                      }}
                    </a>
                    <template
                      v-if="
                        meta.routes[`${index}`]['slug'] !==
                          model.routes[`${index}`]['slug']
                      "
                    >
                      <span class="url-link-new">
                        <i class="fas fa-long-arrow-alt-right"></i>
                        {{ $app.appUrlClient }}/{{
                          model.routes[`${index}`]['slug']
                        }}
                      </span>
                    </template>
                  </div>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab> -->

        <!-- conM <v-tab :title="$t('labels.seo')" id="seo">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-seo"
                v-if="locale.backend"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.seo') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('labels.meta.title')"
                  :label-for="`metadata.title.${index}`"
                  label-cols-sm="3"
                  :description="$t('labels.meta.title-note')"
                  :feedback="feedback('metadata.title.' + index)"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`metadata.title.${index}`"
                      :name="`metadata.title.${index}`"
                      v-model="model.metadata.title[`${index}`]"
                      :state="state('metadata.title.' + index)"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-window-maximize"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('metadata.title.' + index) }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.meta.description')"
                  :label-for="`metadata.description.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`metadata.description.${index}`"
                    :name="`metadata.description.${index}`"
                    :rows="3"
                    v-model="model.metadata.description[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.meta.keywords')"
                  :label-for="`metadata.keywords.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`metadata.keywords.${index}`"
                    :name="`metadata.keywords.${index}`"
                    :rows="3"
                    v-model="model.metadata.keywords[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>-->

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `ParlourServiceEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'parlour_service',
      resourceRoute: 'parlour_services',
      listPath: '/parlour/services',
      model: {
        // Data
        name: {},
        icon: '',
        content: {},
        order: 0,
        enabled: false,
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('parlour-services.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('parlour-services.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
