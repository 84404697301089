<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          <b-button
            to="/countries"
            exact
            variant="secondary"
            size="sm"
            class="float-none btn-back-small btn-back-small-categories"
          >
            <i class="fas fa-reply"></i>
            {{ $t('buttons.back') }}
          </b-button>
          {{ parentElementName }} / Cities
        </h3>
        <div class="card-options" v-if="this.$app.user.can('create cities')">
          <b-button @click="modalCreate" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('geo.buttons.create-city') }}
          </b-button>
        </div>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="cities.search"
        delete-route="cities.destroy"
        action-route="cities.batch_action"
        :identifier="$route.params.id"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="population"
          :sort-desc="true"
        >
          <template v-slot:cell(name)="row">
            <div>
              <a
                href="#"
                @click="CityRecord(row.item.id)"
                class=""
                v-if="row.item.can_edit"
              >
                {{ row.value }}
              </a>
              <span v-else v-text="row.value" class="data-name-strong"></span>
            </div>
          </template>
          <template v-slot:cell(enabled)="row">
            <c-switch
              v-if="row.item.can_edit"
              :checked="row.value"
              @change="onActiveToggle(row.item.id)"
            ></c-switch>
          </template>
          <template v-slot:cell(status_manual)="row">
            <span v-if="row.value" class="badge badge-success">
              {{ trans('core.label.yes') }}
            </span>
            <!--            <span v-else class="badge badge-danger">
              {{ trans('core.label.no') }}
            </span>-->
          </template>
          <template v-slot:cell(population)="row">
            <span class="pop-rec pop-big" v-if="row.value > 1000000">
              {{ row.value }}
            </span>
            <span
              class="pop-rec pop-medium"
              v-if="row.value < 1000001 && row.value > 100000"
            >
              {{ row.value }}
            </span>
            <span
              class="pop-rec pop-stand"
              v-if="row.value < 100001 && row.value > 10000"
            >
              {{ row.value }}
            </span>
            <span
              class="pop-rec pop-small"
              v-if="row.value < 10001 && row.value > 0"
            >
              {{ row.value }}
            </span>
            <span class="pop-rec pop-none" v-if="row.value < 1">
              {{ row.value }}
            </span>
          </template>
          <template v-slot:cell(actions)="row">
            <a
              size="sm"
              v-b-tooltip.hover
              :title="trans('geo.buttons.show-on-map')"
              class="btn mr-1 btn-primary btn-sm"
              :href="
                `https://maps.google.com/maps?q=${row.item.latitude},${row.item.longitude}`
              "
              target="_blank"
              v-if="row.item.latitude && row.item.longitude"
            >
              <i class="fas fa-map-marker-alt"></i>
            </a>
            <b-button
              size="sm"
              variant="primary"
              v-b-tooltip.hover
              :title="trans('core.button.edit')"
              class="mr-1"
              @click="CityRecord(row.item.id)"
            >
              <i class="fas fa-edit"></i>
            </b-button>
            <b-dropdown
              size="sm"
              text=""
              class="adm-btn-dropdown action-btn-dropdown hidden"
            >
              <b-dropdown-item @click.stop="onDelete(row.item.id)">
                <span class="icon-holder icon-danger">
                  <i class="fas fa-trash-alt"></i>
                </span>
                <span class="label-holder label-delete">{{
                  trans('core.button.delete')
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import CityCreateForm from './CityCreateForm.vue'
import CityRecord from '@geo/js/components/modals/CityRecord'
export default {
  title: '',
  name: 'CityList',
  data() {
    return {
      routeData: false,
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      parentElement: [],
      parentElementName: '',
      fields: [
        {
          key: 'name',
          label: this.$t('geo.labels.city-name'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'country_name',
          label: this.$t('geo.labels.country_name'),
          sortable: true,
          class: 'data-name'
        },
        /*        {
          key: 'adminCode',
          label: 'SubDivision code',
          sortable: true,
          class: 'data-name'
        },*/
        {
          key: 'population',
          label: this.$t('geo.labels.population'),
          sortable: true,
          class: 'data-name text-right data-population'
        },
        {
          key: 'latitude',
          label: this.$t('geo.labels.latitude'),
          sortable: true,
          class: 'data-name text-right'
        },
        {
          key: 'longitude',
          label: this.$t('geo.labels.longitude'),
          sortable: true,
          class: 'data-name text-right'
        },
        {
          key: 'slug',
          label: this.$t('geo.labels.city-slug'),
          sortable: true,
          class: 'data-name text-right'
        },
        {
          key: 'enabled',
          label: this.$t('validation.attributes.enabled'),
          class: 'text-center'
        },
        {
          key: 'status_manual',
          label: this.$t('geo.labels.status_manual'),
          class: 'text-center'
        },
        /*        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },*/
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('geo.actions.destroy'),
        enable: this.$t('geo.actions.enable'),
        disable: this.$t('geo.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('geo.titles.city_list')
    // this.$store.commit('categories/setIdentifier', this.data.identifier)
  },
  async beforeMount() {
    //await this.getParentElementName()
  },
  methods: {
    modalCreate() {
      this.$modal.show(
        CityCreateForm,
        {
          modal: this.isModalCreate,
          identifier: this.$route.params.id
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    CityRecord(id) {
      this.$modal.show(
        CityRecord,
        {
          modal: true,
          id: id
        },
        {
          name: 'city-record-modal',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ city: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('cities.enabled', { city: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
          this.$refs.datatable.refresh()
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    async getParentElementName() {
      let { data } = await axios.get(
        this.$app.route('countries.parent.name', {
          country: this.$route.params.id
        })
      )
      this.parentElementName = data
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: 8px;
}
</style>
