export default (app, i18n, newPostsCount, pendingPostsCount) => {
  return [
    {
      name: i18n.t('labels.titles.dashboard'),
      url: '/dashboard',
      icon: 'fe fe-trending-up',
      access: true,
      children: [
        {
          name: i18n.t('labels.titles.dashboard'),
          url: '/dashboard',
          icon: 'fe fe-trending-up',
          access: true
        }
      ]
    },
    {
      name: i18n.t('escorts.sidebar.title'),
      classes: 'nav-escorts',
      url: '/escorts',
      icon: 'fas fa-people-arrows',
      access: app.user.can('view escorts'),
      children: [
        {
          name: i18n.t('escorts.titles.unverified'),
          url: '/escorts/unverified',
          icon: 'fas fa-clock',
          access: app.user.can('view escorts')
        },
        {
          name: i18n.t('escorts-verifications.titles.full-verification'),
          url: '/escorts/verifications',
          icon: 'fas fa-portrait',
          access: app.user.can('view escorts-verifications')
        },
        {
          name: i18n.t('escorts.sidebar.title'),
          url: '/escorts',
          icon: 'fas fa-people-arrows',
          access: app.user.can('view escorts')
        }
      ]
    },
    {
      name: i18n.t('agency-information.sidebar.title'),
      classes: 'nav-agency',
      url: '/agencies',
      icon: 'fas fa-building',
      access: app.user.can('view agencies'),
      children: [
        {
          name: i18n.t('agencies.titles.unverified'),
          url: '/agencies/unverified',
          icon: 'fas fa-clock',
          access: app.user.can('view agencies')
        },
        {
          name: i18n.t('agencies.sidebar.title'),
          url: '/agencies',
          icon: 'fas fa-building',
          access: app.user.can('view agencies')
        },
        {
          title: true,
          name: i18n.t('labels.sidebar.options')
        },
        {
          name: i18n.t('agency-information.titles.list'),
          url: '/agency/information',
          icon: 'fas fa-info-circle',
          access: app.user.can('view agency_information')
        },
        {
          name: i18n.t('agency-services.sidebar.title'),
          url: '/agency/services',
          icon: 'fab fa-servicestack',
          access: app.user.can('view agency_services')
        }
      ]
    },
    {
      name: i18n.t('brothel-information.sidebar.title'),
      classes: 'nav-brothel',
      url: '/brothel/information',
      icon: 'fas fa-person-booth',
      access: app.user.can('view brothel_information'),
      children: [
        {
          name: i18n.t('brothels.titles.unverified'),
          url: '/brothels/unverified',
          icon: 'fas fa-clock',
          access: app.user.can('view brothels')
        },
        {
          name: i18n.t('brothels.sidebar.title'),
          url: '/brothels',
          icon: 'fas fa-person-booth',
          access: app.user.can('view brothels')
        },
        {
          title: true,
          name: i18n.t('labels.sidebar.options')
        },
        {
          name: i18n.t('brothel-information.titles.list'),
          url: '/brothel/information',
          icon: 'fas fa-info-circle',
          access: app.user.can('view brothel_information')
        },
        {
          name: i18n.t('brothel-services.sidebar.title'),
          url: '/brothel/services',
          icon: 'fab fa-servicestack',
          access: app.user.can('view brothel_services')
        }
      ]
    },
    {
      name: i18n.t('parlour-information.sidebar.title'),
      classes: 'nav-parlour',
      url: '/parlours',
      icon: 'fas fa-sign-language',
      access: app.user.can('view parlour_information'),
      children: [
        {
          name: i18n.t('parlours.titles.unverified'),
          url: '/parlours/unverified',
          icon: 'fas fa-clock',
          access: app.user.can('view parlours')
        },
        {
          name: i18n.t('parlours.sidebar.title'),
          url: '/parlours',
          icon: 'fas fa-sign-language',
          access: app.user.can('view parlours')
        },
        {
          title: true,
          name: i18n.t('labels.sidebar.options')
        },
        {
          name: i18n.t('parlour-information.titles.list'),
          url: '/parlour/information',
          icon: 'fas fa-info-circle',
          access: app.user.can('view parlour_information')
        },
        {
          name: i18n.t('parlour-services.sidebar.title'),
          url: '/parlour/services',
          icon: 'fab fa-servicestack',
          access: app.user.can('view parlour_services')
        },
        {
          name: i18n.t('parlour-ethnicity.sidebar.title'),
          url: '/parlour/ethnicity',
          icon: 'fas fa-user-tag',
          access: app.user.can('view parlour_ethnicity')
        }
      ]
    },
    {
      name: i18n.t('club-information.sidebar.title'),
      classes: 'nav-club',
      url: '/clubs',
      icon: 'fas fa-kaaba',
      access: app.user.can('view clubs'),
      children: [
        {
          name: i18n.t('clubs.titles.unverified'),
          url: '/clubs/unverified',
          icon: 'fas fa-clock',
          access: app.user.can('view clubs')
        },
        {
          name: i18n.t('clubs.sidebar.title'),
          url: '/clubs',
          icon: 'fas fa-kaaba',
          access: app.user.can('view clubs')
        },
        {
          title: true,
          name: i18n.t('labels.sidebar.options')
        },
        {
          name: i18n.t('club-information.titles.list'),
          url: '/club/information',
          icon: 'fas fa-info-circle',
          access: app.user.can('view club_information')
        },
        {
          name: i18n.t('club-services.sidebar.title'),
          url: '/club/services',
          icon: 'fab fa-servicestack',
          access: app.user.can('view club_services')
        },
        {
          name: i18n.t('club-types.sidebar.title'),
          url: '/club/types',
          icon: 'fas fa-user-tag',
          access: app.user.can('view club_types')
        }
      ]
    },
    {
      name: i18n.t('clients.sidebar.title'),
      classes: 'nav-club',
      url: '/clients',
      icon: 'fas fa-user-circle',
      access: app.user.can('view clients'),
      children: [
        /*        {
          name: i18n.t('clubs.titles.unverified'),
          url: '/clubs/unverified',
          icon: 'fas fa-clock',
          access: app.user.can('view clubs')
        },*/
        {
          name: i18n.t('clients.sidebar.title'),
          url: '/clients',
          icon: 'fas fa-user-circle',
          access: app.user.can('view clients')
        }
      ]
    },
    {
      name: i18n.t('reviews.sidebar.root'),
      url: '/reviews',
      icon: 'fab fa-asymmetrik',
      access:
        app.user.can('view reviews') || app.user.can('view reviews_escorts'),
      children: [
        {
          title: true,
          name: i18n.t('reviews.sidebar.section')
        },
        {
          name: i18n.t('reviews.sidebar.title'),
          url: '/reviews',
          access: app.user.can('view reviews'),
          icon: 'fas fa-receipt'
        },
        {
          name: i18n.t('reviews-escorts.sidebar.title'),
          url: '/reviews/escorts',
          access: app.user.can('view reviews_escorts'),
          icon: 'fas fa-receipt'
        },
        {
          title: true,
          name: i18n.t('fake-reports.sidebar.section')
        },
        {
          name: i18n.t('fake-reports.sidebar.title'),
          url: '/fake/reports',
          access: app.user.can('view fake_reports'),
          icon: 'fas fa-receipt'
        },
        {
          name: i18n.t('black-lists.sidebar.title'),
          url: '/black/lists',
          access: app.user.can('view black_lists'),
          icon: 'fas fa-receipt'
        }
      ]
    },
    {
      name: i18n.t('coins.sidebar.title'),
      url: '/movement',
      icon: 'fas fa-coins',
      access: app.user.can('view coins'),
      children: [
        {
          title: true,
          name: i18n.t('coins.sidebar.movement-title')
        },
        {
          name: i18n.t('coins.sidebar.coin-movement'),
          url: '/movement',
          icon: 'fas fa-history',
          access: app.user.can('view coins')
        },
        {
          title: true,
          name: i18n.t('coins.sidebar.packages')
        },
        {
          name: i18n.t('coins.sidebar.coin-packages'),
          url: '/packages',
          icon: 'fas fa-coins',
          access: app.user.can('view coins')
        }
      ]
    },
    {
      name: i18n.t('subscriptions.sidebar.title'),
      url: '/subscriptions',
      icon: 'fas fa-file-invoice-dollar',
      access: app.user.can('view subscriptions'),
      children: [
        /*        {
          title: true,
          name: i18n.t('orders.sidebar.title')
        },
        {
          name: i18n.t('orders.sidebar.title'),
          url: '/orders',
          icon: 'fas fa-file-invoice-dollar',
          access: app.user.can('view orders')
        },*/
        {
          title: true,
          name: i18n.t('subscriptions.sidebar.title')
        },
        {
          name: i18n.t('subscriptions.sidebar.title'),
          url: '/subscriptions',
          icon: 'fas fa-file-invoice-dollar',
          access: app.user.can('view subscriptions')
        },
        {
          title: true,
          name: i18n.t('subscriptions.sidebar.plans')
        },
        {
          name: i18n.t('subscriptions.sidebar.plans'),
          url: '/plans',
          icon: 'fas fa-file-invoice-dollar',
          access: app.user.can('view plans')
        },
        {
          title: true,
          name: i18n.t('subscriptions.sidebar.features')
        },
        {
          name: i18n.t('subscriptions.sidebar.features'),
          url: '/features',
          icon: 'fas fa-file-invoice-dollar',
          access: app.user.can('view features')
        }
      ]
    },
    {
      name: i18n.t('pages.sidebar.root'),
      url: '/pages',
      icon: 'fas fa-newspaper',
      access: app.user.can('view pages') || app.user.can('view blocks'),
      children: [
        {
          title: true,
          name: i18n.t('pages.sidebar.section')
        },
        {
          name: i18n.t('pages.sidebar.title'),
          url: '/pages',
          access: app.user.can('view pages'),
          icon: 'fas fa-file-alt'
        },
        {
          name: i18n.t('blocks.sidebar.title'),
          url: '/blocks',
          access: app.user.can('view blocks'),
          icon: 'fas fa-file-alt'
        },
        {
          title: true,
          name: i18n.t('blog.sidebar.section')
        },
        {
          name: i18n.t('blog.sidebar.title'),
          url: '/posts',
          access: app.user.can('view blog'),
          icon: 'fas fa-file-alt'
        },
        {
          name: i18n.t('blog.sidebar.categories-title'),
          url: '/posts/categories',
          access: app.user.can('view blog_categories'),
          icon: 'fas fa-file-alt'
        }
      ]
    },
    {
      name: i18n.t('banners.sidebar.title'),
      url: '/banners',
      icon: 'fas fa-ad',
      access: app.user.can('view banners'),
      children: [
        {
          name: i18n.t('banners.sidebar.title'),
          url: '/banners',
          icon: 'fas fa-ad',
          access: app.user.can('view banners')
        },
        {
          name: i18n.t('banners-links.sidebar.title'),
          url: '/banners/links',
          icon: 'fas fa-ad',
          access: app.user.can('view banners_links')
        }
      ]
    },

    {
      name: i18n.t('email-services.sidebar.title'),
      url: '/send/list',
      icon: 'fas fa-mail-bulk',
      classes: 'nav-send-services',
      access:
        app.user.can('view send_list') ||
        app.user.can('view email_messages') ||
        app.user.can('view email_services') ||
        app.user.can('view email_templates'),
      children: [
        {
          name: i18n.t('email-services.sidebar.list-title'),
          url: '/send/lists',
          icon: 'fas fa-paper-plane',
          access: app.user.can('view send_lists')
        },
        /*        {
          name: i18n.t('email-services.sidebar.messages-title'),
          url: '/email/messages',
          icon: 'fas fa-envelope',
          access: app.user.can('view email_messages')
        },*/
        /*        {
          name: i18n.t('email-services.sidebar.notifications-title'),
          url: '/send/notifications',
          icon: 'fas fa-bell',
          access: app.user.can('view email_messages')
        },*/
        {
          name: i18n.t('email-services.sidebar.templates-title'),
          url: '/email/templates',
          icon: 'fas fa-file-alt',
          access: app.user.can('view email_templates')
        },
        {
          name: i18n.t('email-services.sidebar.service-title'),
          url: '/email/services',
          icon: 'fas fa-project-diagram',
          access: app.user.can('view email_services')
        }
      ]
    },

    {
      name: i18n.t('users-labels.sidebar.root'),
      url: '/users',
      icon: 'fas fa-user-lock',
      access: app.user.can('view users') || app.user.can('view roles'),
      children: [
        {
          name: i18n.t('users-labels.sidebar.title'),
          url: '/users',
          icon: 'fe fe-users',
          access: app.user.can('view users')
        },
        {
          name: i18n.t('roles-labels.sidebar.title'),
          url: '/roles',
          icon: 'fe fe-shield',
          access: app.user.can('view roles')
        }
      ]
    },
    {
      name: i18n.t('labels.sidebar.settings.title'),
      url: '/translations',
      icon: 'fas fa-cogs',
      access: app.user.can('view settings'),
      classes: 'nav-settings',
      children: [
        {
          name: i18n.t('settings.sidebar.title'),
          url: '/settings',
          access: app.user.can('view settings'),
          icon: 'fas fa-cogs'
        },
        {
          title: true,
          name: i18n.t('languages.sidebar.section')
        },
        {
          name: i18n.t('languages.sidebar.section'),
          url: '/languages',
          access: app.user.can('view languages'),
          icon: 'fas fa-globe'
        },
        {
          name: i18n.t('translations.sidebar.section'),
          url: '/translations',
          access: app.user.can('view translations'),
          icon: 'fas fa-language'
        },
        {
          title: true,
          name: i18n.t('geo.sidebar.section')
        },
        {
          name: i18n.t('geo.sidebar.countries'),
          url: '/countries',
          access: app.user.can('view countries'),
          icon: 'fa fa-flag'
        },
        {
          name: 'Cities',
          url: '/cities',
          access: app.user.can('view cities'),
          icon: 'fa fa-city'
        }
      ]
    },
    {
      divider: true,
      access: true
    }
  ]
}
