<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Verification
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      Current status:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <span v-html="statusRender(model.status)"></span>
                    </div>
                  </div>
                  <b-form-group
                    label="Status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'reviewed'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_reviewed"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'revise'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_revise"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'correct'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_active"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'suspended'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_suspended"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Reason for suspension"
                    label-for="canceled_content"
                    :label-cols="3"
                    :feedback="feedback('canceled_content')"
                    v-if="model.status_to == 'suspended'"
                  >
                    <b-input-group>
                      <p-richtexteditor
                        id="canceled_content"
                        name="canceled_content"
                        v-model="model.canceled_content"
                        :rows="10"
                      ></p-richtexteditor>
                    </b-input-group>
                    <b-form-feedback>
                      {{ feedback('canceled_content') }}
                    </b-form-feedback>
                  </b-form-group>
                  <b-form-group
                    label="Reason for adjustment"
                    label-for="revise_content"
                    :label-cols="3"
                    :feedback="feedback('revise_content')"
                    v-if="model.status_to == 'revise'"
                  >
                    <b-input-group>
                      <p-richtexteditor
                        id="revise_content"
                        name="revise_content"
                        v-model="model.revise_content"
                        :rows="10"
                      ></p-richtexteditor>
                    </b-input-group>
                    <b-form-feedback>
                      {{ feedback('revise_content') }}
                    </b-form-feedback>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center" v-if="data.data_verification">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Information
                </h3>
                <div class="data-content-wrap">
                  <b-row>
                    <b-col md="6" class="new-data-wrap">
                      <b-row>
                        <b-col md="12" class="data-row-title">
                          New Information
                        </b-col>
                      </b-row>
                      <b-row
                        v-for="(rec, recIndex) in data.data"
                        :key="recIndex"
                      >
                        <b-col md="4" class="data-row-label">
                          <template v-if="recIndex == 'show_name'">
                            Show name
                          </template>
                          <template v-if="recIndex == 'description'">
                            Description
                          </template>
                          <template v-if="recIndex == 'web_address'">
                            Contact web address
                          </template>
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          {{ rec }}
                        </b-col>
                      </b-row>
                      <b-row v-if="model.video_verification">
                        <b-col md="4" class="data-row-label">
                          Video
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          Verification needed
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6" class="old-data-wrap">
                      <b-row>
                        <b-col md="12" class="data-row-title">
                          Old Information
                        </b-col>
                      </b-row>
                      <b-row
                        v-for="(rec, recIndex) in data.data_old"
                        :key="recIndex"
                      >
                        <b-col md="4" class="data-row-label">
                          <template v-if="recIndex == 'show_name'">
                            Show name
                          </template>
                          <template v-if="recIndex == 'description'">
                            Description
                          </template>
                          <template v-if="recIndex == 'web_address'">
                            Contact web address
                          </template>
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          {{ rec }}
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center" v-if="data.photo_verification">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Photos
                </h3>
                <div class="file-content-wrap">
                  <div class="file-content">
                    <div class="files-container is-fileinfo-visible">
                      <div class="file-grid-wrapper">
                        <section class="file-list grid">
                          <CoolLightBox
                            :items="model.photos"
                            :index="indexMedia"
                            @close="indexMedia = null"
                          >
                          </CoolLightBox>
                          <div
                            class="file-wrapper"
                            v-for="(image, imageIndex) in model.photos"
                            :key="imageIndex"
                          >
                            <div class="file-item" v-if="!image.status_delete">
                              <div
                                class="icon-item"
                                @click="indexMedia = imageIndex"
                              >
                                <img
                                  class="image"
                                  :src="
                                    `${$app.appUrlClient}/storage/${image.path}`
                                  "
                                />
                              </div>
                              <div class="action-wrap">
                                <div class="action-delete">
                                  <div
                                    class="remove-photo"
                                    @click="removePhoto(image.id)"
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                  </div>
                                </div>
                                <div class="action-verify">
                                  <c-switch
                                    name="public"
                                    v-model="image.status_verify"
                                    description="Verified"
                                  ></c-switch>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="false"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `EscortEditUnverifiedForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      indexMedia: '',
      title: '',
      subTitle: '',
      modelName: 'escort_verification',
      resourceRoute: 'escorts_verifications',
      listPath: '/escorts/unverified',
      model: {
        // Data
        name: '',
        email: '',
        description: '',
        status: '',
        status_to: '',
        canceled_content: '',
        revise_content: '',
        position: 0,
        public: false,
        photo_verification: false,
        video_verification: false,
        photos: [],
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {},
      statuses_reviewed: {
        correct: 'Data is correct',
        revise: 'Revise',
        suspended: 'Suspended'
        // correction: 'Data correction'
      },
      statuses_active: {
        suspended: 'Suspended'
        // correction: 'Data correction'
      },
      statuses_suspended: {
        correct: 'Data is correct'
      },
      statuses: {
        suspended: 'Suspended',
        revise: 'Revise'
      }
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('escorts.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('escorts.titles.unit')
  },
  methods: {
    removePhoto(record) {
      let photoRecord = this.model.photos.find(item => item.id === record)
      photoRecord.status_delete = true
    },
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {},
    statusRender(status) {
      return (
        '<span class="badge badge-' +
        status +
        '">' +
        this.trans('escorts.status.' + status) +
        '<span>'
      )
    }
  }
}
</script>
