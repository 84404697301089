<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          Coins movement
        </h3>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="movement.search"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(movement_link_object_type)="row">
            <span v-if="row.item.movement_link_object.type">
              {{ row.item.movement_link_object.type }}
            </span>
          </template>
          <template v-slot:cell(movement_link_object_name)="row">
            <a
              v-if="row.item.movement_link_object.url"
              :href="`${$app.appUrlClient}${row.item.movement_link_object.url}`"
              target="_blank"
              class="dash-tr-link"
            >
              <span
                v-text="row.item.movement_link_object.name"
                class="dash-tr-link"
              ></span>
            </a>
            <span
              v-else
              v-text="row.item.movement_link_object.name"
              class="dash-tr-text"
            ></span>
          </template>
          <template v-slot:cell(amount)="row">
            <span v-if="row.item.type == 'I'">
              <span class="amount-plus">+ {{ row.value }}</span>
            </span>
          </template>
          <template v-slot:cell(total_before)="row">
            <span>
              <span class="amount-before">{{ row.value }}</span>
            </span>
          </template>
          <template v-slot:cell(total_after)="row">
            <span>
              <span class="amount-after">{{ row.value }}</span>
            </span>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  title: '',
  name: 'MovementList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'movement_link_object_type',
          label: 'Record from',
          class: 'data-name text-left'
        },
        {
          key: 'movement_link_object_name',
          label: 'Belongs to',
          class: 'data-name text-left'
        },
        {
          key: 'amount',
          label: 'Coin amount',
          sortable: true,
          class: 'data-name text-right'
        },
        {
          key: 'total_before',
          label: 'Total coins before',
          sortable: true,
          class: 'data-name text-right'
        },
        {
          key: 'total_after',
          label: 'Total coins after',
          sortable: true,
          class: 'data-name text-right'
        },
        {
          key: 'content',
          label: 'Details',
          sortable: true,
          class: 'data-name text-center'
        },
        {
          key: 'created_at',
          label: 'Date',
          class: 'data-name text-center',
          sortable: true
        }
      ]
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = 'Coins movement'
  },
  methods: {
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    }
  }
}
</script>
