<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="7" lg="7">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Profile Verification
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      Profile:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <span>
                        <a
                          v-if="model.escort_url"
                          :href="`${$app.appUrlClient}${model.escort_url}`"
                          target="_blank"
                          class="inner-tr-link"
                        >
                          <span
                            v-text="model.escort_name"
                            class="inner-tr-link"
                          ></span>
                        </a>
                      </span>
                    </div>
                  </div>
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      Current status:
                    </label>
                    <div
                      class="col-form-label col-form-label-value"
                      v-if="!model.verified"
                    >
                      <span v-html="statusRender('unverified')"></span>
                    </div>
                    <div
                      class="col-form-label col-form-label-value"
                      v-if="model.verified"
                    >
                      <span v-html="statusRender(model.verified)"></span>
                    </div>
                  </div>
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      Code:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <span>{{ model.code }}</span>
                    </div>
                  </div>
                  <div
                    role="group"
                    class="form-row form-group form-row-info"
                    v-if="model.reason && model.verified"
                  >
                    <label class="col-3 col-form-label">
                      Reason:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <span v-html="model.reason"></span>
                    </div>
                  </div>
                  <b-form-group
                    label="Status"
                    label-for="verified"
                    :label-cols="3"
                    :feedback="feedback('verified')"
                    v-if="!model.verified"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_verified"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Reason for decline"
                    label-for="reason"
                    :label-cols="3"
                    :feedback="feedback('reason')"
                    v-if="model.status_to == 2"
                  >
                    <b-input-group>
                      <p-richtexteditor
                        id="reason"
                        name="reason"
                        v-model="model.reason"
                        :rows="10"
                      ></p-richtexteditor>
                    </b-input-group>
                    <b-form-feedback>
                      {{ feedback('reason') }}
                    </b-form-feedback>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="12" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Photos
                </h3>
                <div class="file-content-wrap file-content-wrap-full">
                  <div class="file-content">
                    <div class="files-container is-fileinfo-visible">
                      <div class="file-grid-wrapper">
                        <section class="file-list grid">
                          <CoolLightBox
                            :items="model.photos"
                            :index="indexMedia"
                            @close="indexMedia = null"
                          >
                          </CoolLightBox>
                          <div
                            class="file-wrapper"
                            v-for="(image, imageIndex) in model.photos"
                            :key="imageIndex"
                          >
                            <div class="file-item">
                              <div
                                class="icon-item"
                                @click="indexMedia = imageIndex"
                              >
                                <img class="image" :src="image.src" />
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="false"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `EscortVerificationEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      indexMedia: '',
      title: '',
      subTitle: '',
      modelName: 'escort_verification',
      resourceRoute: 'profiles_verifications',
      listPath: '/escorts/verifications',
      model: {
        // Data
        code: '',
        escort_name: '',
        escort_url: '',
        status_to: '',
        verified: '',
        reason: '',
        photos: [],
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {},
      statuses_verified: {
        1: 'Verified',
        2: 'Declined'
      }
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('escorts-verifications.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('escorts-verifications.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {},
    statusRender(status) {
      return (
        '<span class="badge badge-' +
        status +
        '">' +
        this.trans('escorts.status.' + status) +
        '<span>'
      )
    }
  }
}
</script>
