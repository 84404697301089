<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <b-form-group
                  :label="$t('validation.attributes.name')"
                  label-for="name"
                  horizontal
                  :label-cols="3"
                  :feedback="feedback('name')"
                >
                  <b-form-input
                    id="name"
                    name="name"
                    required
                    :placeholder="$t('validation.attributes.name')"
                    v-model="model.name"
                    :state="state('name')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.email')"
                  label-for="email"
                  horizontal
                  :label-cols="3"
                  :feedback="feedback('email')"
                >
                  <b-form-input
                    id="email"
                    name="email"
                    type="email"
                    required
                    :placeholder="$t('validation.attributes.email')"
                    v-model="model.email"
                    :state="state('email')"
                  ></b-form-input>
                </b-form-group>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="active"
                        v-model="model.active"
                        :description="$t('validation.attributes.active')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
                <b-form-group
                  :label="$t('validation.attributes.password')"
                  label-for="password"
                  horizontal
                  :label-cols="3"
                  :feedback="feedback('password')"
                >
                  <b-form-input
                    id="password"
                    name="password"
                    type="password"
                    :placeholder="$t('validation.attributes.password')"
                    v-model="model.password"
                    :state="state('password')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.password_confirmation')"
                  label-for="password_confirmation"
                  horizontal
                  :label-cols="3"
                  :feedback="feedback('password_confirmation')"
                >
                  <b-form-input
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    :placeholder="
                      $t('validation.attributes.password_confirmation')
                    "
                    v-model="model.password_confirmation"
                    :state="state('password_confirmation')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.roles')"
                  label-for="roles"
                  horizontal
                  :label-cols="3"
                >
                  <b-form-checkbox-group
                    stacked
                    v-model="model.roles"
                    name="roles[]"
                  >
                    <b-form-checkbox
                      v-for="role in roles"
                      :key="role.id"
                      v-b-tooltip.left
                      :title="role.description"
                      :value="role.id"
                    >
                      {{ role.display_name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab
          :title="$t('users-labels.users.labels.restrictions')"
          id="restrictions"
        >
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang group-lang-seo">
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('users-labels.users.labels.restrictions') }}
                </h3>
                <b-form-group
                  :label="$t('users-labels.users.labels.by-country')"
                  label-for="country"
                  :label-cols="3"
                  :feedback="feedback('country')"
                >
                  <b-row>
                    <b-col md="8">
                      <multiselect
                        class="input-m-select input-h-select"
                        v-model="model.restricted_country"
                        track-by="code"
                        label="asciiname"
                        placeholder=""
                        :options="countries"
                        :searchable="false"
                        :allow-empty="true"
                        :multiple="false"
                        autocomplete="off"
                        @input="setCountry"
                        @select="setCountry"
                        @remove="removeCountry"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>
                            {{ option.asciiname }} ({{ option.name }})
                          </strong>
                          <img
                            class="option__img option__img-selected-right country-flag"
                            :src="getFlag(option.code)"
                            :alt="option.name"
                          />
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">
                              {{ props.option.asciiname }}
                            </span>
                            <span class="option__small">
                              ({{ props.option.name }})
                            </span>
                          </div>
                          <img
                            class="option__img option__img-right country-flag"
                            :src="getFlag(props.option.code)"
                            :alt="props.option.name"
                          />
                        </template>
                        <template slot="caret" slot-scope="{ toggle }">
                          <div
                            @mousedown.prevent.stop="toggle"
                            class="toggle-hold"
                          >
                            <font-awesome-icon
                              :icon="['fa', 'chevron-down']"
                            ></font-awesome-icon>
                          </div>
                        </template>
                      </multiselect>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import Multiselect from 'vue-multiselect'

export default {
  name: `UserForm`,
  components: {
    VueTabs,
    VTab,
    Multiselect
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'user',
      resourceRoute: 'users',
      listPath: '/users',
      model: {
        name: null,
        email: null,
        active: true,
        password: null,
        confirm_password: null,
        roles: [],
        restriction_country_code: '',
        restricted_country: '1'
      },
      roles: [],
      data: {},
      meta: {},
      countries: [],
      icons: {
        opened: '<i class="fas fa-chevron-up"></i>',
        closed: '<i class="fas fa-chevron-down"></i>'
      },
      cityFieldDisabled: false
    }
  },
  async created() {
    this.fetchData()
    let { data } = await axios.get(this.$app.route(`users.get_roles`))
    this.roles = data
  },
  mounted() {
    this.initCountries()
    this.$title = this.$t('categories.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('categories.titles.unit')
  },
  methods: {
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    setCountry(selectedOption) {
      if (selectedOption) {
        this.model.restriction_country_code = selectedOption.code
        this.cityFieldDisabled = false
      }
    },
    removeCountry() {
      this.model.restriction_country_code = ''
      this.cityFieldDisabled = true
    },
    onModelChanged() {
      if (this.model.roles) {
        this.model.roles = this.model.roles.map(item => {
          return item.id
        })
      }
    },
    async initCountries() {
      let { data } = await axios.get(this.$app.route('countries.list'))
      this.countries = data
    },
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.country-flag {
  max-width: 30px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
}
</style>
<style scoped>
.country-flag {
  max-width: 30px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
}
</style>
