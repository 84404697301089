export default {
  path: 'posts',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'posts',
      component: require('@blog/js/components/PostList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'categories/',
      name: 'blog_categories',
      component: require('@blog/js/components/CategoryList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'posts_create',
      component: require('@blog/js/components/PostCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'categories/create',
      name: 'blog_categories_create',
      component: require('@blog/js/components/CategoryCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'posts_edit',
      component: require('@blog/js/components/PostEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: 'categories/:id/edit',
      name: 'blog_categories_edit',
      component: require('@blog/js/components/CategoryEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
