<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('subscriptions.titles.plans') }}
        </h3>
        <div class="card-options" v-if="this.$app.user.can('create plans')">
          <b-button @click="modalCreate" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('subscriptions.buttons.create-plan') }}
          </b-button>
        </div>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="plans.search"
        delete-route="plans.destroy"
        action-route="plans.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(name)="row">
            <router-link
              v-if="row.item.can_edit"
              :to="`/plans/${row.item.id}/edit`"
              v-text="row.value"
            ></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template v-slot:cell(is_active)="row">
            <span v-if="row.value" class="badge badge-success">
              {{ trans('core.label.yes') }}
            </span>
            <span v-else class="badge badge-danger">
              {{ trans('core.label.no') }}
            </span>
          </template>
          <template v-slot:cell(public)="row">
            <span v-if="row.value" class="badge badge-success">
              {{ trans('core.label.yes') }}
            </span>
            <span v-else class="badge badge-danger">
              {{ trans('core.label.no') }}
            </span>
          </template>
          <template v-slot:cell(type)="row">
            <span v-if="row.value == 'escort'">
              Escort
            </span>
            <span v-if="row.value == 'agency'">
              Agency
            </span>
            <span v-if="row.value == 'brothel'">
              Brothel
            </span>
            <span v-if="row.value == 'parlour'">
              Parlour
            </span>
            <span v-if="row.value == 'club'">
              Club
            </span>
            <span v-if="row.value == 'client'">
              Client (Member)
            </span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              v-if="row.item.can_edit"
              size="sm"
              variant="primary"
              :to="`/plans/${row.item.id}/edit`"
              v-b-tooltip.hover
              :title="$t('buttons.edit')"
              class="mr-1"
            >
              <i class="fas fa-edit"></i>
            </b-button>
            <b-dropdown
              size="sm"
              text=""
              class="adm-btn-dropdown action-btn-dropdown"
              v-if="row.item.deletable"
            >
              <b-dropdown-item
                @click.stop="onDelete(row.item.id)"
                v-if="row.item.deletable"
              >
                <span class="icon-holder icon-danger">
                  <i class="fas fa-trash-alt"></i>
                </span>
                <span class="label-holder label-delete">{{
                  trans('core.button.delete')
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import PlanCreateForm from './PlanCreateForm.vue'
export default {
  title: '',
  name: 'PlanList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'name',
          label: 'Plan name',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'comment',
          label: 'Comment (for inner use only)',
          sortable: false,
          class: 'data-name'
        },
        {
          key: 'public',
          label: 'Public',
          class: 'text-center',
          sortable: true
        },
        {
          key: 'is_active',
          label: 'Active',
          class: 'text-center',
          sortable: true
        },
        {
          key: 'type',
          label: 'Dedication for',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'sort_order',
          label: this.$t('validation.attributes.order'),
          class: 'text-right data-position',
          sortable: true
        },
        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('plans.actions.destroy'),
        enable: this.$t('plans.actions.enable'),
        disable: this.$t('plans.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('subscriptions.titles.plan-list')
  },
  methods: {
    modalCreate() {
      this.$modal.show(
        PlanCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: 700,
          transition: 'nice-modal-fade'
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ plan: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('plans.enabled', { plan: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    }
  }
}
</script>
