<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center" v-if="!data.verified">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Verification
                </h3>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="enabled"
                        v-model="model.verified"
                        description="Verified"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Information
                </h3>
                <div class="data-content-wrap">
                  <b-row>
                    <b-col md="12" class="new-data-wrap">
                      <b-row>
                        <b-col md="12" class="data-row-title">
                          Client provided information
                        </b-col>
                      </b-row>
                      <b-row v-if="model.message">
                        <b-col md="4" class="data-row-label">
                          Message
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          {{ model.message }}
                        </b-col>
                      </b-row>
                      <b-row v-if="model.review_object.type">
                        <b-col md="4" class="data-row-label">
                          Reviewable Type
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          {{ model.review_object.type }}
                        </b-col>
                      </b-row>
                      <b-row
                        v-if="
                          model.review_object.url || model.review_object.name
                        "
                      >
                        <b-col md="4" class="data-row-label">
                          Reviewable Name
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          <a
                            v-if="model.review_object.url"
                            :href="
                              `${$app.appUrlClient}${model.review_object.url}`
                            "
                            target="_blank"
                            class="inner-tr-link"
                          >
                            <span
                              v-text="model.review_object.name"
                              class="inner-tr-link"
                            ></span>
                          </a>
                          <span
                            v-else
                            v-text="model.review_object.name"
                            class="inner-tr-text"
                          ></span>
                        </b-col>
                      </b-row>
                      <b-row v-if="model.client_name">
                        <b-col md="4" class="data-row-label">
                          From
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          <a
                            v-if="model.client_iden"
                            :href="
                              `${$app.appUrlClient}/client/${model.client_iden}`
                            "
                            target="_blank"
                            class="inner-tr-link"
                          >
                            <span
                              v-text="model.client_name"
                              class="inner-tr-link"
                            ></span>
                          </a>
                          <span
                            v-else
                            v-text="model.client_name"
                            class="inner-tr-text"
                          ></span>
                        </b-col>
                      </b-row>
                      <b-row v-if="model.rating">
                        <b-col md="4" class="data-row-label">
                          Environment
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          <strong>{{ model.rating }}</strong> / 10
                        </b-col>
                      </b-row>
                      <b-row v-if="model.rating2">
                        <b-col md="4" class="data-row-label">
                          Experience
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          <strong>{{ model.rating2 }}</strong> / 10
                        </b-col>
                      </b-row>
                      <b-row v-if="model.rating3">
                        <b-col md="4" class="data-row-label">
                          Hygiene
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          <strong>{{ model.rating3 }}</strong> / 10
                        </b-col>
                      </b-row>
                      <b-row v-if="model.rating4">
                        <b-col md="4" class="data-row-label">
                          Comfort
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          <strong>{{ model.rating4 }}</strong> / 10
                        </b-col>
                      </b-row>
                      <b-row v-if="model.rating5">
                        <b-col md="4" class="data-row-label">
                          Discretion
                        </b-col>
                        <b-col md="8" class="data-row-data">
                          <strong>{{ model.rating5 }}</strong> / 10
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :iden="id"
          :delete-enabled="true"
          :route-delete="routeDelete"
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="false"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `ReviewEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'review',
      resourceRoute: 'reviews',
      listPath: '/reviews',
      routeDelete: 'reviews.destroy',
      model: {
        // Data
        message: '',
        review_object: {},
        verified: false,
        rating: '',
        rating2: '',
        rating3: '',
        rating4: '',
        rating5: '',
        client_iden: '',
        client_name: '',
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('reviews.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('reviews.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
