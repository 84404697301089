export default {
  path: 'agencies',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'agencies',
      component: require('@agencies/js/components/AgencyList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified',
      name: 'agencies',
      component: require('@agencies/js/components/AgencyUnverifiedList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'agencies_create',
      component: require('@agencies/js/components/AgencyCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'agencies_edit',
      component: require('@agencies/js/components/AgencyEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified/:id/edit',
      name: 'agencies_unverified_edit',
      component: require('@agencies/js/components/AgencyEditUnverifiedForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
