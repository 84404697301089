<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('reviews.titles.list') }}
        </h3>
        <div class="card-options" v-if="this.$app.user.can('create reviews')">
          <!--          <b-button @click="modalCreate" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('reviews.buttons.create') }}
          </b-button>-->
        </div>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="reviews.search"
        delete-route="reviews.destroy"
        action-route="reviews.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(review_object_type)="row">
            <span v-if="row.item.review_object.type">
              {{ row.item.review_object.type }}
            </span>
          </template>
          <template v-slot:cell(review_object_name)="row">
            <a
              v-if="row.item.review_object.url"
              :href="`${$app.appUrlClient}${row.item.review_object.url}`"
              target="_blank"
              class="dash-tr-link"
            >
              <span
                v-text="row.item.review_object.name"
                class="dash-tr-link"
              ></span>
            </a>
            <span
              v-else
              v-text="row.item.review_object.name"
              class="dash-tr-text"
            ></span>
          </template>
          <template v-slot:cell(client_name)="row">
            <a
              v-if="row.item.client_iden"
              :href="`${$app.appUrlClient}/client/${row.item.client_iden}`"
              target="_blank"
              v-text="row.value"
              class="dash-tr-link"
            >
              <span v-text="row.value" class="dash-tr-link"></span>
            </a>
            <span v-else v-text="row.value" class="dash-tr-text"></span>
          </template>
          <template v-slot:cell(verified)="row">
            <span v-if="row.value" class="badge badge-success">
              {{ trans('core.label.yes') }}
            </span>
            <span v-else class="badge badge-danger">
              {{ trans('core.label.no') }}
            </span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              v-if="row.item.can_edit"
              size="sm"
              variant="primary"
              :to="`/reviews/${row.item.id}/edit`"
              v-b-tooltip.hover
              :title="$t('buttons.edit')"
              class="mr-1"
            >
              <i class="fe fe-edit"></i>
            </b-button>
            <b-button
              v-if="row.item.can_delete"
              size="sm"
              variant="danger"
              v-b-tooltip.hover
              :title="$t('buttons.delete')"
              @click.stop="onDelete(row.item.id)"
            >
              <i class="fe fe-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
    <modals-container></modals-container>
  </div>
</template>

<script>
import axios from 'axios'
import ReviewCreateForm from './ReviewCreateForm.vue'
export default {
  title: '',
  name: 'ReviewList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'review_object_type',
          label: 'Reviewable Type',
          class: 'text-left'
        },
        {
          key: 'review_object_name',
          label: 'Reviewable Name',
          class: 'text-left'
        },
        {
          key: 'client_name',
          label: 'Client Name',
          class: 'text-left'
        },
        {
          key: 'verified',
          label: 'Verified',
          class: 'text-center'
        },
        {
          key: 'created_at',
          label: 'Date',
          class: 'text-right',
          sortable: true
        },
        /*        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },*/
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('reviews.actions.destroy'),
        enable: this.$t('reviews.actions.enable'),
        disable: this.$t('reviews.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('reviews.titles.list')
  },
  methods: {
    modalCreate() {
      this.$modal.show(
        ReviewCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ review: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('reviews.enabled', { review: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    }
  }
}
</script>
