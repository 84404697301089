export default {
  path: 'parlour/services',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'parlour_services',
      component: require('@parlour-services/js/components/ParlourServiceList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'parlour_services_create',
      component: require('@parlour-services/js/components/ParlourServiceCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'parlour_services_edit',
      component: require('@parlour-services/js/components/ParlourServiceEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
