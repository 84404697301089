<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card class="attribute-tabs dialog-tabs">
            <h3 class="card-title" slot="header">
              <template>
                {{ $t('geo.titles.city-create') }}
              </template>
            </h3>
            <vue-tabs
              active-tab-color="#363B64"
              active-text-color="white"
              type="pills"
              class="attribute-tabs"
              v-model="activeTab"
            >
              <v-tab title="Duomenys" icon="fas fa-pen">
                <div class="attribute-tab-content wrap-tab-content">
                  <!-- Country name -->
                  <div
                    role="group"
                    class="form-row form-group form-row-info mb-0 mt-0"
                  >
                    <label class="col-3 col-form-label">
                      {{ trans('geo.labels.country_name') }}:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <template v-if="data && data.name">
                        {{ data.country_name }}
                      </template>
                    </div>
                  </div>

                  <!-- Code -->
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      {{ trans('geo.labels.country_code') }}:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      {{ data.country_code }}
                    </div>
                  </div>

                  <!-- City Name -->
                  <b-form-group
                    :label="trans('geo.labels.city-name') + ' *'"
                    label-for="name"
                    label-cols-sm="3"
                    :feedback="feedback('name')"
                  >
                    <b-form-input
                      id="name"
                      name="name"
                      v-model="model.name"
                      :state="state('name')"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('name') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Population -->
                  <b-form-group
                    :label="trans('geo.labels.adjust-by-population') + ' *'"
                    label-for="name"
                    label-cols-sm="3"
                    :feedback="feedback('population')"
                  >
                    <b-form-input
                      id="population"
                      name="population"
                      type="number"
                      v-model="model.population"
                      :state="state('population')"
                      class="form-control-status"
                      min="0"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('population') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Latitude -->
                  <b-form-group
                    :label="trans('geo.labels.latitude')"
                    label-for="latitude"
                    label-cols-sm="3"
                    :feedback="feedback('latitude')"
                  >
                    <b-form-input
                      id="latitude"
                      name="latitude"
                      type="number"
                      step="0.000001"
                      v-model="model.latitude"
                      :state="state('latitude')"
                      class="form-control-status"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('population') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Latitude -->
                  <b-form-group
                    :label="trans('geo.labels.longitude')"
                    label-for="longitude"
                    label-cols-sm="3"
                    :feedback="feedback('longitude')"
                  >
                    <b-form-input
                      id="longitude"
                      name="longitude"
                      type="number"
                      step="0.000001"
                      v-model="model.longitude"
                      :state="state('longitude')"
                      class="form-control-status"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('longitude') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- City Time zone -->
                  <b-form-group
                    label="Time zone"
                    label-for="timezone"
                    label-cols-sm="3"
                    :feedback="feedback('timezone')"
                  >
                    <b-form-input
                      id="timezone"
                      name="timezone"
                      v-model="model.timezone"
                      :state="state('timezone')"
                    ></b-form-input>

                    <b-form-feedback>
                      {{ feedback('timezone') }}
                    </b-form-feedback>
                  </b-form-group>

                  <!-- Enabled -->
                  <div class="form-group">
                    <b-row>
                      <b-col lg="9" offset-lg="3">
                        <c-switch
                          name="enabled"
                          v-model="model.enabled"
                          :description="$t('core.label.enabled')"
                        ></c-switch>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- Manual enabled -->
                  <div class="form-group">
                    <b-row>
                      <b-col lg="9" offset-lg="3">
                        <c-switch
                          name="status_manual"
                          v-model="model.status_manual"
                          :description="$t('geo.labels.status-manual')"
                        ></c-switch>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </v-tab>

              <v-tab title="Url" icon="fas fa-link">
                <div class="attribute-tab-content  wrap-tab-content">
                  <b-row class="left-content-center">
                    <b-col xl="12" lg="12">
                      <b-card
                        :key="index"
                        v-for="(locale, index) in this.$app.locales"
                        class="group-lang group-lang-url"
                        v-if="locale.backend"
                      >
                        <!-- Divider -->
                        <b-form-group class="card-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ $t('labels.url') }} - {{ index }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <b-form-group
                          :label="$t('validation.attributes.url')"
                          :label-for="`routes.${index}.slug`"
                          label-cols-sm="3"
                          :feedback="feedback('routes.' + index + '.slug')"
                        >
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-title prepend-lang"
                              :class="[
                                locale.required ? 'prepend-lang-required' : ''
                              ]"
                            >
                              <b-input-group-text
                                data-toggle
                                v-b-tooltip.hover
                                :title="$t(locale.name)"
                              >
                                <span>{{ index }}</span>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              :id="`routes.${index}.slug`"
                              :name="`routes.${index}.slug`"
                              v-model="model.routes[`${index}`]['slug']"
                              :state="state('routes.' + index + '.slug')"
                            ></b-form-input>
                            <b-input-group-append
                              class="input-group-append-title"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fas fa-link"></i>
                              </b-input-group-text>
                            </b-input-group-append>
                            <b-form-feedback>
                              {{ feedback('routes.' + index + '.slug') }}
                            </b-form-feedback>
                          </b-input-group>
                        </b-form-group>

                        <b-form-group
                          :label="$t('validation.attributes.url-target')"
                          label-for="target"
                          :description="
                            $t('validation.attributes.url-target-info')
                          "
                          label-cols-sm="3"
                        >
                          <div
                            class="url-link"
                            v-if="
                              meta.routes &&
                                meta.routes[`${index}`] &&
                                meta.routes[`${index}`]['slug']
                            "
                          >
                            <a
                              :href="
                                $app.appUrlClient +
                                  '/' +
                                  meta.routes[`${index}`]['slug']
                              "
                              target="_blank"
                            >
                              {{ $app.appUrlClient }}/{{
                                meta.routes[`${index}`]['slug']
                              }}
                            </a>
                            <template
                              v-if="
                                meta.routes[`${index}`]['slug'] !==
                                  model.routes[`${index}`]['slug']
                              "
                            >
                              <span class="url-link-new">
                                <i class="fas fa-long-arrow-alt-right"></i>
                                {{ $app.appUrlClient }}/{{
                                  model.routes[`${index}`]['slug']
                                }}
                              </span>
                            </template>
                          </div>
                        </b-form-group>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </v-tab>

              <v-tab title="Seo" icon="fas fa-heading">
                <div class="attribute-tab-content  wrap-tab-content">
                  <b-row class="left-content-center">
                    <b-col xl="12" lg="12">
                      <b-card
                        :key="index"
                        v-for="(locale, index) in this.$app.locales"
                        v-if="locale.backend"
                        class="group-lang group-lang-seo"
                      >
                        <!-- Divider -->
                        <b-form-group class="card-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ $t('labels.seo') }} - {{ index }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <b-form-group
                          :label="$t('labels.meta.title')"
                          :label-for="`metadata.title.${index}`"
                          label-cols-sm="3"
                          :description="$t('labels.meta.title-note')"
                          :feedback="feedback('metadata.title.' + index)"
                        >
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-title prepend-lang"
                              :class="[
                                locale.required ? 'prepend-lang-required' : ''
                              ]"
                            >
                              <b-input-group-text
                                data-toggle
                                v-b-tooltip.hover
                                :title="$t(locale.name)"
                              >
                                <span>{{ index }}</span>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              :id="`metadata.title.${index}`"
                              :name="`metadata.title.${index}`"
                              v-model="model.metadata.title[`${index}`]"
                              :state="state('metadata.title.' + index)"
                            ></b-form-input>
                            <b-input-group-append
                              class="input-group-append-title"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fas fa-window-maximize"></i>
                              </b-input-group-text>
                            </b-input-group-append>
                            <b-form-feedback>
                              {{ feedback('metadata.title.' + index) }}
                            </b-form-feedback>
                          </b-input-group>
                        </b-form-group>
                        <b-form-group
                          :label="$t('labels.meta.description')"
                          :label-for="`metadata.description.${index}`"
                          label-cols-sm="3"
                        >
                          <b-form-textarea
                            :id="`metadata.description.${index}`"
                            :name="`metadata.description.${index}`"
                            :rows="3"
                            v-model="model.metadata.description[`${index}`]"
                          ></b-form-textarea>
                        </b-form-group>
                        <b-form-group
                          :label="$t('labels.meta.keywords')"
                          :label-for="`metadata.keywords.${index}`"
                          label-cols-sm="3"
                        >
                          <b-form-textarea
                            :id="`metadata.keywords.${index}`"
                            :name="`metadata.keywords.${index}`"
                            :rows="3"
                            v-model="model.metadata.keywords[`${index}`]"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </v-tab>
            </vue-tabs>
            <template v-if="modal">
              <action-footer-modal
                :page-title="$t('attributes.titles.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="false"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  title: '',
  name: 'CityCreateForm',
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    identifier: {
      type: [String, Boolean],
      default: false
    }
  },
  data() {
    return {
      activeTab: 0,
      isModal: this.modal,
      modelName: 'city',
      resourceRoute: 'cities',
      listPath: '/countries/' + this.$route.params.id + '/cities',
      model: {
        // Data
        name: '',
        timezone: '',
        population: 0,
        latitude: '',
        longitude: '',
        enabled: true,
        status_manual: true,
        identifier: this.identifier,
        // Routes
        routes: {
          lt: {
            slug: ''
          },
          en: {
            slug: ''
          }
        },
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('geo.titles.create')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    }
  }
}
</script>
