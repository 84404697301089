<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>

                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                    v-if="locale.backend"
                  >
                    <b-form-group
                      label="Amount of coins"
                      label-for="amount"
                      label-cols-sm="3"
                    >
                      <b-row>
                        <b-col md="5">
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-position"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fas fa-coins"></i>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              id="amount"
                              name="amount"
                              type="number"
                              step="1"
                              v-model="model.amount"
                            ></b-form-input>
                          </b-input-group>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </div>
                </div>

                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    label="Price"
                    label-for="price"
                    :label-cols="3"
                    :feedback="feedback('price')"
                  >
                    <b-row>
                      <b-col md="5">
                        <b-input-group>
                          <b-form-input
                            class="price"
                            type="number"
                            id="price"
                            name="price"
                            v-model="model.price"
                            :state="state('price')"
                            step="0.01"
                          ></b-form-input>
                          <b-input-group-append
                            class="input-group-append-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-euro-sign"></i>
                            </b-input-group-text>
                          </b-input-group-append>
                          <b-form-feedback>
                            {{ feedback('price') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    label="Old price"
                    label-for="price_old"
                    :label-cols="3"
                    :feedback="feedback('price_old')"
                  >
                    <b-row>
                      <b-col md="5">
                        <b-input-group>
                          <b-form-input
                            class="price_old"
                            type="number"
                            id="price_old"
                            name="price_old"
                            v-model="model.price_old"
                            :state="state('price_old')"
                            step="0.01"
                          ></b-form-input>
                          <b-input-group-append
                            class="input-group-append-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-euro-sign"></i>
                            </b-input-group-text>
                          </b-input-group-append>
                          <b-form-feedback>
                            {{ feedback('price_old') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                    v-if="locale.backend"
                  >
                    <b-form-group
                      label="Package label (tooltip)"
                      :label-for="`label.${index}`"
                      label-cols-sm="3"
                      :feedback="feedback('label.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`label.${index}`"
                          :name="`label.${index}`"
                          v-model="model.label[`${index}`]"
                          :state="state('label.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('label.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>

                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="is_active"
                        v-model="model.is_active"
                        :description="$t('validation.attributes.enabled')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `CoinEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'package',
      resourceRoute: 'packages',
      listPath: '/packages',
      model: {
        // Data
        amount: '',
        price: '',
        price_old: '',
        label: {},
        is_active: true,
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('coins.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('coins.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
