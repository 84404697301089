export default {
    "en": {
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "settings": {
            "actions": {
                "destroy": "Delete selected settings",
                "disable": "Disable selected settings",
                "enable": "Enable selected settings"
            },
            "alerts": {
                "bulk_destroyed": "Selected settings deleted",
                "bulk_disabled": "Selected settings disabled",
                "bulk_enabled": "Selected settings enabled",
                "created": "Settings created",
                "deleted": "Settings deleted",
                "disabled": "Settings disabled",
                "enabled": "Settings enabled",
                "updated": "Settings updated"
            },
            "label": {
                "body": "Email body",
                "confidential_text": "Use confidential text on email template",
                "footer_links": "Use footer links on email template",
                "signature": "Use signature on email template",
                "subject": "Email subject",
                "template": "Template",
                "title": "Email title",
                "use-confidential-text": "Include confidential text in email",
                "use-logo": "Include logo in email"
            },
            "permissions": {
                "category": "Settings",
                "create": {
                    "display_name": "Create settings"
                },
                "delete": {
                    "display_name": "Delete settings"
                },
                "edit": {
                    "display_name": "Edit settings"
                },
                "view": {
                    "display_name": "View settings"
                }
            },
            "section": {
                "buttons": "Buttons",
                "elements-to-use": "Elements to use",
                "email-template": "Email template",
                "icons": "Email icon",
                "settings": "Settings"
            },
            "sections": {
                "buttons": "Buttons",
                "elements-to-use": "Elements to use",
                "email-template": "Email template",
                "icons": "Email icon"
            },
            "sidebar": {
                "title": "Settings"
            },
            "titles": {
                "create": "Settings creation",
                "edit": "Settings edit",
                "list": "Settings",
                "unit": "Settings",
                "units": "Settings"
            }
        },
        "media": {
            "actions": {
                "destroy": "Delete selected media",
                "disable": "Disable selected media",
                "enable": "Enable selected media"
            },
            "alerts": {
                "bulk_destroyed": "Selected media deleted",
                "bulk_disabled": "Selected media disabled",
                "bulk_enabled": "Selected media enabled",
                "created": "Media created",
                "deleted": "Media deleted",
                "disabled": "Media disabled",
                "enabled": "Media enabled",
                "updated": "Media updated"
            },
            "buttons": {
                "create": "Create Media"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected media",
                "bulk_disable": "Error on disabling selected media",
                "bulk_enable": "Error on enabling selected media"
            },
            "permissions": {
                "category": "Media",
                "create": {
                    "display_name": "Create media"
                },
                "delete": {
                    "display_name": "Delete media"
                },
                "edit": {
                    "display_name": "Edit media"
                },
                "view": {
                    "display_name": "View media"
                }
            },
            "sidebar": {
                "title": "Media"
            },
            "titles": {
                "create": "Media creation",
                "edit": "Media edit",
                "list": "Media",
                "unit": "Media",
                "units": "Media"
            }
        },
        "fake-reports": {
            "actions": {
                "destroy": "Delete selected fakeReports",
                "disable": "Disable selected fakeReports",
                "enable": "Enable selected fakeReports"
            },
            "alerts": {
                "bulk_destroyed": "Selected fakeReports deleted",
                "bulk_disabled": "Selected fakeReports disabled",
                "bulk_enabled": "Selected fakeReports enabled",
                "created": "FakeReport created",
                "deleted": "FakeReport deleted",
                "disabled": "FakeReport disabled",
                "enabled": "FakeReport enabled",
                "updated": "FakeReport updated"
            },
            "buttons": {
                "create": "Create FakeReport"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected fakeReports",
                "bulk_disable": "Error on disabling selected fakeReports",
                "bulk_enable": "Error on enabling selected fakeReports"
            },
            "permissions": {
                "category": "FakeReports",
                "create": {
                    "display_name": "Create fake_reports"
                },
                "delete": {
                    "display_name": "Delete fake_reports"
                },
                "edit": {
                    "display_name": "Edit fake_reports"
                },
                "view": {
                    "display_name": "View fake_reports"
                }
            },
            "sidebar": {
                "section": "Reports",
                "title": "FakeReports"
            },
            "titles": {
                "create": "FakeReport creation",
                "edit": "FakeReport edit",
                "list": "FakeReports",
                "unit": "FakeReport",
                "units": "FakeReports"
            }
        },
        "coins": {
            "actions": {
                "destroy": "Delete selected coins",
                "disable": "Disable selected coins",
                "enable": "Enable selected coins"
            },
            "alerts": {
                "bulk_destroyed": "Selected coins deleted",
                "bulk_disabled": "Selected coins disabled",
                "bulk_enabled": "Selected coins enabled",
                "created": "Coin created",
                "deleted": "Coin deleted",
                "disabled": "Coin disabled",
                "enabled": "Coin enabled",
                "package-created": "Coin package created",
                "package-deleted": "Coin package deleted",
                "package-updated": "Coin package updated",
                "updated": "Coin updated"
            },
            "buttons": {
                "create": "Create Coin",
                "package-create": "Create coin package"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected coins",
                "bulk_disable": "Error on disabling selected coins",
                "bulk_enable": "Error on enabling selected coins"
            },
            "permissions": {
                "category": "Coins",
                "create": {
                    "display_name": "Create coins"
                },
                "delete": {
                    "display_name": "Delete coins"
                },
                "edit": {
                    "display_name": "Edit coins"
                },
                "view": {
                    "display_name": "View coins"
                }
            },
            "sidebar": {
                "coin-movement": "Coins movement",
                "coin-packages": "Coin packages",
                "movement-title": "Movement",
                "packages": "Packages",
                "title": "Coins"
            },
            "titles": {
                "create": "Coin creation",
                "edit": "Coin edit",
                "list": "Coins",
                "package-create": "Create coins package",
                "package-list": "Coin package list",
                "unit": "Coin",
                "units": "Coins"
            }
        },
        "users-buttons": {
            "users": {
                "create": "Create user"
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "lang-required": "The {Field} field is required in {Lang}-name ({LANG}-CODE) language.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "identifier": "Code",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "enabled": "Enabled",
                "disabled": "Disabled",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "url-target": "URL link",
                "url-target-info": "Remember. You can only access the URL link if the item is not disabled.",
                "url-description": "If you do not fill in the URL field, it will be automatically generated from the item name.",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "price": "Price",
                "page-title": "Page title"
            }
        },
        "users-labels": {
            "sidebar": {
                "root": "Access",
                "title": "Users"
            },
            "users": {
                "titles": {
                    "main": "User",
                    "index": "User list",
                    "create": "User creation",
                    "edit": "User modification"
                },
                "labels": {
                    "checkbox": "Check",
                    "restrictions": "Restrictions",
                    "by-country": "By country"
                },
                "actions": {
                    "destroy": "Delete selected users",
                    "enable": "Enable selected users",
                    "disable": "Disable selected users"
                }
            }
        },
        "parlours": {
            "sidebar": {
                "title": "Parlours"
            },
            "titles": {
                "unit": "Parlour",
                "units": "Parlours",
                "list": "Parlours",
                "create": "Parlour creation",
                "edit": "Parlour edit",
                "dashboard-parlours": "Parlours",
                "dashboard-latest-verifications": "Awaiting data check / verification",
                "unverified": "Unverified Parlours"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Parlour",
                "all-verifications": "All awaiting data check / verification"
            },
            "field": {
                "description": "Description",
                "email": "Email",
                "name": "Name",
                "public": "Public",
                "show_name": "Show name",
                "blocked": "Blocked"
            },
            "label": {
                "all-records": "Parlours total count",
                "dashboard-active": "Active",
                "dashboard-canceled": "Canceled",
                "dashboard-correct": "Parlour whose data is correct",
                "dashboard-draft": "Records in data draft",
                "dashboard-pending": "Awaiting for data check",
                "dashboard-revise": "Awaiting for data corrections",
                "dashboard-suspended": "Suspended / Blocked",
                "dashboard-verification": "Awaiting verification",
                "dashboard-verified": "Verified",
                "no-results-awaiting": "All records has been checked / verified"
            },
            "status": {
                "active": "Verified",
                "canceled": "Canceled",
                "check": "Check",
                "check-verification": "Check / Verification",
                "correct": "Data is correct",
                "draft": "Draft",
                "reviewed": "Viewed",
                "revise": "Revise",
                "suspended": "Suspended",
                "verification": "Pending"
            },
            "alerts": {
                "created": "Parlour created",
                "updated": "Parlour updated",
                "deleted": "Parlour deleted",
                "enabled": "Parlour enabled",
                "disabled": "Parlour disabled",
                "bulk_destroyed": "Selected parlours deleted",
                "bulk_enabled": "Selected parlours enabled",
                "bulk_disabled": "Selected parlours disabled"
            },
            "actions": {
                "destroy": "Delete selected parlours",
                "enable": "Enable selected parlours",
                "disable": "Disable selected parlours"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected parlours",
                "bulk_enable": "Error on enabling selected parlours",
                "bulk_disable": "Error on disabling selected parlours"
            },
            "permissions": {
                "category": "Parlours",
                "view": {
                    "display_name": "View parlours",
                    "description": ""
                },
                "create": {
                    "display_name": "Create parlours",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit parlours",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete parlours",
                    "description": ""
                }
            }
        },
        "agency-information": {
            "actions": {
                "destroy": "Delete selected agency Information",
                "disable": "Disable selected agency Information",
                "enable": "Enable selected agency Information"
            },
            "alerts": {
                "bulk_destroyed": "Selected agency Information deleted",
                "bulk_disabled": "Selected agency Information disabled",
                "bulk_enabled": "Selected agency Information enabled",
                "created": "Agency Information created",
                "deleted": "Agency Information deleted",
                "disabled": "Agency Information disabled",
                "enabled": "Agency Information enabled",
                "updated": "Agency Information updated"
            },
            "buttons": {
                "create": "Create Agency Information"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected agency yInformation",
                "bulk_disable": "Error on disabling selected agency Information",
                "bulk_enable": "Error on enabling selected agency Information"
            },
            "permissions": {
                "category": "Agency Information",
                "create": {
                    "display_name": "Create agency information"
                },
                "delete": {
                    "display_name": "Delete agency information"
                },
                "edit": {
                    "display_name": "Edit agency information"
                },
                "view": {
                    "display_name": "View agency information"
                }
            },
            "sidebar": {
                "title": "Agencies"
            },
            "titles": {
                "create": "Agency Information creation",
                "edit": "Agency Information edit",
                "list": "Agency Information",
                "unit": "Agency Information",
                "units": "Agency Information"
            }
        },
        "clients": {
            "actions": {
                "destroy": "Delete selected clients",
                "disable": "Disable selected clients",
                "enable": "Enable selected clients"
            },
            "alerts": {
                "bulk_destroyed": "Selected clients deleted",
                "bulk_disabled": "Selected clients disabled",
                "bulk_enabled": "Selected clients enabled",
                "created": "Client created",
                "deleted": "Client deleted",
                "disabled": "Client disabled",
                "enabled": "Client enabled",
                "updated": "Client updated"
            },
            "buttons": {
                "create": "Create Client"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected clients",
                "bulk_disable": "Error on disabling selected clients",
                "bulk_enable": "Error on enabling selected clients"
            },
            "permissions": {
                "category": "Clients",
                "create": {
                    "display_name": "Create clients"
                },
                "delete": {
                    "display_name": "Delete clients"
                },
                "edit": {
                    "display_name": "Edit clients"
                },
                "view": {
                    "display_name": "View clients"
                }
            },
            "sidebar": {
                "title": "Clients"
            },
            "titles": {
                "create": "Client creation",
                "edit": "Client edit",
                "list": "Clients",
                "unit": "Client",
                "units": "Clients"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "roles-labels": {
            "sidebar": {
                "title": "Roles"
            },
            "roles": {
                "titles": {
                    "main": "Role",
                    "index": "Role list",
                    "create": "Role creation",
                    "edit": "Role modification"
                }
            }
        },
        "subscriptions": {
            "actions": {
                "destroy": "Delete selected subscriptions",
                "disable": "Disable selected subscriptions",
                "enable": "Enable selected subscriptions"
            },
            "alerts": {
                "bulk_destroyed": "Selected subscriptions deleted",
                "bulk_disabled": "Selected subscriptions disabled",
                "bulk_enabled": "Selected subscriptions enabled",
                "created": "Subscription created",
                "created-feature": "Feature created",
                "created-plan": "Plan created",
                "deleted": "Subscription deleted",
                "deleted-feature": "Feature deleted",
                "deleted-plan": "Plan deleted",
                "disabled": "Subscription disabled",
                "enabled": "Subscription enabled",
                "updated": "Subscription updated",
                "updated-feature": "Feature updated",
                "updated-plan": "Plan updated"
            },
            "buttons": {
                "create": "Create Subscription",
                "create-feature": "Create Feature",
                "create-plan": "Create Plan"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected subscriptions",
                "bulk_disable": "Error on disabling selected subscriptions",
                "bulk_enable": "Error on enabling selected subscriptions"
            },
            "permissions": {
                "category": "Subscriptions",
                "create": {
                    "display_name": "Create subscriptions"
                },
                "delete": {
                    "display_name": "Delete subscriptions"
                },
                "edit": {
                    "display_name": "Edit subscriptions"
                },
                "view": {
                    "display_name": "View subscriptions"
                }
            },
            "sidebar": {
                "features": "Features",
                "plans": "Plans",
                "title": "Subscriptions"
            },
            "titles": {
                "create": "Subscription creation",
                "create-feature": "Feature creation",
                "create-plan": "Plan creation",
                "edit": "Subscription edit",
                "edit-feature": "Feature edit",
                "edit-plan": "Plan edit",
                "feature-list": "Features",
                "features": "Features",
                "list": "Subscriptions",
                "plan-list": "Plans",
                "plans": "Plans",
                "unit": "Subscription",
                "unit-feature": "Feature",
                "unit-plan": "Plan",
                "units": "Subscriptions"
            }
        },
        "templates": {
            "sidebar": {
                "title": "Templates"
            },
            "titles": {
                "unit": "Template",
                "units": "Templates",
                "list": "Templates",
                "create": "Template creation",
                "edit": "Template edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Template"
            },
            "alerts": {
                "created": "Template created",
                "updated": "Template updated",
                "deleted": "Template deleted",
                "enabled": "Template enabled",
                "disabled": "Template disabled",
                "bulk_destroyed": "Selected templates deleted",
                "bulk_enabled": "Selected templates enabled",
                "bulk_disabled": "Selected templates disabled"
            },
            "actions": {
                "destroy": "Delete selected templates",
                "enable": "Enable selected templates",
                "disable": "Disable selected templates"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected templates",
                "bulk_enable": "Error on enabling selected templates",
                "bulk_disable": "Error on disabling selected templates"
            },
            "permissions": {
                "category": "Templates",
                "view": {
                    "display_name": "View templates",
                    "description": ""
                },
                "create": {
                    "display_name": "Create templates",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit templates",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete templates",
                    "description": ""
                }
            }
        },
        "parlour-information": {
            "actions": {
                "destroy": "Delete selected parlourInformation",
                "disable": "Disable selected parlourInformation",
                "enable": "Enable selected parlourInformation"
            },
            "alerts": {
                "bulk_destroyed": "Selected parlour Information deleted",
                "bulk_disabled": "Selected parlour Information disabled",
                "bulk_enabled": "Selected parlour Information enabled",
                "created": "Parlour Information created",
                "deleted": "Parlour Information deleted",
                "disabled": "Parlour Information disabled",
                "enabled": "Parlour Information enabled",
                "updated": "Parlour Information updated"
            },
            "buttons": {
                "create": "Create Parlour  Information"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected parlourInformation",
                "bulk_disable": "Error on disabling selected parlourInformation",
                "bulk_enable": "Error on enabling selected parlourInformation"
            },
            "permissions": {
                "category": "Parlour Information",
                "create": {
                    "display_name": "Create parlour information"
                },
                "delete": {
                    "display_name": "Delete parlour information"
                },
                "edit": {
                    "display_name": "Edit parlour information"
                },
                "view": {
                    "display_name": "View parlour information"
                }
            },
            "sidebar": {
                "title": "Parlours"
            },
            "titles": {
                "create": "Parlour Information creation",
                "edit": "Parlour Information edit",
                "list": "Parlour Information",
                "unit": "Parlour Information",
                "units": "Parlour Information"
            }
        },
        "roles-buttons": {
            "roles": {
                "create": "Create role"
            }
        },
        "reviews-escorts": {
            "actions": {
                "destroy": "Delete selected review Escorts",
                "disable": "Disable selected reviewsEscorts",
                "enable": "Enable selected reviewsEscorts"
            },
            "alerts": {
                "bulk_destroyed": "Selected Escort Review deleted",
                "bulk_disabled": "Selected Escort Review disabled",
                "bulk_enabled": "Selected Escort Review enabled",
                "created": "Escort Review created",
                "deleted": "Escort Review deleted",
                "disabled": "Escort Review disabled",
                "enabled": "Escort Review enabled",
                "updated": "Escort Review updated"
            },
            "buttons": {
                "create": "Create Escort Review"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected Escort Review",
                "bulk_disable": "Error on disabling selected Escort Review",
                "bulk_enable": "Error on enabling selected Escort Review"
            },
            "permissions": {
                "category": "Escort Reviews",
                "create": {
                    "display_name": "Create escort reviews"
                },
                "delete": {
                    "display_name": "Delete escort reviews"
                },
                "edit": {
                    "display_name": "Edit escort reviews"
                },
                "view": {
                    "display_name": "View escort reviews"
                }
            },
            "sidebar": {
                "title": "Escort Reviews"
            },
            "titles": {
                "create": "Escort Review creation",
                "edit": "Escort Review edit",
                "list": "Escort Reviews",
                "unit": "Escort Review",
                "units": "Escort Reviews"
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "agencies": {
            "sidebar": {
                "title": "Agencies"
            },
            "titles": {
                "unit": "Agency",
                "units": "Agencies",
                "list": "Agencies",
                "create": "Agency creation",
                "edit": "Agency edit",
                "dashboard-agencies": "Agencies",
                "dashboard-latest-verifications": "Awaiting data check / verification",
                "unverified": "Unverified Agencies"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Agency",
                "all-verifications": "All awaiting data check / verification"
            },
            "field": {
                "description": "Description",
                "email": "Email",
                "name": "Name",
                "public": "Public",
                "show_name": "Show name",
                "blocked": "Blocked"
            },
            "label": {
                "all-records": "Agencies total count",
                "dashboard-active": "Active",
                "dashboard-canceled": "Canceled",
                "dashboard-correct": "Agency whose data is correct",
                "dashboard-draft": "Records in data draft",
                "dashboard-pending": "Awaiting for data check",
                "dashboard-revise": "Awaiting for data corrections",
                "dashboard-suspended": "Suspended / Blocked",
                "dashboard-verification": "Awaiting verification",
                "dashboard-verified": "Verified",
                "no-results-awaiting": "All records has been checked / verified"
            },
            "alerts": {
                "created": "Agency created",
                "updated": "Agency updated",
                "deleted": "Agency deleted",
                "enabled": "Agency enabled",
                "disabled": "Agency disabled",
                "bulk_destroyed": "Selected agencies deleted",
                "bulk_enabled": "Selected agencies enabled",
                "bulk_disabled": "Selected agencies disabled"
            },
            "actions": {
                "destroy": "Delete selected agencies",
                "enable": "Enable selected agencies",
                "disable": "Disable selected agencies"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected agencies",
                "bulk_enable": "Error on enabling selected agencies",
                "bulk_disable": "Error on disabling selected agencies"
            },
            "status": {
                "active": "Verified",
                "canceled": "Canceled",
                "check": "Check",
                "check-verification": "Check / Verification",
                "correct": "Data is correct",
                "draft": "Draft",
                "reviewed": "Viewed",
                "revise": "Revise",
                "suspended": "Suspended",
                "verification": "Pending"
            },
            "permissions": {
                "category": "Agencies",
                "view": {
                    "display_name": "View agencies",
                    "description": ""
                },
                "create": {
                    "display_name": "Create agencies",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit agencies",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete agencies",
                    "description": ""
                }
            }
        },
        "core": {
            "alert": {
                "invalid_action": "Invalid action"
            },
            "button": {
                "back": "Back",
                "cancel": "Cancel",
                "create": "Create",
                "delete": "Delete",
                "edit": "Edit",
                "info": "Information",
                "login-as": "Login as user",
                "message": "Message",
                "preview": "Preview",
                "save": "Save",
                "save-and-close": "Save and close"
            },
            "datatable": {
                "entries_per_page": "entries per page",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries",
                "no_matched_results": "No matched results available",
                "no_results": "No results available",
                "search": "Search",
                "show_per_page": "Show"
            },
            "label": {
                "actions": "Actions",
                "active": "Active",
                "enabled": "Enabled",
                "inactive": "Inactive",
                "name": "Name",
                "no": "No",
                "off": "Off",
                "on": "On",
                "yes": "Yes"
            },
            "section": {
                "main": "Main information"
            },
            "tab": {
                "main": "Main information"
            }
        },
        "geo": {
            "actions": {
                "destroy": "Delete selected countries",
                "disable": "Disable selected countries",
                "enable": "Enable selected countries"
            },
            "alerts": {
                "city-country-disabled": "City disabled",
                "city-country-enabled": "City enabled",
                "city-created": "City created",
                "city-deleted": "City deleted",
                "city-disabled": "City disabled",
                "city-enabled": "City enabled",
                "city-updated": "City updated",
                "country-created": "Country created",
                "country-deleted": "Country deleted",
                "country-disabled": "Country disabled",
                "country-enabled": "Country enabled",
                "country-updated": "Country updated",
                "geo-disabled": "Geo record removed from city list",
                "geo-enabled": "Geo record pushed to city list",
                "geo-not-reviewed": "Geo record marked as not reviewed",
                "geo-reviewed": "Geo record reviewed"
            },
            "buttons": {
                "adjust": "Cities of country adjustment",
                "cities": "Cities",
                "create": "Create Country",
                "create-city": "Create City",
                "geo-data": "Geo data",
                "show-on-map": "Show on map",
                "states": "Subdivisions"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected countries",
                "bulk_disable": "Error on disabling selected countries",
                "bulk_enable": "Error on enabling selected countries"
            },
            "labels": {
                "adjust-action": "Action",
                "adjust-by-population": "Population",
                "adjust-mode": "Mode",
                "adjust-override": "Override manually enabled / disabled cities of country ( Note: manual enables will be switched off, so use only when necessary !!! )",
                "adm-code": "ADM",
                "area": "Area",
                "capital": "Capital",
                "city-name": "City name",
                "city-slug": "City slug (Url part)",
                "code": "Code",
                "continent": "Continent",
                "country-code": "Code",
                "country-name": "Country",
                "country-slug": "Country slug (Url part)",
                "country_cities": "Cities",
                "country_code": "Country code",
                "country_flag": "Flag",
                "country_name": "Country",
                "country_states": "Subdivisions",
                "geo-alternames": "Alternative names",
                "geo-depth": "Depth",
                "geo-id": "Geo ID",
                "geo-level": "Level",
                "geo-parent-id": "Parent ID",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "name": "Name",
                "name_original": "Native name",
                "population": "Population",
                "reviewed": "Reviewed",
                "status-manual": "Can't override enabled / disabled by cities of country adjustment",
                "status_manual": "Can't override status",
                "timezone": "Timezone",
                "tld": "Top level domain"
            },
            "permissions": {
                "category": "Countries",
                "create": {
                    "display_name": "Create country"
                },
                "delete": {
                    "display_name": "Delete country"
                },
                "edit": {
                    "display_name": "Edit country"
                },
                "view": {
                    "display_name": "View countries"
                }
            },
            "sections": {
                "adjust": "Disable / Enable cities of country by population"
            },
            "sidebar": {
                "countries": "Countries",
                "section": "Geo",
                "title": "Geo"
            },
            "titles": {
                "city-create": "City create",
                "city-edit": "City edit",
                "city_list": "Cities",
                "country-adjust": "Cities of country adjustment",
                "create": "Country creation",
                "edit": "Country edit",
                "list": "Countries",
                "unit": "Country",
                "units": "Countries"
            },
            "tooltips": {
                "cities_active_count": "Active cities",
                "cities_count": "Total cities",
                "cities_inactive_count": "Inactive cities",
                "cities_view": "City list of country",
                "geo_active_count": "Active geo records",
                "geo_count": "Geo records total",
                "geo_inactive_count": "Inactive geo records",
                "states_active_count": "Active states",
                "states_count": "Total states",
                "states_inactive_count": "Inactive states",
                "states_view": "Subdivision list of country"
            }
        },
        "parlour-ethnicity": {
            "actions": {
                "destroy": "Delete selected parlour Ethnicity",
                "disable": "Disable selected parlour Ethnicity",
                "enable": "Enable selected parlour Ethnicity"
            },
            "alerts": {
                "bulk_destroyed": "Selected parlour Ethnicity deleted",
                "bulk_disabled": "Selected parlour Ethnicity disabled",
                "bulk_enabled": "Selected parlour Ethnicity enabled",
                "created": "Parlour Ethnicity created",
                "deleted": "Parlour Ethnicity deleted",
                "disabled": "Parlour Ethnicity disabled",
                "enabled": "Parlour Ethnicity enabled",
                "updated": "Parlour Ethnicity updated"
            },
            "buttons": {
                "create": "Create Parlour Ethnicity"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected parlour Ethnicity",
                "bulk_disable": "Error on disabling selected parlour Ethnicity",
                "bulk_enable": "Error on enabling selected parlour Ethnicity"
            },
            "permissions": {
                "category": "ParlourEthnicity",
                "create": {
                    "display_name": "Create parlour ethnicity"
                },
                "delete": {
                    "display_name": "Delete parlour ethnicity"
                },
                "edit": {
                    "display_name": "Edit parlour ethnicity"
                },
                "view": {
                    "display_name": "View parlour ethnicity"
                }
            },
            "sidebar": {
                "title": "Parlour Ethnicity"
            },
            "titles": {
                "create": "Parlour Ethnicity creation",
                "edit": "Parlour Ethnicity edit",
                "list": "Parlour Ethnicity",
                "unit": "Parlour Ethnicity",
                "units": "Parlour Ethnicity"
            }
        },
        "orders": {
            "actions": {
                "destroy": "Delete selected orders",
                "disable": "Disable selected orders",
                "enable": "Enable selected orders"
            },
            "alerts": {
                "bulk_destroyed": "Selected orders deleted",
                "bulk_disabled": "Selected orders disabled",
                "bulk_enabled": "Selected orders enabled",
                "created": "Order created",
                "deleted": "Order deleted",
                "disabled": "Order disabled",
                "enabled": "Order enabled",
                "updated": "Order updated"
            },
            "buttons": {
                "create": "Create Order"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected orders",
                "bulk_disable": "Error on disabling selected orders",
                "bulk_enable": "Error on enabling selected orders"
            },
            "permissions": {
                "category": "Orders",
                "create": {
                    "display_name": "Create orders"
                },
                "delete": {
                    "display_name": "Delete orders"
                },
                "edit": {
                    "display_name": "Edit orders"
                },
                "view": {
                    "display_name": "View orders"
                }
            },
            "sidebar": {
                "title": "Orders",
                "title-payments": "Payments"
            },
            "titles": {
                "create": "Order creation",
                "edit": "Order edit",
                "list": "Orders",
                "unit": "Order",
                "units": "Orders"
            }
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "save-continue": "Save and Continue Edit",
            "save-and-close": "Save and close",
            "save-and-continue": "Save and continue filling data",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply"
        },
        "agency-services": {
            "actions": {
                "destroy": "Delete selected agency Services",
                "disable": "Disable selected agency Services",
                "enable": "Enable selected agency Services"
            },
            "alerts": {
                "bulk_destroyed": "Selected agency Services deleted",
                "bulk_disabled": "Selected agency Services disabled",
                "bulk_enabled": "Selected agency Services enabled",
                "created": "Agency Service created",
                "deleted": "Agency Service deleted",
                "disabled": "Agency Service disabled",
                "enabled": "Agency Service enabled",
                "updated": "Agency Service updated"
            },
            "buttons": {
                "create": "Create Agency Service"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected agency Services",
                "bulk_disable": "Error on disabling selected agency Services",
                "bulk_enable": "Error on enabling selected agency Services"
            },
            "permissions": {
                "category": "Agency Services",
                "create": {
                    "display_name": "Create agency services"
                },
                "delete": {
                    "display_name": "Delete agency services"
                },
                "edit": {
                    "display_name": "Edit agency services"
                },
                "view": {
                    "display_name": "View agency services"
                }
            },
            "sidebar": {
                "title": "Agency Services"
            },
            "titles": {
                "create": "Agency Service creation",
                "edit": "Agency Service edit",
                "list": "Agency Services",
                "unit": "Agency Service",
                "units": "Agency Services"
            }
        },
        "club-services": {
            "actions": {
                "destroy": "Delete selected club Services",
                "disable": "Disable selected club Services",
                "enable": "Enable selected club Services"
            },
            "alerts": {
                "bulk_destroyed": "Selected club Services deleted",
                "bulk_disabled": "Selected club Services disabled",
                "bulk_enabled": "Selected club Services enabled",
                "created": "Club Service created",
                "deleted": "Club Service deleted",
                "disabled": "Club Service disabled",
                "enabled": "Club Service enabled",
                "updated": "Club Service updated"
            },
            "buttons": {
                "create": "Create Club Service"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected club Services",
                "bulk_disable": "Error on disabling selected club Services",
                "bulk_enable": "Error on enabling selected club Services"
            },
            "permissions": {
                "category": "Club Services",
                "create": {
                    "display_name": "Create club services"
                },
                "delete": {
                    "display_name": "Delete club services"
                },
                "edit": {
                    "display_name": "Edit club services"
                },
                "view": {
                    "display_name": "View club services"
                }
            },
            "sidebar": {
                "title": "Club Services"
            },
            "titles": {
                "create": "Club Service creation",
                "edit": "Club Service edit",
                "list": "Club Services",
                "unit": "Club Service",
                "units": "Club Services"
            }
        },
        "reviews": {
            "actions": {
                "destroy": "Delete selected reviews",
                "disable": "Disable selected reviews",
                "enable": "Enable selected reviews"
            },
            "alerts": {
                "bulk_destroyed": "Selected reviews deleted",
                "bulk_disabled": "Selected reviews disabled",
                "bulk_enabled": "Selected reviews enabled",
                "created": "Review created",
                "deleted": "Review deleted",
                "disabled": "Review disabled",
                "enabled": "Review enabled",
                "updated": "Review updated"
            },
            "buttons": {
                "create": "Create Review"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected reviews",
                "bulk_disable": "Error on disabling selected reviews",
                "bulk_enable": "Error on enabling selected reviews"
            },
            "permissions": {
                "category": "Reviews",
                "create": {
                    "display_name": "Create reviews"
                },
                "delete": {
                    "display_name": "Delete reviews"
                },
                "edit": {
                    "display_name": "Edit reviews"
                },
                "view": {
                    "display_name": "View reviews"
                }
            },
            "sidebar": {
                "root": "Reviews / Reports",
                "section": "Reviews",
                "sections": "Reviews",
                "title": "Reviews"
            },
            "titles": {
                "create": "Review creation",
                "edit": "Review edit",
                "list": "Reviews",
                "unit": "Review",
                "units": "Reviews"
            }
        },
        "translations": {
            "action": {
                "destroy": "Delete selected translations",
                "disable": "Disable selected translations",
                "enable": "Enable selected translations"
            },
            "alert": {
                "bulk_destroyed": "Selected translations deleted",
                "bulk_disabled": "Selected translations disabled",
                "bulk_enabled": "Selected translations enabled",
                "created": "Translation created",
                "deleted": "Translation deleted",
                "disabled": "Translation disabled",
                "enabled": "Translation enabled",
                "import": "Translations imported",
                "publish": "Translations published",
                "sync": "Translations synced",
                "updated": "Translation updated"
            },
            "button": {
                "create": "Create Translation",
                "import": "Import Translations",
                "publish": "Publish Translations",
                "sync-center": "Sync Backend Translations",
                "sync-site": "Sync Frontend Translations"
            },
            "exception": {
                "bulk_destroy": "Error on deleting selected translations",
                "bulk_disable": "Error on disabling selected translations",
                "bulk_enable": "Error on enabling selected translations"
            },
            "filter": {
                "area": "Area",
                "groups": "Groups",
                "languages": "Languages",
                "modules": "Modules",
                "status": "Status"
            },
            "label": {
                "area": "Area",
                "backend": "Backend",
                "frontend": "Frontend",
                "group": "Group",
                "key": "Key",
                "locale": "Locale",
                "module": "Module",
                "original": "Origin",
                "original_translation": "Original translation",
                "status": "Status",
                "value": "Translation"
            },
            "message": {
                "import_progress": "Please wait, translations import in progress ...",
                "publish_progress": "Please wait, translations publish in progress ...",
                "sync_progress": "Please wait, translations sync in progress ..."
            },
            "permission": {
                "category": "Translations",
                "create": {
                    "display_name": "Create translation"
                },
                "delete": {
                    "display_name": "Delete translation"
                },
                "edit": {
                    "display_name": "Edit translation"
                },
                "view": {
                    "display_name": "View translations"
                }
            },
            "sidebar": {
                "section": "Translations",
                "title": "Translations"
            },
            "title": {
                "create": "Translation creation",
                "edit": "Translation edit",
                "edit-form": "Origin translation edit",
                "filters": "Filters",
                "import": "Translations import",
                "list": "Translations",
                "publish": "Translations publish",
                "status": "Status",
                "sync": "Translations sync",
                "unit": "Translation",
                "units": "Translations"
            },
            "tooltip": {
                "status_missing": "Status - Missing translation",
                "status_ok": "Status - Translated",
                "status_waiting": "Status - Waiting to be published"
            }
        },
        "black-lists": {
            "actions": {
                "destroy": "Delete selected blackLists",
                "disable": "Disable selected blackLists",
                "enable": "Enable selected blackLists"
            },
            "alerts": {
                "bulk_destroyed": "Selected blackLists deleted",
                "bulk_disabled": "Selected blackLists disabled",
                "bulk_enabled": "Selected blackLists enabled",
                "created": "BlackList created",
                "deleted": "BlackList deleted",
                "disabled": "BlackList disabled",
                "enabled": "BlackList enabled",
                "updated": "BlackList updated"
            },
            "buttons": {
                "create": "Create BlackList"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected blackLists",
                "bulk_disable": "Error on disabling selected blackLists",
                "bulk_enable": "Error on enabling selected blackLists"
            },
            "permissions": {
                "category": "BlackLists",
                "create": {
                    "display_name": "Create black_lists"
                },
                "delete": {
                    "display_name": "Delete black_lists"
                },
                "edit": {
                    "display_name": "Edit black_lists"
                },
                "view": {
                    "display_name": "View black_lists"
                }
            },
            "sidebar": {
                "title": "BlackLists"
            },
            "titles": {
                "create": "BlackList creation",
                "edit": "BlackList edit",
                "list": "BlackLists",
                "unit": "BlackList",
                "units": "BlackLists"
            }
        },
        "roles-permissions": {
            "category": "Roles",
            "view": {
                "display_name": "View roles",
                "description": ""
            },
            "create": {
                "display_name": "Create role",
                "description": ""
            },
            "edit": {
                "display_name": "Edit role",
                "description": ""
            },
            "delete": {
                "display_name": "Delete role",
                "description": ""
            }
        },
        "pages": {
            "actions": {
                "destroy": "Delete selected pages",
                "disable": "Disable selected pages",
                "enable": "Enable selected pages"
            },
            "alerts": {
                "bulk_destroyed": "Selected pages deleted",
                "bulk_disabled": "Selected pages disabled",
                "bulk_enabled": "Selected pages enabled",
                "created": "Page created",
                "deleted": "Page deleted",
                "disabled": "Page disabled",
                "enabled": "Page enabled",
                "footer-disabled": "Page disabled on footer",
                "footer-enabled": "Page enabled on footer",
                "header-disabled": "Page disabled on header",
                "header-enabled": "Page enabled on header",
                "updated": "Page updated"
            },
            "buttons": {
                "create": "Create Page"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected pages",
                "bulk_disable": "Error on disabling selected pages",
                "bulk_enable": "Error on enabling selected pages"
            },
            "labels": {
                "content_html": "Content html",
                "footer": "Show on footer",
                "header": "Show on header",
                "menu_label": "Menu link label",
                "menu_label_optional": "Menu link label (optional)"
            },
            "permissions": {
                "category": "Pages",
                "create": {
                    "display_name": "Create pages"
                },
                "delete": {
                    "display_name": "Delete pages"
                },
                "edit": {
                    "display_name": "Edit pages"
                },
                "view": {
                    "display_name": "View pages"
                }
            },
            "sidebar": {
                "root": "Content",
                "section": "Content management",
                "title": "Pages"
            },
            "titles": {
                "create": "Page creation",
                "edit": "Page edit",
                "list": "Pages",
                "unit": "Page",
                "units": "Pages"
            }
        },
        "club-types": {
            "actions": {
                "destroy": "Delete selected club Types",
                "disable": "Disable selected club Types",
                "enable": "Enable selected club Types"
            },
            "alerts": {
                "bulk_destroyed": "Selected club Types deleted",
                "bulk_disabled": "Selected club Types disabled",
                "bulk_enabled": "Selected club Types enabled",
                "created": "Club Type created",
                "deleted": "Club Type deleted",
                "disabled": "Club Type disabled",
                "enabled": "Club Type enabled",
                "updated": "Club Type updated"
            },
            "buttons": {
                "create": "Create Club Type"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected club Types",
                "bulk_disable": "Error on disabling selected club Types",
                "bulk_enable": "Error on enabling selected club Types"
            },
            "permissions": {
                "category": "Club Types",
                "create": {
                    "display_name": "Create club types"
                },
                "delete": {
                    "display_name": "Delete club types"
                },
                "edit": {
                    "display_name": "Edit club types"
                },
                "view": {
                    "display_name": "View club types"
                }
            },
            "sidebar": {
                "title": "Club Types"
            },
            "titles": {
                "create": "Club Type creation",
                "edit": "Club Type edit",
                "list": "Club Types",
                "unit": "Club Type",
                "units": "Club Types"
            }
        },
        "brothel-services": {
            "actions": {
                "destroy": "Delete selected brothel Services",
                "disable": "Disable selected brothel Services",
                "enable": "Enable selected brothel Services"
            },
            "alerts": {
                "bulk_destroyed": "Selected brothel Services deleted",
                "bulk_disabled": "Selected brothel Services disabled",
                "bulk_enabled": "Selected brothel Services enabled",
                "created": "Brothel Service created",
                "deleted": "Brothel Service deleted",
                "disabled": "Brothel Service disabled",
                "enabled": "Brothel Service enabled",
                "updated": "Brothel Service updated"
            },
            "buttons": {
                "create": "Create Brothel Service"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected brothel Services",
                "bulk_disable": "Error on disabling selected brothel Services",
                "bulk_enable": "Error on enabling selected brothel Services"
            },
            "permissions": {
                "category": "Brothel Services",
                "create": {
                    "display_name": "Create brothel services"
                },
                "delete": {
                    "display_name": "Delete brothel services"
                },
                "edit": {
                    "display_name": "Edit brothel services"
                },
                "view": {
                    "display_name": "View brothel services"
                }
            },
            "sidebar": {
                "title": "Brothel Services"
            },
            "titles": {
                "create": "Brothel Service creation",
                "edit": "Brothel Service edit",
                "list": "Brothel Services",
                "unit": "Brothel Service",
                "units": "Brothel Services"
            }
        },
        "banners": {
            "actions": {
                "destroy": "Delete selected banners",
                "disable": "Disable selected banners",
                "enable": "Enable selected banners"
            },
            "alerts": {
                "bulk_destroyed": "Selected banners deleted",
                "bulk_disabled": "Selected banners disabled",
                "bulk_enabled": "Selected banners enabled",
                "created": "Banner created",
                "deleted": "Banner deleted",
                "disabled": "Banner disabled",
                "enabled": "Banner enabled",
                "updated": "Banner updated"
            },
            "buttons": {
                "create": "Create Banner"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected banners",
                "bulk_disable": "Error on disabling selected banners",
                "bulk_enable": "Error on enabling selected banners"
            },
            "field": {
                "Views": "Views",
                "clicks": "Clicks",
                "image": "Banner image",
                "name": "Site Name",
                "show_until": "Shown Until",
                "shown_until": "Shown Until",
                "size": "Size",
                "type": "Position",
                "url": "Banner Url",
                "views": "Views",
                "visible": "Visible"
            },
            "permissions": {
                "category": "Banners",
                "create": {
                    "display_name": "Create banners"
                },
                "delete": {
                    "display_name": "Delete banners"
                },
                "edit": {
                    "display_name": "Edit banners"
                },
                "view": {
                    "display_name": "View banners"
                }
            },
            "sidebar": {
                "title": "Banners"
            },
            "titles": {
                "create": "Banner creation",
                "edit": "Banner edit",
                "list": "Banners",
                "unit": "Banner",
                "units": "Banners"
            }
        },
        "users-permissions": {
            "category": "Users",
            "category_backend": "Backend",
            "backend": {
                "display_name": "Allow access backend",
                "description": ""
            },
            "view": {
                "display_name": "View users",
                "description": ""
            },
            "create": {
                "display_name": "Create user",
                "description": ""
            },
            "edit": {
                "display_name": "Edit user",
                "description": ""
            },
            "delete": {
                "display_name": "Delete user",
                "description": ""
            }
        },
        "notifications": {
            "actions": {
                "destroy": "Delete selected notifications",
                "disable": "Disable selected notifications",
                "enable": "Enable selected notifications"
            },
            "alerts": {
                "bulk_destroyed": "Selected notifications deleted",
                "bulk_disabled": "Selected notifications disabled",
                "bulk_enabled": "Selected notifications enabled",
                "created": "Notification created",
                "deleted": "Notification deleted",
                "disabled": "Notification disabled",
                "enabled": "Notification enabled",
                "updated": "Notification updated"
            },
            "buttons": {
                "create": "Create Notification"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected notifications",
                "bulk_disable": "Error on disabling selected notifications",
                "bulk_enable": "Error on enabling selected notifications"
            },
            "permissions": {
                "category": "Notifications",
                "create": {
                    "display_name": "Create notifications"
                },
                "delete": {
                    "display_name": "Delete notifications"
                },
                "edit": {
                    "display_name": "Edit notifications"
                },
                "view": {
                    "display_name": "View notifications"
                }
            },
            "sidebar": {
                "title": "Notifications"
            },
            "titles": {
                "create": "Notification creation",
                "edit": "Notification edit",
                "list": "Notifications",
                "unit": "Notification",
                "units": "Notifications"
            }
        },
        "blocks": {
            "sidebar": {
                "title": "Blocks"
            },
            "titles": {
                "unit": "Block",
                "units": "Blocks",
                "list": "Blocks",
                "create": "Block creation",
                "edit": "Block edit"
            },
            "labels": {
                "comment": "Comment",
                "content_html": "Content Html",
                "btn_label": "Button label",
                "btn_url": "Button link",
                "btn_class": "Button extra css class",
                "btn_target_blank": "Open button link in a new window"
            },
            "notes": {
                "comment": "For internal use only"
            },
            "tabs": {
                "buttons": "Buttons"
            },
            "sections": {
                "buttons": "Button",
                "buttons_settings": "Button settings"
            },
            "buttons": {
                "create": "Create Block"
            },
            "alerts": {
                "created": "Block created",
                "updated": "Block updated",
                "deleted": "Block deleted",
                "enabled": "Block enabled",
                "disabled": "Block disabled",
                "bulk_destroyed": "Selected blocks deleted",
                "bulk_enabled": "Selected blocks enabled",
                "bulk_disabled": "Selected blocks disabled"
            },
            "actions": {
                "destroy": "Delete selected blocks",
                "enable": "Enable selected blocks",
                "disable": "Disable selected blocks"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected blocks",
                "bulk_enable": "Error on enabling selected blocks",
                "bulk_disable": "Error on disabling selected blocks"
            },
            "permissions": {
                "category": "Blocks",
                "view": {
                    "display_name": "View blocks",
                    "description": ""
                },
                "create": {
                    "display_name": "Create blocks",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit blocks",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete blocks",
                    "description": ""
                }
            }
        },
        "dashboard": {
            "permissions": {
                "category": "Dashboard",
                "create": {
                    "display_name": "Create dashboard"
                },
                "delete": {
                    "display_name": "Delete dashboard"
                },
                "edit": {
                    "display_name": "Edit dashboard"
                },
                "view": {
                    "display_name": "View dashboard"
                }
            },
            "sidebar": {
                "title": "Dashboard"
            },
            "titles": {
                "unit": "Dashboard"
            }
        },
        "escorts-verifications": {
            "actions": {
                "destroy": "Delete selected escortsVerifications",
                "disable": "Disable selected escortsVerifications",
                "enable": "Enable selected escortsVerifications"
            },
            "alerts": {
                "bulk_destroyed": "Selected escorts Verifications deleted",
                "bulk_disabled": "Selected escorts Verifications disabled",
                "bulk_enabled": "Selected escorts Verifications enabled",
                "created": "Escort Verification created",
                "deleted": "Escort Verification deleted",
                "disabled": "Escort Verification disabled",
                "enabled": "Escort Verification enabled",
                "updated": "Escort Verification updated"
            },
            "buttons": {
                "create": "Create Escort Verification"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected escortsVerifications",
                "bulk_disable": "Error on disabling selected escortsVerifications",
                "bulk_enable": "Error on enabling selected escortsVerifications"
            },
            "permissions": {
                "category": "Escorts Verifications",
                "create": {
                    "display_name": "Create escorts verifications"
                },
                "delete": {
                    "display_name": "Delete escorts verifications"
                },
                "edit": {
                    "display_name": "Edit escorts verifications"
                },
                "view": {
                    "display_name": "View escorts verifications"
                }
            },
            "sidebar": {
                "title": "Escorts Verifications"
            },
            "titles": {
                "create": "Escort Verification creation",
                "edit": "Profile Verification",
                "full-verification": "Profile Verifications",
                "list": "Profile Verifications",
                "unit": "Profile Verification",
                "units": "Profile Verifications"
            }
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "main_content": "Main information",
            "content": "Content",
            "url": "Url",
            "seo": "SEO",
            "meta": {
                "title": "Page title",
                "title-note": "If leave empty, item name will be used as title by default.",
                "description": "Meta description",
                "keywords": "Meta keywords"
            },
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "morphs": {
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "lt": "Lithuanian"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "register": "Register",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrateur",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "titles": {
                "dashboard": "Dashboard",
                "administration": "Administration"
            },
            "sidebar": {
                "options": "Options",
                "content": "Content management",
                "forms": "Form management",
                "access": "Access management",
                "seo": "SEO settings",
                "settings": {
                    "title": "Settings",
                    "attributes": "Attributes",
                    "attribute-groups": "Attribute groups",
                    "attribute-sets": "Attribute sets"
                },
                "jobs": {
                    "title": "Jobs"
                },
                "catalog": {
                    "title": "Catalog",
                    "products": "Products",
                    "categories": "Categories"
                }
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                }
            }
        },
        "banners-links": {
            "actions": {
                "destroy": "Delete selected banners Links",
                "disable": "Disable selected banners Links",
                "enable": "Enable selected banners Links"
            },
            "alerts": {
                "bulk_destroyed": "Selected banners Links deleted",
                "bulk_disabled": "Selected banners Links disabled",
                "bulk_enabled": "Selected banners Links enabled",
                "created": "Banner Link created",
                "deleted": "Banner Link deleted",
                "disabled": "Banner Link disabled",
                "enabled": "Banner Link enabled",
                "updated": "Banner Link updated"
            },
            "buttons": {
                "create": "Create Banner Link"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected banners links",
                "bulk_disable": "Error on disabling selected banners links",
                "bulk_enable": "Error on enabling selected banners links"
            },
            "field": {
                "verified": "Verified"
            },
            "permissions": {
                "category": "Banners Links",
                "create": {
                    "display_name": "Create banners links"
                },
                "delete": {
                    "display_name": "Delete banners links"
                },
                "edit": {
                    "display_name": "Edit banners links"
                },
                "view": {
                    "display_name": "View banners links"
                }
            },
            "sidebar": {
                "title": "Banner Links"
            },
            "titles": {
                "create": "Banner Link creation",
                "edit": "Banner Link edit",
                "list": "Banner Links",
                "unit": "Banner Link",
                "units": "Banner Links"
            }
        },
        "clubs": {
            "sidebar": {
                "title": "Clubs"
            },
            "titles": {
                "unit": "Club",
                "units": "Clubs",
                "list": "Clubs",
                "create": "Club creation",
                "edit": "Club edit",
                "dashboard-clubs": "Clubs",
                "dashboard-latest-verifications": "Awaiting data check / verification",
                "unverified": "Unverified Clubs"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Club",
                "all-verifications": "All awaiting data check / verification"
            },
            "field": {
                "description": "Description",
                "email": "Email",
                "name": "Name",
                "public": "Public",
                "show_name": "Show name",
                "blocked": "Blocked"
            },
            "label": {
                "all-records": "Clubs total count",
                "dashboard-active": "Active",
                "dashboard-canceled": "Canceled",
                "dashboard-correct": "Club whose data is correct",
                "dashboard-draft": "Records in data draft",
                "dashboard-pending": "Awaiting for data check",
                "dashboard-revise": "Awaiting for data corrections",
                "dashboard-suspended": "Suspended / Blocked",
                "dashboard-verification": "Awaiting verification",
                "dashboard-verified": "Verified",
                "no-results-awaiting": "All records has been checked / verified"
            },
            "status": {
                "active": "Verified",
                "canceled": "Canceled",
                "check": "Check",
                "check-verification": "Check / Verification",
                "correct": "Data is correct",
                "draft": "Draft",
                "reviewed": "Viewed",
                "revise": "Revise",
                "suspended": "Suspended",
                "verification": "Pending"
            },
            "alerts": {
                "created": "Club created",
                "updated": "Club updated",
                "deleted": "Club deleted",
                "enabled": "Club enabled",
                "disabled": "Club disabled",
                "bulk_destroyed": "Selected clubs deleted",
                "bulk_enabled": "Selected clubs enabled",
                "bulk_disabled": "Selected clubs disabled"
            },
            "actions": {
                "destroy": "Delete selected clubs",
                "enable": "Enable selected clubs",
                "disable": "Disable selected clubs"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected clubs",
                "bulk_enable": "Error on enabling selected clubs",
                "bulk_disable": "Error on disabling selected clubs"
            },
            "permissions": {
                "category": "Clubs",
                "view": {
                    "display_name": "View clubs",
                    "description": ""
                },
                "create": {
                    "display_name": "Create clubs",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit clubs",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete clubs",
                    "description": ""
                }
            }
        },
        "users-alerts": {
            "users": {
                "created": "User created",
                "updated": "User updated",
                "deleted": "User deleted",
                "bulk_destroyed": "Selected users deleted",
                "bulk_enabled": "Selected users enabled",
                "bulk_disabled": "Selected users disabled"
            }
        },
        "club-information": {
            "actions": {
                "destroy": "Delete selected club Information",
                "disable": "Disable selected club Information",
                "enable": "Enable selected club Information"
            },
            "alerts": {
                "bulk_destroyed": "Selected club Information deleted",
                "bulk_disabled": "Selected club Information disabled",
                "bulk_enabled": "Selected club Information enabled",
                "created": "Club Information created",
                "deleted": "Club Information deleted",
                "disabled": "Club Information disabled",
                "enabled": "Club Information enabled",
                "updated": "Club Information updated"
            },
            "buttons": {
                "create": "Create Club Information"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected club Information",
                "bulk_disable": "Error on disabling selected club Information",
                "bulk_enable": "Error on enabling selected club Information"
            },
            "permissions": {
                "category": "Club Information",
                "create": {
                    "display_name": "Create club information"
                },
                "delete": {
                    "display_name": "Delete club information"
                },
                "edit": {
                    "display_name": "Edit club information"
                },
                "view": {
                    "display_name": "View club information"
                }
            },
            "sidebar": {
                "title": "Clubs"
            },
            "titles": {
                "create": "Club Information creation",
                "edit": "Club Information edit",
                "list": "Club Information",
                "unit": "Club Information",
                "units": "Club Information"
            }
        },
        "languages": {
            "alert": {
                "bulk_destroyed": "Selected languages deleted",
                "bulk_disabled": "Selected languages disabled",
                "bulk_enabled": "Selected languages enabled",
                "created": "Language created",
                "deleted": "Language deleted",
                "disabled": "Language disabled",
                "enabled": "Language enabled",
                "updated": "Language updated"
            },
            "button": {
                "create": "Create Language"
            },
            "label": {
                "enabled_center": "Enabled on Backend",
                "enabled_site": "Enabled on Frontend",
                "interface_center": "Backend Interface",
                "locale": "Locale",
                "locale_select": "Select locale",
                "name": "Name",
                "native": "Native",
                "position_center": "Position on backend",
                "position_site": "Position on frontend",
                "regional": "Regional",
                "required_center": "Fields in this language are required",
                "show_center": "Show in backend list",
                "show_site": "Show in frontend list"
            },
            "note": {
                "center_sort_order": "The position of the language in the list of languages of the backend",
                "interface_center": "Use as backend interface language. There will also be a possibility to translate the backend in this language in Translations",
                "name": "Language name (mainly used)",
                "native": "Native language name",
                "required_center": "When filling in the fields of the module forms, the fields of this language are mandatory if it is provided in the backend part. For example, the category name must be filled in the default language, if you select this option, the category name must be filled in that language as well.",
                "show_center": "Show language in backend selection list",
                "show_site": "Show language in frontend selection list",
                "site_sort_order": "The position of the language in the list of languages of the frontend"
            },
            "permission": {
                "category": "Languages",
                "create": {
                    "display_name": "Create language"
                },
                "delete": {
                    "display_name": "Delete language"
                },
                "edit": {
                    "display_name": "Edit language"
                },
                "view": {
                    "display_name": "View languages"
                }
            },
            "section": {
                "center": "Backend Content",
                "center_interface": "Backend Interface",
                "site": "Frontend"
            },
            "sidebar": {
                "section": "Languages",
                "title": "Languages"
            },
            "tab": {
                "center": "Backend Content",
                "center_interface": "Backend Interface",
                "site": "Frontend"
            },
            "title": {
                "create": "Language creation",
                "edit": "Language edit",
                "list": "Languages",
                "unit": "Language",
                "units": "Languages"
            }
        },
        "mails": [],
        "brothels": {
            "sidebar": {
                "title": "Brothels"
            },
            "titles": {
                "unit": "Brothel",
                "units": "Brothels",
                "list": "Brothels",
                "create": "Brothel creation",
                "edit": "Brothel edit",
                "dashboard-brothels": "Brothels",
                "dashboard-latest-verifications": "Awaiting data check / verification",
                "unverified": "Unverified Brothels"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Brothel",
                "all-verifications": "All awaiting data check / verification"
            },
            "field": {
                "description": "Description",
                "email": "Email",
                "name": "Name",
                "public": "Public",
                "show_name": "Show name",
                "blocked": "Blocked"
            },
            "label": {
                "all-records": "Brothels total count",
                "dashboard-active": "Active",
                "dashboard-canceled": "Canceled",
                "dashboard-correct": "Brothel whose data is correct",
                "dashboard-draft": "Records in data draft",
                "dashboard-pending": "Awaiting for data check",
                "dashboard-revise": "Awaiting for data corrections",
                "dashboard-suspended": "Suspended / Blocked",
                "dashboard-verification": "Awaiting verification",
                "dashboard-verified": "Verified",
                "no-results-awaiting": "All records has been checked / verified"
            },
            "status": {
                "active": "Verified",
                "canceled": "Canceled",
                "check": "Check",
                "check-verification": "Check / Verification",
                "correct": "Data is correct",
                "draft": "Draft",
                "reviewed": "Viewed",
                "revise": "Revise",
                "suspended": "Suspended",
                "verification": "Pending"
            },
            "alerts": {
                "created": "Brothel created",
                "updated": "Brothel updated",
                "deleted": "Brothel deleted",
                "enabled": "Brothel enabled",
                "disabled": "Brothel disabled",
                "bulk_destroyed": "Selected brothels deleted",
                "bulk_enabled": "Selected brothels enabled",
                "bulk_disabled": "Selected brothels disabled"
            },
            "actions": {
                "destroy": "Delete selected brothels",
                "enable": "Enable selected brothels",
                "disable": "Disable selected brothels"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected brothels",
                "bulk_enable": "Error on enabling selected brothels",
                "bulk_disable": "Error on disabling selected brothels"
            },
            "permissions": {
                "category": "Brothels",
                "view": {
                    "display_name": "View brothels",
                    "description": ""
                },
                "create": {
                    "display_name": "Create brothels",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit brothels",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete brothels",
                    "description": ""
                }
            }
        },
        "parlour-services": {
            "actions": {
                "destroy": "Delete selected parlour Services",
                "disable": "Disable selected parlour Services",
                "enable": "Enable selected parlour Services"
            },
            "alerts": {
                "bulk_destroyed": "Selected parlour Services deleted",
                "bulk_disabled": "Selected parlour Services disabled",
                "bulk_enabled": "Selected parlour Services enabled",
                "created": "Parlour Service created",
                "deleted": "Parlour Service deleted",
                "disabled": "Parlour Service disabled",
                "enabled": "Parlour Service enabled",
                "updated": "Parlour Service updated"
            },
            "buttons": {
                "create": "Create Parlour Service"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected parlour Services",
                "bulk_disable": "Error on disabling selected parlour Services",
                "bulk_enable": "Error on enabling selected parlour Services"
            },
            "permissions": {
                "category": "Parlour Services",
                "create": {
                    "display_name": "Create parlour services"
                },
                "delete": {
                    "display_name": "Delete parlour services"
                },
                "edit": {
                    "display_name": "Edit parlour services"
                },
                "view": {
                    "display_name": "View parlour services"
                }
            },
            "sidebar": {
                "title": "Parlour Services"
            },
            "titles": {
                "create": "Parlour Service creation",
                "edit": "Parlour Service edit",
                "list": "Parlour Services",
                "unit": "Parlour Service",
                "units": "Parlour Services"
            }
        },
        "blog": {
            "actions": {
                "destroy": "Delete selected posts",
                "disable": "Disable selected posts",
                "enable": "Enable selected posts"
            },
            "alerts": {
                "bulk_destroyed": "Selected posts deleted",
                "bulk_disabled": "Selected posts disabled",
                "bulk_enabled": "Selected posts enabled",
                "created": "Post created",
                "deleted": "Post deleted",
                "disabled": "Post disabled",
                "enabled": "Post enabled",
                "updated": "Post updated"
            },
            "buttons": {
                "create": "Create Post",
                "create-category": "Create News category"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected posts",
                "bulk_disable": "Error on disabling selected posts",
                "bulk_enable": "Error on enabling selected posts"
            },
            "field": {
                "inner_image": "Show image on inner page"
            },
            "permissions": {
                "category": "Posts",
                "create": {
                    "display_name": "Create posts"
                },
                "delete": {
                    "display_name": "Delete posts"
                },
                "edit": {
                    "display_name": "Edit posts"
                },
                "view": {
                    "display_name": "View posts"
                }
            },
            "sidebar": {
                "categories-title": "News categories",
                "section": "News",
                "title": "Posts"
            },
            "statuses": {
                "draft": "Draft",
                "pending": "Pending",
                "published": "Published"
            },
            "titles": {
                "create": "Post creation",
                "create-category": "Create News category",
                "edit": "Post edit",
                "list": "Posts",
                "list-categories": "Categories",
                "unit": "Post",
                "unit-category": "News category",
                "units": "Posts"
            }
        },
        "roles-alerts": {
            "roles": {
                "created": "Role created",
                "updated": "Role updated",
                "deleted": "Role deleted",
                "bulk_destroyed": "Selected roles deleted",
                "bulk_enabled": "Selected roles enabled",
                "bulk_disabled": "Selected roles disabled"
            }
        },
        "assets": {
            "sidebar": {
                "title": "Assets"
            },
            "titles": {
                "unit": "Asset",
                "units": "Assets",
                "list": "Assets",
                "create": "Asset creation",
                "edit": "Asset edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Asset"
            },
            "alerts": {
                "created": "Asset created",
                "updated": "Asset updated",
                "deleted": "Asset deleted",
                "enabled": "Asset enabled",
                "disabled": "Asset disabled",
                "bulk_destroyed": "Selected assets deleted",
                "bulk_enabled": "Selected assets enabled",
                "bulk_disabled": "Selected assets disabled"
            },
            "actions": {
                "destroy": "Delete selected assets",
                "enable": "Enable selected assets",
                "disable": "Disable selected assets"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected assets",
                "bulk_enable": "Error on enabling selected assets",
                "bulk_disable": "Error on disabling selected assets"
            },
            "permissions": {
                "category": "Assets",
                "view": {
                    "display_name": "View assets",
                    "description": ""
                },
                "create": {
                    "display_name": "Create assets",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit assets",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete assets",
                    "description": ""
                }
            }
        },
        "escorts": {
            "actions": {
                "destroy": "Delete selected escorts",
                "disable": "Disable selected escorts",
                "enable": "Enable selected escorts"
            },
            "alerts": {
                "bulk_destroyed": "Selected escorts deleted",
                "bulk_disabled": "Selected escorts disabled",
                "bulk_enabled": "Selected escorts enabled",
                "created": "Escort created",
                "deleted": "Escort deleted",
                "disabled": "Escort disabled",
                "enabled": "Escort enabled",
                "updated": "Escort updated"
            },
            "buttons": {
                "all-verifications": "All awaiting data check / verification",
                "create": "Create Escort"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected escorts",
                "bulk_disable": "Error on disabling selected escorts",
                "bulk_enable": "Error on enabling selected escorts"
            },
            "field": {
                "blocked": "Blocked",
                "description": "Description",
                "email": "Email",
                "name": "Name",
                "public": "Public",
                "show_name": "Show name"
            },
            "label": {
                "all-records": "Escorts total count",
                "dashboard-active": "Active",
                "dashboard-canceled": "Canceled",
                "dashboard-correct": "Escort whose data is correct",
                "dashboard-draft": "Records in data draft",
                "dashboard-pending": "Awaiting for data check",
                "dashboard-revise": "Awaiting for data corrections",
                "dashboard-suspended": "Suspended / Blocked",
                "dashboard-verification": "Awaiting verification",
                "dashboard-verified": "Verified",
                "no-results-awaiting": "All records has been checked / verified"
            },
            "permissions": {
                "category": "Escorts",
                "create": {
                    "display_name": "Create escorts"
                },
                "delete": {
                    "display_name": "Delete escorts"
                },
                "edit": {
                    "display_name": "Edit escorts"
                },
                "view": {
                    "display_name": "View escorts"
                }
            },
            "sidebar": {
                "title": "Escorts"
            },
            "status": {
                "1": "Verified",
                "2": "Declined",
                "active": "Verified",
                "canceled": "Canceled",
                "check": "Check",
                "check-verification": "Check / Verification",
                "correct": "Data is correct",
                "draft": "Draft",
                "reviewed": "Viewed",
                "revise": "Revise",
                "suspended": "Suspended",
                "unverified": "Unverified",
                "verification": "Pending"
            },
            "titles": {
                "create": "Escort creation",
                "dashboard-escorts": "Escorts",
                "dashboard-latest-verifications": "Awaiting data check / verification",
                "edit": "Escort edit",
                "list": "Escorts",
                "unit": "Escort",
                "units": "Escorts",
                "unverified": "Unverified Escorts"
            }
        },
        "email-services": {
            "actions": {
                "destroy": "Delete selected emailServices",
                "disable": "Disable selected emailServices",
                "enable": "Enable selected emailServices"
            },
            "alert": {
                "send-list-created": "Send list created",
                "send-list-deleted": "Send list deleted",
                "send-list-send": "Send list mail sending executed",
                "send-list-updated": "Send list updated",
                "template-created": "Email template created",
                "template-deleted": "Email template deleted",
                "template-updated": "Email template updated",
                "test-email-failed": "Failed to send test email",
                "test-email-send": "Test email successfully sent"
            },
            "alerts": {
                "bulk_destroyed": "Selected emailServices deleted",
                "bulk_disabled": "Selected emailServices disabled",
                "bulk_enabled": "Selected emailServices enabled",
                "created": "EmailService created",
                "deleted": "EmailService deleted",
                "disabled": "EmailService disabled",
                "enabled": "EmailService enabled",
                "message-created": "Message send",
                "updated": "EmailService updated"
            },
            "button": {
                "campaign-create": "Create Campaign",
                "campaign-prepare": "Prepare for sending",
                "campaign-send": "Send Campaign",
                "download_invalid": "Download invalid records",
                "list-create": "Create Send List",
                "segment-create": "Create Segment / Group",
                "send-test-email": "Send preview test email",
                "subscriber-create": "Create Subscriber",
                "subscriber-import": "Import Subscribers",
                "subscribers-count": "Subscribers",
                "template-create": "Create Template"
            },
            "buttons": {
                "create": "Create Email Service"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected email services",
                "bulk_disable": "Error on disabling selected email services",
                "bulk_enable": "Error on enabling selected email services"
            },
            "field": {
                "as-email": "Delivery via email",
                "as-emails": "Delivery via email",
                "as-notification": "Delivery via notification",
                "as-notifications": "Delivery via notifications",
                "created_count": "Created",
                "email-service": "Email Service",
                "file": "CSV file",
                "file_name": "File name on system",
                "important": "Important",
                "invalid_count": "Invalid",
                "list-bounce": "Bounce",
                "list-clicked": "Clicked",
                "list-created": "Created",
                "list-opened": "Opened",
                "list-sent": "Sent",
                "list-status": "Status",
                "message-click-count": "Click count",
                "message-content": "Content",
                "message-email": "Email",
                "message-open-count": "Open count",
                "message-send-at": "Send At",
                "message-status": "Status",
                "message-subject": "Subject",
                "name": "Service name",
                "processed_count": "Processed",
                "recipient": "Recipient",
                "recipients": "Recipients",
                "send-list-content": "Content",
                "send-list-from-email": "From email",
                "send-list-from-name": "From name",
                "send-list-is-click-tracking": "Email click tracking",
                "send-list-is-open-tracking": "Email open tracking",
                "send-list-name": "Send list name",
                "send-list-subject": "Subject",
                "service-domain": "Domain",
                "service-encryption": "Encryption",
                "service-host": "Host",
                "service-password": "Password",
                "service-port": "Post",
                "service-secret": "Secret",
                "service-type": "Service type",
                "service-type-api": "API Key",
                "service-username": "Username",
                "service-zone": "Zone",
                "template-code": "Template content",
                "template-id": "Template",
                "template-name": "Template name",
                "updated_count": "Updated"
            },
            "note": {
                "send-list-internal-use": "For internal use only"
            },
            "permissions": {
                "category": "Send Services",
                "create": {
                    "display_name": "Create email_services"
                },
                "delete": {
                    "display_name": "Delete email_services"
                },
                "edit": {
                    "display_name": "Edit email_services"
                },
                "view": {
                    "display_name": "View email_services"
                }
            },
            "sidebar": {
                "list-title": "Send lists",
                "messages-title": "Messages",
                "notifications-title": "Notifications",
                "service-title": "Email Services",
                "templates-title": "Templates",
                "title": "Sending services"
            },
            "tab": {
                "list-buttons": "Buttons",
                "list-content": "Content"
            },
            "title": {
                "list-title": "Send Lists",
                "message-list": "Messages",
                "message-send": "Send message",
                "message-unit": "Message",
                "message-units": "Messages",
                "send-list": "Send List",
                "send-list-create": "Create send list",
                "send-list-edit": "Send list edit",
                "subscriber-units": "Subscribers",
                "template-create": "Template creation",
                "template-edit": "Template  edit",
                "template-list": "Templates",
                "template-unit": "Template",
                "template-units": "Templates"
            },
            "titles": {
                "create": "Email Service creation",
                "edit": "Email Service edit",
                "list": "Email Services",
                "unit": "Email Service",
                "units": "Email Services"
            }
        },
        "brothel-information": {
            "actions": {
                "destroy": "Delete selected brothel Information",
                "disable": "Disable selected brothel Information",
                "enable": "Enable selected brothel Information"
            },
            "alerts": {
                "bulk_destroyed": "Selected brothel Information deleted",
                "bulk_disabled": "Selected brothel Information disabled",
                "bulk_enabled": "Selected brothel Information enabled",
                "created": "Brothel Information created",
                "deleted": "Brothel Information deleted",
                "disabled": "Brothel Information disabled",
                "enabled": "Brothel Information enabled",
                "updated": "Brothel Information updated"
            },
            "buttons": {
                "create": "Create Brothel Information"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected brothel Information",
                "bulk_disable": "Error on disabling selected brothel Information",
                "bulk_enable": "Error on enabling selected brothel Information"
            },
            "permissions": {
                "category": "Brothel Information",
                "create": {
                    "display_name": "Create brothel information"
                },
                "delete": {
                    "display_name": "Delete brothel information"
                },
                "edit": {
                    "display_name": "Edit brothel information"
                },
                "view": {
                    "display_name": "View brothel information"
                }
            },
            "sidebar": {
                "title": "Brothels"
            },
            "titles": {
                "create": "Brothel Information creation",
                "edit": "Brothel Information edit",
                "list": "Brothel Information",
                "unit": "Brothel Information",
                "units": "Brothel Information"
            }
        }
    },
    "lt": {
        "pagination": {
            "previous": "&laquo; Ankstesnis",
            "next": "Sekantis &raquo;"
        },
        "settings": {
            "sidebar": {
                "title": "Settings"
            },
            "titles": {
                "unit": "Settings",
                "units": "Settings",
                "list": "Settings",
                "create": "Settings creation",
                "edit": "Settings edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Settings"
            },
            "alerts": {
                "created": "Settings created",
                "updated": "Settings updated",
                "deleted": "Settings deleted",
                "enabled": "Settings enabled",
                "disabled": "Settings disabled",
                "bulk_destroyed": "Selected settings deleted",
                "bulk_enabled": "Selected settings enabled",
                "bulk_disabled": "Selected settings disabled"
            },
            "actions": {
                "destroy": "Delete selected settings",
                "enable": "Enable selected settings",
                "disable": "Disable selected settings"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected settings",
                "bulk_enable": "Error on enabling selected settings",
                "bulk_disable": "Error on disabling selected settings"
            },
            "permissions": {
                "category": "Settings",
                "view": {
                    "display_name": "View settings",
                    "description": ""
                },
                "create": {
                    "display_name": "Create settings",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit settings",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete settings",
                    "description": ""
                }
            }
        },
        "users-buttons": {
            "users": {
                "create": "Sukurti vartotoją"
            }
        },
        "validation": {
            "accepted": "Laukas {attribute} turi būti priimtas.",
            "active_url": "Laukas {attribute} nėra galiojantis internetinis adresas.",
            "after": "Lauko {attribute} reikšmė turi būti po {date} datos.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "Laukas {attribute} gali turėti tik raides.",
            "alpha_dash": "Laukas {attribute} gali turėti tik raides, skaičius ir brūkšnelius.",
            "alpha_num": "Laukas {attribute} gali turėti tik raides ir skaičius.",
            "array": "Laukas {attribute} turi būti masyvas.",
            "before": "Laukas {attribute} turi būti data prieš {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "Lauko {attribute} reikšmė turi būti tarp {min} ir {max}.",
                "file": "Failo dydis lauke {attribute} turi būti tarp {min} ir {max} kilobaitų.",
                "string": "Simbolių skaičius lauke {attribute} turi būti tarp {min} ir {max}.",
                "array": "Elementų skaičius lauke {attribute} turi turėti nuo {min} iki {max}."
            },
            "boolean": "Lauko reikšmė {attribute} turi būti 'taip' arba 'ne'.",
            "confirmed": "Lauko {attribute} patvirtinimas nesutampa.",
            "date": "Lauko {attribute} reikšmė nėra galiojanti data.",
            "date_format": "Lauko {attribute} reikšmė neatitinka formato {format}.",
            "different": "Laukų {attribute} ir {other} reikšmės turi skirtis.",
            "digits": "Laukas {attribute} turi būti sudarytas iš {digits} skaitmenų.",
            "digits_between": "Laukas {attribute} tuti turėti nuo {min} iki {max} skaitmenų.",
            "dimensions": "Lauke {attribute} įkeltas paveiksliukas neatitinka išmatavimų reikalavimo.",
            "distinct": "Laukas {attribute} pasikartoja.",
            "email": "Lauko {attribute} reikšmė turi būti galiojantis el. pašto adresas.",
            "file": "The {attribute} must be a file.",
            "filled": "Laukas {attribute} turi būti užpildytas.",
            "exists": "Pasirinkta negaliojanti {attribute} reikšmė.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "Lauko {attribute} reikšmė turi būti paveikslėlis.",
            "in": "Pasirinkta negaliojanti {attribute} reikšmė.",
            "in_array": "Laukas {attribute} neegzistuoja {other} lauke.",
            "integer": "Lauko {attribute} reikšmė turi būti sveikasis skaičius.",
            "ip": "Lauko {attribute} reikšmė turi būti galiojantis IP adresas.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "Lauko {attribute} reikšmė turi būti JSON tekstas.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "Lauko {attribute} reikšmė negali būti didesnė nei {max}.",
                "file": "Failo dydis lauke {attribute} reikšmė negali būti didesnė nei {max} kilobaitų.",
                "string": "Simbolių kiekis lauke {attribute} reikšmė negali būti didesnė nei {max} simbolių.",
                "array": "Elementų kiekis lauke {attribute} negali turėti daugiau nei {max} elementų."
            },
            "mimes": "Lauko reikšmė {attribute} turi būti failas vieno iš sekančių tipų: {values}.",
            "mimetypes": "Lauko reikšmė {attribute} turi būti failas vieno iš sekančių tipų: {values}.",
            "min": {
                "numeric": "Lauko {attribute} reikšmė turi būti ne mažesnė nei {min}.",
                "file": "Failo dydis lauke {attribute} turi būti ne mažesnis nei {min} kilobaitų.",
                "string": "Simbolių kiekis lauke {attribute} turi būti ne mažiau nei {min}.",
                "array": "Elementų kiekis lauke {attribute} turi būti ne mažiau nei {min}."
            },
            "not_in": "Pasirinkta negaliojanti reikšmė {attribute}.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "Lauko {attribute} reikšmė turi būti skaičius.",
            "present": "Laukas {attribute} turi egzistuoti.",
            "regex": "Negaliojantis lauko {attribute} formatas.",
            "required": "Privaloma užpildyti lauką {attribute}.",
            "required_if": "Privaloma užpildyti lauką {attribute} kai {other} yra {value}.",
            "required_unless": "Laukas {attribute} yra privalomas, nebent {other} yra tarp {values} reikšmių.",
            "required_with": "Privaloma užpildyti lauką {attribute} kai pateikta {values}.",
            "required_with_all": "Privaloma užpildyti lauką {attribute} kai pateikta {values}.",
            "required_without": "Privaloma užpildyti lauką {attribute} kai nepateikta {values}.",
            "required_without_all": "Privaloma užpildyti lauką {attribute} kai nepateikta nei viena iš reikšmių {values}.",
            "same": "Laukai {attribute} ir {other} turi sutapti.",
            "size": {
                "numeric": "Lauko {attribute} reikšmė turi būti {size}.",
                "file": "Failo dydis lauke {attribute} turi būti {size} kilobaitai.",
                "string": "Simbolių skaičius lauke {attribute} turi būti {size}.",
                "array": "Elementų kiekis lauke {attribute} turi būti {size}."
            },
            "string": "Laukas {attribute} turi būti tekstinis.",
            "timezone": "Lauko {attribute} reikšmė turi būti galiojanti laiko zona.",
            "unique": "Tokia {attribute} reikšmė jau pasirinkta.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "Negaliojantis lauko {attribute} formatas.",
            "lang-required": "Lauką {Field} privaloma užpildyti {Lang}-name ({LANG}-CODE).",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Pavadinimas",
                "identifier": "Kodas",
                "display_name": "Rodomas pavadinimas",
                "username": "Pseudo",
                "email": "El. paštas",
                "first_name": "Vardas",
                "last_name": "Pavardė",
                "password": "Slaptažodis",
                "password_confirmation": "Pakartoti slaptažodį",
                "old_password": "Senas slaptažodis",
                "new_password": "Naujas slaptažodis",
                "new_password_confirmation": "Patvirtinti naują slaptažodį",
                "postal_code": "Pašto kodas",
                "city": "Miestas",
                "country": "Šalis",
                "address": "Adresas",
                "phone": "Telefonas",
                "mobile": "Mobilus",
                "age": "Amžius",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Diena",
                "month": "Mėnuo",
                "year": "Metai",
                "hour": "Valanda",
                "minute": "Minutė",
                "second": "Sekundė",
                "title": "Title",
                "content": "Turinys",
                "description": "Apibudinimas",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Data",
                "time": "Laikas",
                "available": "Available",
                "size": "Dydis",
                "roles": "Rolės",
                "permissions": "Leidimai",
                "active": "Aktyvus",
                "enabled": "Aktyvus",
                "disabled": "Neaktyvus",
                "message": "Žinutė",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL adresas",
                "url-target": "URL nuoroda",
                "url-target-info": "URL nuoroda galite pasiekti tik tuo atveju jei elementas nėra išjungtas.",
                "url-description": "Jei URL adreso lauko neužpildysite jis bus automatiškai sugeneruotas iš pavadinimo.",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Pozicija",
                "position": "Pozicija",
                "image": "Image",
                "status": "Statusas",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Paskelbta",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "working_hours": "Darbo laikas",
                "zip": "Pašto kodas",
                "coordinates": "Koordinatės",
                "price": "Kaina",
                "page-title": "Puslapio pavadinimas"
            }
        },
        "users-labels": {
            "sidebar": {
                "root": "Prieiga",
                "title": "Vartotojai"
            },
            "users": {
                "sidebar": {
                    "label": "Prieiga"
                },
                "titles": {
                    "label": "Vartotojai",
                    "main": "Vartotojai",
                    "index": "Vartotojų sąrašas",
                    "create": "Naujo vartotojo kūrimas",
                    "edit": "Vartotojo koregavimas"
                },
                "labels": {
                    "checkbox": "Žymėti",
                    "restrictions": "Apribojimai"
                },
                "actions": {
                    "destroy": "Ištrinti pasirinktus vartotojus",
                    "enable": "Aktyvuoti pasirinktus vartotojus",
                    "disable": "Deaktyvuoti pasirinktus vartotojus"
                }
            }
        },
        "auth": {
            "failed": "Neteisingti prisijungimo duomenys",
            "throttle": "Perdaug bandymų prisijungti. Bandykite po {seconds} sec.",
            "socialite": {
                "unacceptable": "{provider} neleidžia prisijungti."
            }
        },
        "roles-labels": {
            "sidebar": {
                "title": "Rolės"
            },
            "roles": {
                "titles": {
                    "main": "Rolės",
                    "index": "Rolių sąrašas",
                    "create": "Naujos rolės kūrimas",
                    "edit": "Rolės koregavimas"
                }
            }
        },
        "roles-buttons": {
            "roles": {
                "create": "Sukurti rolę"
            }
        },
        "passwords": {
            "password": "Slaptažodis turi būti bent šešių simbolių ir sutapti su patvirtinimu.",
            "reset": "Nustatytas naujas slaptažodis!",
            "sent": "Naujo slaptažodžio nustatymo nuoroda išsiųsta",
            "token": "Šis slaptažodžio raktas yra neteisingas.",
            "user": "Vartotojas su tokiu el. paštu nerastas."
        },
        "core": {
            "alert": {
                "invalid_action": "Invalid action"
            },
            "button": {
                "back": "Back",
                "cancel": "Cancel",
                "create": "Create",
                "edit": "Edit",
                "save-and-close": "Save and close"
            },
            "datatable": {
                "entries_per_page": "entries per page",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries",
                "no_matched_results": "No matched results available",
                "no_results": "No results available",
                "search": "Search",
                "show_per_page": "Show"
            },
            "label": {
                "actions": "Actions",
                "name": "Name",
                "no": "No",
                "yes": "Yes"
            },
            "section": {
                "main": "Main information"
            },
            "tab": {
                "main": "Main information"
            }
        },
        "buttons": {
            "cancel": "Atšaukti",
            "save": "Saugoti",
            "save-continue": "Saugoti ir tęsti redagavimą",
            "save-and-close": "Saugoti ir uždaryti",
            "save-and-continue": "Saugoti ir tęsti duomenų pildymą",
            "close": "Uždaryti",
            "create": "Sukurti",
            "delete": "Trinti",
            "confirm": "Patvirtinti",
            "show": "Rodyti",
            "edit": "Koreguoti",
            "update": "Atnaujinti",
            "view": "Peržiūra",
            "preview": "Peržiūrėti",
            "back": "Atgal",
            "send": "Siųsti",
            "login-as": "Prisijungta {name}",
            "apply": "Taikyti"
        },
        "translations": {
            "action": {
                "destroy": "Delete selected translations",
                "disable": "Disable selected translations",
                "enable": "Enable selected translations"
            },
            "alert": {
                "bulk_destroyed": "Selected translations deleted",
                "bulk_disabled": "Selected translations disabled",
                "bulk_enabled": "Selected translations enabled",
                "created": "Translation created",
                "deleted": "Translation deleted",
                "disabled": "Translation disabled",
                "enabled": "Translation enabled",
                "import": "Translations imported",
                "publish": "Translations published",
                "sync": "Translations synced",
                "updated": "Translation updated"
            },
            "button": {
                "create": "Create Translation",
                "import": "Import Translations",
                "publish": "Publish Translations",
                "sync-center": "Sync Backend Translations",
                "sync-site": "Sync Frontend Translations"
            },
            "exception": {
                "bulk_destroy": "Error on deleting selected translations",
                "bulk_disable": "Error on disabling selected translations",
                "bulk_enable": "Error on enabling selected translations"
            },
            "filter": {
                "area": "Area",
                "groups": "Groups",
                "languages": "Languages",
                "modules": "Modules",
                "status": "Status"
            },
            "label": {
                "area": "Area",
                "backend": "Backend",
                "frontend": "Frontend",
                "group": "Group",
                "key": "Key",
                "locale": "Locale",
                "module": "Module",
                "original": "Origin",
                "original_translation": "Original translation",
                "status": "asafaf",
                "value": "Translation"
            },
            "message": {
                "import_progress": "Please wait, translations import in progress ...",
                "publish_progress": "Please wait, translations publish in progress ...",
                "sync_progress": "Please wait, translations sync in progress ..."
            },
            "permission": {
                "category": "Translations",
                "create": {
                    "display_name": "Create translation"
                },
                "delete": {
                    "display_name": "Delete translation"
                },
                "edit": {
                    "display_name": "Edit translation"
                },
                "view": {
                    "display_name": "View translations"
                }
            },
            "sidebar": {
                "section": "Translations",
                "title": "Translations"
            },
            "title": {
                "create": "Translation creation",
                "edit": "Translation edit",
                "edit-form": "Origin translation edit",
                "filters": "Filters3",
                "import": "Translations importing",
                "list": "Translations",
                "publish": "Translations publishing",
                "status": "sdfsfasdfasdf asfasdff s fsf",
                "sync": "Translations sync",
                "unit": "Translation",
                "units": "Translations"
            },
            "tooltip": {
                "status_missing": "Status - Missing translation",
                "status_ok": "Status - Translated",
                "status_waiting": "Status - Waiting to be published"
            }
        },
        "categories": {
            "sidebar": {
                "title": "Kategorijos"
            },
            "titles": {
                "unit": "Kategorija",
                "units": "Kategorijos",
                "list": "Kategorijos",
                "create": "Kategorijos kūrimas",
                "create-sub": "Sub Kategorijos kūrimas",
                "edit": "Kategorijos koregavimas"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Sukurti kategoriją",
                "create-subcategory": "Sukurti sub kategoriją",
                "subcategories": "Sub Kategorijos"
            },
            "alerts": {
                "created": "Kategorija sukurta",
                "updated": "Kategorija atnaujinta",
                "deleted": "Kategorija ištrinta",
                "enabled": "Kategorija įjungta",
                "disabled": "Kategorija išjungta",
                "bulk_destroyed": "Selected categories deleted",
                "bulk_enabled": "Selected categories enabled",
                "bulk_disabled": "Selected categories disabled"
            },
            "actions": {
                "destroy": "Delete selected categories",
                "enable": "Enable selected categories",
                "disable": "Disable selected categories"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected categories",
                "bulk_enable": "Error on enabling selected categories",
                "bulk_disable": "Error on disabling selected categories"
            },
            "permissions": {
                "category": "Kategorijos",
                "view": {
                    "display_name": "Kategorijų peržiūra",
                    "description": ""
                },
                "create": {
                    "display_name": "Sukurti naują kategoriją",
                    "description": ""
                },
                "edit": {
                    "display_name": "Koreguoti kategoriją",
                    "description": ""
                },
                "delete": {
                    "display_name": "Ištrinti kategoriją",
                    "description": ""
                }
            }
        },
        "blacklist-labels": {
            "blacklist": {
                "titles": {
                    "main": "Blacklist",
                    "index": "Blacklist list",
                    "create": "Blacklist creation",
                    "edit": "Blacklist modification"
                },
                "actions": {
                    "destroy": "Delete selected Blacklist",
                    "enable": "Enable selected Blacklist",
                    "disable": "Disable selected Blacklist"
                }
            }
        },
        "blacklist-buttons": {
            "blacklist": {
                "create": "Create Blacklist"
            }
        },
        "roles-permissions": {
            "category": "Rolės",
            "view": {
                "display_name": "Rolių peržiūra",
                "description": ""
            },
            "create": {
                "display_name": "Sukurti naują rolę",
                "description": ""
            },
            "edit": {
                "display_name": "Koreguoti rolę",
                "description": ""
            },
            "delete": {
                "display_name": "Ištrinti rolę",
                "description": ""
            }
        },
        "pages": {
            "sidebar": {
                "root": "Turinys",
                "section": "Turinio valdymas",
                "title": "Puslapiai"
            },
            "titles": {
                "unit": "Puslapis",
                "units": "Puslapiai",
                "list": "Puslapiai",
                "create": "Puslapio kūrimas",
                "edit": "Puslapio koregavimas"
            },
            "labels": {
                "header": "Rodyti viršuje (header)",
                "footer": "Rodyti apačioje (footer)",
                "content_html": "Turinys (Html)"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Sukurti puslapį"
            },
            "alerts": {
                "created": "Puslapis sukurtas",
                "updated": "Puslapis atnaujintas",
                "deleted": "Puslapis ištrintas",
                "enabled": "Puslapis įjungtas",
                "disabled": "Puslapis išjungtas",
                "header-disabled": "Puslapis išjungtas viršuje (header)",
                "header-enabled": "Puslapis įjungtas viršuje (header)",
                "footer-disabled": "Puslapis išjungtas apačioje (footer)",
                "footer-enabled": "Puslapis įjungtas apačioje (footer)",
                "bulk_destroyed": "Selected pages deleted",
                "bulk_enabled": "Selected pages enabled",
                "bulk_disabled": "Selected pages disabled"
            },
            "actions": {
                "destroy": "Delete selected pages",
                "enable": "Enable selected pages",
                "disable": "Disable selected pages"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected pages",
                "bulk_enable": "Error on enabling selected pages",
                "bulk_disable": "Error on disabling selected pages"
            },
            "permissions": {
                "category": "Turinio puslapiai",
                "view": {
                    "display_name": "Peržiūrėti puslapius",
                    "description": ""
                },
                "create": {
                    "display_name": "Sukurti naują puslapį",
                    "description": ""
                },
                "edit": {
                    "display_name": "Koreguoti puslapį",
                    "description": ""
                },
                "delete": {
                    "display_name": "Ištrinti puslapį",
                    "description": ""
                }
            }
        },
        "geo-buttons": {
            "geo": {
                "create": "Create Geo"
            }
        },
        "geo-labels": {
            "geo": {
                "titles": {
                    "main": "Geo",
                    "index": "Geo list",
                    "create": "Geo creation",
                    "edit": "Geo modification"
                },
                "actions": {
                    "destroy": "Delete selected Geo",
                    "enable": "Enable selected Geo",
                    "disable": "Disable selected Geo"
                }
            }
        },
        "users-permissions": {
            "category": "Vartotojai",
            "category_backend": "Backend",
            "backend": {
                "display_name": "Leisti prisijungti prie backend",
                "description": ""
            },
            "view": {
                "display_name": "Vartotojų peržiūra",
                "description": ""
            },
            "create": {
                "display_name": "Sukurti naują vartotoją",
                "description": ""
            },
            "edit": {
                "display_name": "Koreguoti vartotoją",
                "description": ""
            },
            "delete": {
                "display_name": "Ištrinti vartotoją",
                "description": ""
            }
        },
        "blocks": {
            "sidebar": {
                "title": "Blokai"
            },
            "titles": {
                "unit": "Blokas",
                "units": "Blokai",
                "list": "Blokai",
                "create": "Bloko kūrimas",
                "edit": "Bloko koregavimas"
            },
            "labels": {
                "comment": "Komentaras",
                "content_html": "Turinys (Html formatu)",
                "btn_label": "Mygtuko pavadinimas",
                "btn_url": "Mykgtuko nuoroda",
                "btn_class": "Mygtuko papildoma css klasė",
                "btn_target_blank": "Atidaryti mygtuko nuorodą naujame lange"
            },
            "notes": {
                "comment": "Vidiniam naudojimui"
            },
            "tabs": {
                "buttons": "Mygtukai"
            },
            "sections": {
                "buttons": "Mygtukas",
                "buttons_settings": "Mygtuko nustatymai"
            },
            "buttons": {
                "create": "Sukurti bloką"
            },
            "alerts": {
                "created": "Blokas sukurtas",
                "updated": "Blokas atnaujintas",
                "deleted": "Blokas ištrintas",
                "enabled": "Blokas įjungtas",
                "disabled": "Blokas išjungtas",
                "bulk_destroyed": "Selected blocks deleted",
                "bulk_enabled": "Selected blocks enabled",
                "bulk_disabled": "Selected blocks disabled"
            },
            "actions": {
                "destroy": "Delete selected blocks",
                "enable": "Enable selected blocks",
                "disable": "Disable selected blocks"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected blocks",
                "bulk_enable": "Error on enabling selected blocks",
                "bulk_disable": "Error on disabling selected blocks"
            },
            "permissions": {
                "category": "Turinio blokai",
                "view": {
                    "display_name": "Peržiūrėti blokus",
                    "description": ""
                },
                "create": {
                    "display_name": "Sukurti naują bloką",
                    "description": ""
                },
                "edit": {
                    "display_name": "Koreguoti bloką",
                    "description": ""
                },
                "delete": {
                    "display_name": "Ištrinti bloką",
                    "description": ""
                }
            }
        },
        "blacklist-alerts": {
            "blacklist": {
                "created": "Blacklist created",
                "updated": "Blacklist updated",
                "deleted": "Blacklist deleted",
                "bulk_destroyed": "Selected Blacklist deleted",
                "bulk_enabled": "Selected Blacklist enabled",
                "bulk_disabled": "Selected Blacklist disabled"
            }
        },
        "labels": {
            "language": "Kalba",
            "actions": "Veiksmai",
            "general": "Pagrindinis",
            "no_results": "Rezultatų nėra",
            "search": "Ieškoti",
            "validate": "Vykdyti",
            "choose_file": "Pasirinkti failą",
            "no_file_chosen": "Failas neparinktas",
            "are_you_sure": "Ar tikrai ?",
            "delete_image": "Trinti paveiksliuką",
            "yes": "Taip",
            "no": "Ne",
            "add_new": "Pridėti",
            "export": "Eksportuoti",
            "more_info": "Daugiau",
            "author": "Autorius",
            "author_id": "Autoriaus ID",
            "last_access_at": "Paskutinį kartą prisijungta",
            "published_at": "Paskelbta",
            "created_at": "Sukurta",
            "updated_at": "Atnaujinta",
            "deleted_at": "Ištrinta",
            "no_value": "Nėra reikšmės",
            "upload_image": "Įkelti paveiksliuką",
            "anonymous": "Anonimas",
            "all_rights_reserved": "Visos teisės saugomos.",
            "with": "su",
            "by": "by",
            "main_content": "Pagrindinė informacija",
            "content": "Turinys",
            "url": "Nuorodos (URL)",
            "seo": "SEO",
            "meta": {
                "title": "Puslapio pavadinimas",
                "title-note": "Jei paliksite tuščią, puslapio pavadinimui bus naudojamas elemento pavadinimas",
                "description": "Meta apibūdinimas",
                "keywords": "Meta raktažodžiai"
            },
            "datatables": {
                "no_results": "Nieko nerasta",
                "no_matched_results": "Pagal nurodytus kriterijus rezultatų nerasta",
                "show_per_page": "Rodyti",
                "entries_per_page": "įrašai per puslapį",
                "search": "Ieškoti",
                "infos": "Rodoma {offset_start} iki {offset_end} iš {total} įrašų"
            },
            "morphs": {
                "user": "Vartotojas, identifikatorius {id}"
            },
            "auth": {
                "disabled": "Jūsų paskyra buvo užblokuota."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "Anglų k.",
                "lt": "Lietuvių k."
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Prisiminti",
                "login": "Prisijungti",
                "logout": "Atsijungti",
                "password_forgot": "Pamiršote slaptažodį ?",
                "send_password_link": "Siųsti slaptažodžio keitimo nuorodą",
                "password_reset": "Slaptažodžio keitimas",
                "register": "Registruoti",
                "space": "Mano erdvė",
                "settings": "Nustatymai",
                "account": "Mano paskyra",
                "profile": "Mano profilis",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Koreguoti mano profilį",
                "change_password": "Keisti mano slaptažodį",
                "delete": "Trinti mano paskyrą",
                "administration": "Adminisravimas",
                "member_since": "Narys nuo {date}",
                "profile_updated": "Profilis sėkmingai atnaujintas.",
                "password_updated": "Slaptaždosis sėkmingai atnaujintas.",
                "super_admin": "Super administratorius",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "Mano erdvė",
                    "account": "Mano paskyra"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "titles": {
                "dashboard": "Pagrindinis",
                "administration": "Administravimas"
            },
            "sidebar": {
                "options": "Variantai / Pasirinkimai",
                "forms": "Form management",
                "access": "Prieigos valdymas",
                "seo": "SEO settings",
                "settings": {
                    "title": "Nustatymai",
                    "attributes": "Atributai",
                    "attribute-groups": "Atributų grupės",
                    "attribute-sets": "Atributų rinkiniai"
                },
                "jobs": {
                    "title": "Darbai"
                },
                "catalog": {
                    "title": "Katalogas",
                    "products": "Produktai",
                    "categories": "Kategorijos",
                    "colors": "Spalvos",
                    "occasions": "Progos",
                    "dedications": "Skirta"
                },
                "content": {
                    "title": "Turinys",
                    "content": "Turinys",
                    "pages": "Puslapiai"
                },
                "sales": {
                    "title": "Pardavimai",
                    "orders": "Užsakymai"
                }
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Aktyvūs vartotojai",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "user": "Naujas vartotojas",
                    "role": "Nauja rolė"
                }
            }
        },
        "companies": {
            "sidebar": {
                "title": "Įmonės"
            },
            "titles": {
                "unit": "Įmonė",
                "units": "Įmonės",
                "list": "Įmonės",
                "create": "Company creation",
                "edit": "Company edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Company"
            },
            "alerts": {
                "created": "Company created",
                "updated": "Company updated",
                "deleted": "Company deleted",
                "enabled": "Company enabled",
                "disabled": "Company disabled",
                "bulk_destroyed": "Selected companies deleted",
                "bulk_enabled": "Selected companies enabled",
                "bulk_disabled": "Selected companies disabled"
            },
            "actions": {
                "destroy": "Delete selected companies",
                "enable": "Enable selected companies",
                "disable": "Disable selected companies"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected companies",
                "bulk_enable": "Error on enabling selected companies",
                "bulk_disable": "Error on disabling selected companies"
            },
            "permissions": {
                "category": "Companies",
                "view": {
                    "display_name": "View companies",
                    "description": ""
                },
                "create": {
                    "display_name": "Create companies",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit companies",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete companies",
                    "description": ""
                }
            }
        },
        "users-alerts": {
            "users": {
                "created": "Vartotojas sukurtas",
                "updated": "Vartotojas atnaujintas",
                "deleted": "Vartotojas ištrintas",
                "bulk_destroyed": "Pasirinkti vartotojai ištrinti",
                "bulk_enabled": "Pasirinkti vartotojai aktyvuoti",
                "bulk_disabled": "Pasirinkti vartotojai deaktyvuoti"
            }
        },
        "languages": {
            "alert": {
                "bulk_destroyed": "Selected languages deleted",
                "bulk_disabled": "Selected languages disabled",
                "bulk_enabled": "Selected languages enabled",
                "created": "Language created",
                "deleted": "Language deleted",
                "disabled": "Language disabled",
                "enabled": "Language enabled",
                "updated": "Language updated"
            },
            "button": {
                "create": "Create Language"
            },
            "label": {
                "enabled_center": "Enabled on Backend",
                "enabled_site": "Enabled on Frontend",
                "interface_center": "Backend Interface",
                "locale": "Locale",
                "locale_select": "Select locale",
                "name": "Name",
                "native": "Native",
                "position_center": "Position on backend",
                "position_site": "Position on frontend",
                "regional": "Regional",
                "required_center": "Fields in this language are required",
                "show_center": "Show in backend list",
                "show_site": "Show in frontend list"
            },
            "note": {
                "center_sort_order": "The position of the language in the list of languages of the backend",
                "interface_center": "Use as backend interface language. There will also be a possibility to translate the backend in this language in Translations",
                "name": "Language name (mainly used)",
                "native": "Native language name",
                "required_center": "When filling in the fields of the module forms, the fields of this language are mandatory if it is provided in the backend part. For example, the category name must be filled in the default language, if you select this option, the category name must be filled in that language as well.",
                "show_center": "Show language in backend selection list",
                "show_site": "Show language in frontend selection list",
                "site_sort_order": "The position of the language in the list of languages of the frontend"
            },
            "permission": {
                "category": "Languages",
                "create": {
                    "display_name": "Create language"
                },
                "delete": {
                    "display_name": "Delete language"
                },
                "edit": {
                    "display_name": "Edit language"
                },
                "view": {
                    "display_name": "View languages"
                }
            },
            "section": {
                "center": "Backend Content",
                "center_interface": "Backend Interface",
                "site": "Frontend"
            },
            "sidebar": {
                "section": "64564",
                "title": "Kalbos"
            },
            "tab": {
                "center": "Backend Content",
                "center_interface": "Backend Interface",
                "site": "Frontend"
            },
            "title": {
                "create": "Language creation",
                "edit": "Language edit",
                "list": "Languages",
                "unit": "Language",
                "units": "Languages"
            }
        },
        "countries": {
            "sidebar": {
                "section": "Geo",
                "title": "Countries"
            },
            "titles": {
                "unit": "Šalis",
                "units": "Šalys",
                "list": "Šalys",
                "create": "Country creation",
                "edit": "Country edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Country"
            },
            "labels": {
                "name": "Pavadinimas",
                "name_original": "Originalus pavadinimas",
                "code": "Kodas",
                "capital": "Sostinė",
                "area": "Plotas",
                "tld": "Top level domain"
            },
            "alerts": {
                "created": "Country created",
                "updated": "Country updated",
                "deleted": "Country deleted",
                "enabled": "Country enabled",
                "disabled": "Country disabled",
                "bulk_destroyed": "Selected countries deleted",
                "bulk_enabled": "Selected countries enabled",
                "bulk_disabled": "Selected countries disabled"
            },
            "actions": {
                "destroy": "Delete selected countries",
                "enable": "Enable selected countries",
                "disable": "Disable selected countries"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected countries",
                "bulk_enable": "Error on enabling selected countries",
                "bulk_disable": "Error on disabling selected countries"
            },
            "permissions": {
                "category": "Šalys",
                "view": {
                    "display_name": "Peržiūrėti šalis",
                    "description": ""
                },
                "create": {
                    "display_name": "Sukurti šalį",
                    "description": ""
                },
                "edit": {
                    "display_name": "Koreguoti šalį",
                    "description": ""
                },
                "delete": {
                    "display_name": "Ištrinti šalį",
                    "description": ""
                }
            }
        },
        "geo-alerts": {
            "geo": {
                "created": "Geo created",
                "updated": "Geo updated",
                "deleted": "Geo deleted",
                "bulk_destroyed": "Selected Geo deleted",
                "bulk_enabled": "Selected Geo enabled",
                "bulk_disabled": "Selected Geo disabled"
            }
        },
        "roles-alerts": {
            "roles": {
                "created": "Rolė sukurta",
                "updated": "Rolė atnaujinta",
                "deleted": "Rolė ištrinta",
                "bulk_destroyed": "Pasirinktos rolės ištrintos",
                "bulk_enabled": "Pasirinktos rolės aktyvuotos",
                "bulk_disabled": "Pasirinktos rolės deaktyvuotos"
            }
        }
    },
    "lv": {
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "lang-required": "The {Field} field is required in {Lang}-name ({LANG}-CODE) language.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "identifier": "Code",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "enabled": "Enabled",
                "disabled": "Disabled",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "url-target": "URL link",
                "url-target-info": "Remember. You can only access the URL link if the item is not disabled.",
                "url-description": "If you do not fill in the URL field, it will be automatically generated from the item name.",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "price": "Price",
                "page-title": "Page title"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "save-continue": "Save and Continue Edit",
            "save-and-close": "Save and close",
            "save-and-continue": "Save and continue filling data",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply"
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "main_content": "Main information",
            "content": "Content",
            "url": "Url",
            "seo": "SEO",
            "meta": {
                "title": "Page title",
                "title-note": "If leave empty, item name will be used as title by default.",
                "description": "Meta description",
                "keywords": "Meta keywords"
            },
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "morphs": {
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "lt": "Lithuanian"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "register": "Register",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrateur",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "titles": {
                "dashboard": "Dashboard",
                "administration": "Administration"
            },
            "sidebar": {
                "options": "Options",
                "content": "Content management",
                "forms": "Form management",
                "access": "Access management",
                "seo": "SEO settings",
                "settings": {
                    "title": "Settings",
                    "attributes": "Attributes",
                    "attribute-groups": "Attribute groups",
                    "attribute-sets": "Attribute sets"
                },
                "jobs": {
                    "title": "Jobs"
                },
                "catalog": {
                    "title": "Catalog",
                    "products": "Products",
                    "categories": "Categories"
                }
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                }
            }
        }
    },
    "ru": {
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "lang-required": "The {Field} field is required in {Lang}-name ({LANG}-CODE) language.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "identifier": "Code",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "enabled": "Enabled",
                "disabled": "Disabled",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "url-target": "URL link",
                "url-target-info": "Remember. You can only access the URL link if the item is not disabled.",
                "url-description": "If you do not fill in the URL field, it will be automatically generated from the item name.",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "price": "Price",
                "page-title": "Page title"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "save-continue": "Save and Continue Edit",
            "save-and-close": "Save and close",
            "save-and-continue": "Save and continue filling data",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply"
        },
        "translations": {
            "sidebar": {
                "title": "Translations",
                "section": "Translations"
            },
            "titles": {
                "unit": "Translation",
                "units": "Translations",
                "list": "Translations",
                "create": "Translation creation",
                "edit": "Translation edit",
                "import": "Translations importing",
                "publish": "Translations publishing"
            },
            "buttons": {
                "create": "Create Translation",
                "import": "Import/Sync Translations",
                "publish": "Publish translations"
            },
            "alerts": {
                "created": "Translation created",
                "updated": "Translation updated",
                "deleted": "Translation deleted",
                "enabled": "Translation enabled",
                "disabled": "Translation disabled",
                "bulk_destroyed": "Selected translations deleted",
                "bulk_enabled": "Selected translations enabled",
                "bulk_disabled": "Selected translations disabled"
            },
            "actions": {
                "destroy": "Delete selected translations",
                "enable": "Enable selected translations",
                "disable": "Disable selected translations"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected translations",
                "bulk_enable": "Error on enabling selected translations",
                "bulk_disable": "Error on disabling selected translations"
            },
            "permissions": {
                "category": "Translations",
                "view": {
                    "display_name": "View translations"
                },
                "create": {
                    "display_name": "Create translations"
                },
                "edit": {
                    "display_name": "Edit translations"
                },
                "delete": {
                    "display_name": "Delete translations"
                }
            }
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "main_content": "Main information",
            "content": "Content",
            "url": "Url",
            "seo": "SEO",
            "meta": {
                "title": "Page title",
                "title-note": "If leave empty, item name will be used as title by default.",
                "description": "Meta description",
                "keywords": "Meta keywords"
            },
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "morphs": {
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "lt": "Lithuanian"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "register": "Register",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrateur",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "titles": {
                "dashboard": "Dashboard",
                "administration": "Administration"
            },
            "sidebar": {
                "options": "Options",
                "content": "Content management",
                "forms": "Form management",
                "access": "Access management",
                "seo": "SEO settings",
                "settings": {
                    "title": "Settings",
                    "attributes": "Attributes",
                    "attribute-groups": "Attribute groups",
                    "attribute-sets": "Attribute sets"
                },
                "jobs": {
                    "title": "Jobs"
                },
                "catalog": {
                    "title": "Catalog",
                    "products": "Products",
                    "categories": "Categories"
                }
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                }
            }
        }
    }
}
