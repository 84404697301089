<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('club-services.titles.create') }}
            </h3>

            <div class="multi-lang multi-lang-open">
              <div
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                v-if="locale.backend"
              >
                <b-form-group
                  :label="$t('validation.attributes.name')"
                  :label-for="`name.${index}`"
                  :label-cols="3"
                  :feedback="feedback('name.' + index)"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`name.${index}`"
                      :name="`name.${index}`"
                      v-model="model.name[`${index}`]"
                      :state="state('name.' + index)"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fab fa-autoprefixer"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('name.' + index) }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>

            <b-form-group
              :label="$t('validation.attributes.order')"
              label-for="order"
              label-cols-sm="3"
            >
              <b-row>
                <b-col md="3">
                  <b-input-group>
                    <b-input-group-prepend class="input-group-prepend-position">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-arrows-alt"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      id="order"
                      name="order"
                      type="number"
                      v-model="model.order"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="enabled"
                    v-model="model.enabled"
                    :description="$t('validation.attributes.enabled')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <template v-if="!modal">
              <b-row slot="footer">
                <b-col>
                  <b-button
                    to="/club/services"
                    exact
                    variant="danger"
                    size="sm"
                  >
                    {{ $t('buttons.back') }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    size="sm"
                    class="float-right"
                    :disabled="pending"
                    v-if="isNew"
                  >
                    {{ $t('buttons.create') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="modal">
              <action-footer-modal
                :page-title="$t('club-services.titles.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="true"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'

export default {
  title: '',
  name: 'ClubServiceCreateForm',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'club_service',
      resourceRoute: 'club_services',
      listPath: '/club/services',
      model: {
        // Data
        name: {},
        icon: '',
        order: '',
        enabled: false,
        // Routes
        routes: {}
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('club-services.titles.create')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    }
  }
}
</script>
