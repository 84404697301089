<template>
  <AppHeader fixed>
    <SidebarToggler class="d-lg-none" display="md" mobile></SidebarToggler>
    <a class="navbar-brand" :href="$app.homePath" target="_blank">
      <img
        class="navbar-brand-full"
        src="@main/images/logo.png"
        height="32"
        alt="Corion"
      />
      <img
        class="navbar-brand-minimized"
        src="@main/images/logo.png"
        width="32"
        height="32"
        alt="Corion"
      />
    </a>
    <!--
      <SidebarToggler class="d-md-down-none" display="lg"></SidebarToggler>
    -->
    <b-navbar-nav class="ml-auto">
      <HeaderDropdown right class="px-3 d-none d-md-block">
        <template slot="header">
          <span class="d-md-down-none"> {{ $t('labels.language') }} </span>
        </template>
        <template slot="dropdown">
          <b-dropdown-item
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.interface"
            :hreflang="index"
            :href="`/${index}${$route.fullPath}`"
          >
            {{ locale.native }}
          </b-dropdown-item>
        </template>
      </HeaderDropdown>
      <HeaderDropdown right class="px-3">
        <template slot="header">
          <img
            :src="this.$app.user.avatar"
            class="img-avatar"
            :alt="$t('labels.user.avatar')"
          />
          <span class="d-md-down-none"> {{ this.$app.user.name }} </span>
        </template>
        <template slot="dropdown">
          <!--
            <b-dropdown-item :href="$app.route('user.account')">
              <i class="fe fe-user"></i>&nbsp;&nbsp;{{ $t('labels.user.profile') }}
            </b-dropdown-item>
          -->
          <b-dropdown-item :href="$app.route('logout')">
            <i class="fe fe-log-out"></i>&nbsp;&nbsp;{{
              $t('labels.user.logout')
            }}
          </b-dropdown-item>
        </template>
      </HeaderDropdown>
    </b-navbar-nav>
    <AsideToggler class="d-none d-lg-block"></AsideToggler>
  </AppHeader>
</template>

<script>
import AppHeader from '../vendor/coreui/components/Header/Header'
export default {
  name: 'Header',
  components: {
    AppHeader
  }
}
</script>
