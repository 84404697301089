export default {
  path: 'agency/services',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'agency_services',
      component: require('@agency-services/js/components/AgencyServiceList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'agency_services_create',
      component: require('@agency-services/js/components/AgencyServiceCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'agency_services_edit',
      component: require('@agency-services/js/components/AgencyServiceEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
