<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <b-form-group
                  :label="$t('banners.field.name') + ' *'"
                  label-for="name"
                  :label-cols="3"
                  :feedback="feedback('name')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="model.name"
                          :state="state('name')"
                        ></b-form-input>
                        <b-form-feedback>
                          {{ feedback('name') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('banners.field.url') + ' *'"
                  label-for="name"
                  :label-cols="3"
                  :feedback="feedback('url')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="model.url"
                          :state="state('url')"
                        ></b-form-input>
                        <b-form-feedback>
                          {{ feedback('url') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="visible"
                        v-model="model.visible"
                        :description="$t('banners.field.visible')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="verified"
                        v-model="model.verified"
                        :description="$t('banners-links.field.verified')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
                <b-form-group
                  :label="$t('validation.attributes.image')"
                  label-for="banner_image"
                  horizontal
                  :label-cols="2"
                  :feedback="feedback('banner_image')"
                >
                  <div class="media">
                    <b-img-style
                      v-if="model.banner_image_url"
                      :src="model.banner_image_url"
                      :width="model.banner_width"
                      :height="model.banner_height"
                      rounded
                      fluid
                      class="mr-2"
                    ></b-img-style>

                    <div class="media-body">
                      <h6>{{ $t('labels.upload_image') }}</h6>
                      <b-form-file
                        id="banner_image"
                        name="banner_image"
                        ref="bannerImageInput"
                        :placeholder="$t('labels.no_file_chosen')"
                        v-model="model.banner_image"
                        :state="state('banner_image')"
                        v-b-tooltip.hover
                        :title="$t('labels.descriptions.allowed_image_types')"
                      ></b-form-file>
                      <a
                        href="#"
                        class="d-block mt-1"
                        v-if="model.has_banner_image || model.banner_image"
                        @click.prevent="deleteBannerImage"
                      >
                        {{ $t('labels.delete_image') }}
                      </a>
                    </div>
                  </div>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `BannerLinkEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'banner_link',
      resourceRoute: 'banners_links',
      listPath: '/banners/links',
      model: {
        // Data
        name: '',
        size: '',
        note: '',
        url: '',
        path: '',
        show_until: '',
        visible: false,
        verified: false,
        banner_image: null,
        banner_image_url: null,
        has_banner_image: false,
        banner_width: 120,
        banner_height: 80,
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('banners-links.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('banners-links.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
