<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <b-form-group
                  :label="$t('escorts.field.name') + ' *'"
                  label-for="name"
                  :label-cols="3"
                  :feedback="feedback('name')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="model.name"
                          :state="state('name')"
                        ></b-form-input>
                        <b-form-feedback>
                          {{ feedback('name') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('escorts.field.show_name') + ' *'"
                  label-for="show_name"
                  :label-cols="3"
                  :feedback="feedback('show_name')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="show_name"
                          name="show_name"
                          v-model="model.show_name"
                          :state="state('show_name')"
                        ></b-form-input>
                        <b-form-feedback>
                          {{ feedback('show_name') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('escorts.field.email') + ' *'"
                  label-for="email"
                  :label-cols="3"
                  :feedback="feedback('email')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-at"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="email"
                          name="email"
                          type="email"
                          v-model="model.email"
                          :state="state('email')"
                        ></b-form-input>
                        <b-form-feedback>
                          {{ feedback('email') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="public"
                        v-model="model.public"
                        :description="$t('escorts.field.public')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="blocked"
                        v-model="model.blocked"
                        :description="$t('escorts.field.blocked')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `ClientEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'client',
      resourceRoute: 'clients',
      listPath: '/clients',
      model: {
        // Data
        name: '',
        show_name: '',
        email: '',
        public: false,
        blocked: false,
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('clients.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('clients.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
