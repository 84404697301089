export default {
  path: 'packages',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'plans',
      component: require('@coins/js/components/PackageList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'plans_create',
      component: require('@coins/js/components/PackageCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'plans_edit',
      component: require('@coins/js/components/PackageEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
