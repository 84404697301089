import 'babel-polyfill'
import './load-client-scripts'

// Vue & axios
import Vue from 'vue'

export const EventBus = new Vue()
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import VuejsDialog from 'vuejs-dialog'
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import './axios-config'

import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'

// Vendor plugins components
import './vendor/coreui/components'
import rawDisplayer from '@core/js/helpers/raw-displayer'
import DataTable from '@core/js/components/Plugins/DataTable'
import RichTextEditor from '@core/js/components/Plugins/RichTextEditor'
import DateTimePicker from '@core/js/components/Plugins/DateTimePicker'
import Switch from '@core/js/components/Plugins/Switch'
import vSelect from '@core/js/components/Plugins/Select'
import ImageStyle from '@core/js/components/Plugins/ImageStyle'
import CardSelect from '@core/js/components/Plugins/CardSelect'

import VModal from 'vue-js-modal'
import fontawesome from '@fortawesome/fontawesome'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import ActionHeader from '@core/js/components/Elements/ActionHeader'
import ActionFooter from '@core/js/components/Elements/ActionFooter'
import ActionFooterModal from '@core/js/components/Elements/ActionFooterModal'
import DataSidebar from '@core/js/components/DataSidebar'

import * as VueGoogleMaps from 'vue2-google-maps'

import { createRouter } from './router'
import { createStore } from './store'
import { createLocales } from './vue-i18n-config'
import VuePageTitle from 'vue-page-title'

import App from './App.vue'
import Noty from 'noty'
import Vuebar from 'vuebar'

Vue.use(VueLodash, { lodash: lodash })

// Bootstrap Vue
Vue.use(BootstrapVue)

Vue.use(VuejsDialog)
Vue.use(CoolLightBox)

window._get = require('lodash/get')
window._eachRight = require('lodash/eachRight')
window._replace = require('lodash/replace')
window._has = require('lodash/has')
window._size = require('lodash/size')

Vue.prototype.trans = (string, args) => {
  let value = _get(window.i18n, string)

  _eachRight(args, (paramVal, paramKey) => {
    value = _replace(value, `:${paramKey}`, paramVal)
  })

  if (value) {
    return value
  }
  return string
}

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAksiUXmZcrpudgyhO2ZhCt6SJnf3jvJD4',
    language: 'en',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  }
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.use(fontawesome)
Vue.use(VModal, { dialog: true, dynamic: true })
Vue.use(Vuebar)
Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: `| ${window.settings.appName}`
})

Vue.component('rawDisplayer', rawDisplayer)

// vue-select
Vue.component('v-select', vSelect)

// Custom components
Vue.component('c-switch', Switch)
Vue.component('p-datetimepicker', DateTimePicker)
Vue.component('p-richtexteditor', RichTextEditor)
Vue.component('b-datatable', DataTable)
Vue.component('b-img-style', ImageStyle)
Vue.component('card-select', CardSelect)

Vue.component('action-header', ActionHeader)
Vue.component('action-footer', ActionFooter)
Vue.component('action-footer-modal', ActionFooterModal)
Vue.component('data-sidebar', DataSidebar)

export function createApp() {
  // Init router and store
  const i18n = createLocales(window.settings.locale)
  const router = createRouter(window.settings.adminHomePath, i18n)
  const store = createStore(window.route)

  Vue.prototype.$eventHub = new Vue()

  /**
   * Server-side settings
   */
  Vue.prototype.$app = window.settings

  /**
   * Server-side named routes function router
   */
  Vue.prototype.$app.route = window.route

  /**
   * Client-side permissions
   */
  if (Vue.prototype.$app.user) {
    Vue.prototype.$app.user.can = permission => {
      if (Vue.prototype.$app.user.id === 1) {
        return true
      }
      return Vue.prototype.$app.permissions.includes(permission)
    }
  }

  /**
   * Object to FormData converter
   */
  let objectToFormData = (obj, form, namespace) => {
    let fd = form || new FormData()

    for (let property in obj) {
      if (!obj.hasOwnProperty(property)) {
        continue
      }

      let formKey = namespace ? `${namespace}[${property}]` : property

      if (obj[property] === null) {
        fd.append(formKey, '')
        continue
      }
      if (typeof obj[property] === 'boolean') {
        fd.append(formKey, obj[property] ? '1' : '0')
        continue
      }
      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString())
        continue
      }
      if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey)
        continue
      }
      fd.append(formKey, obj[property])
    }

    return fd
  }

  Vue.prototype.$app.objectToFormData = objectToFormData

  /**
   * Notifications
   */
  let noty = (type, text) => {
    new Noty({
      layout: 'topRight',
      theme: 'bootstrap-v4',
      timeout: 2000,
      text,
      type
    }).show()
  }

  Vue.prototype.$app.noty = {
    alert: text => {
      noty('alert', text)
    },
    success: text => {
      noty('success', text)
    },
    error: text => {
      noty('error', text)
    },
    warning: text => {
      noty('warning', text)
    },
    info: text => {
      noty('info', text)
    }
  }

  Vue.prototype.$app.error = error => {
    if (error instanceof String) {
      noty('error', error)
      return
    }

    if (error.response) {
      // Not allowed error
      if (error.response.status === 403) {
        noty('error', i18n.t('exceptions.unauthorized'))
        return
      }

      // Domain error
      if (error.response.data.error !== undefined) {
        noty('error', error.response.data.message)
        return
      }
    }

    // Generic error
    noty('error', i18n.t('exceptions.general'))
  }

  router.beforeEach((to, from, next) => {
    document.title = `${to.meta.label} | ${window.settings.appName}`
    next()
  })

  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  })

  return { app, router, store }
}

// Init App
if (document.getElementById('app') !== null) {
  const { app } = createApp()
  app.$mount('#app')
}
