<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                    v-if="locale.backend"
                  >
                    <b-form-group
                      label="Plan name"
                      :label-for="`name.${index}`"
                      label-cols-sm="3"
                      :feedback="feedback('name.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`name.${index}`"
                          :name="`name.${index}`"
                          v-model="model.name[`${index}`]"
                          :state="state('name.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  label="Comment"
                  label-for="comment"
                  :label-cols="3"
                  description="For internal use only"
                  :feedback="feedback('comment')"
                >
                  <b-row>
                    <b-col md="12">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-comment-alt"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="comment"
                          name="comment"
                          v-model="model.comment"
                          :state="state('comment')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    label="Trial period"
                    label-for="trial_period"
                    :label-cols="3"
                    :feedback="feedback('trial_period')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-calendar"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            class="term_max"
                            type="number"
                            id="trial_period"
                            name="trial_period"
                            v-model="model.trial_period"
                            :state="state('trial_period')"
                          ></b-form-input>
                          <b-form-select
                            id="trial_interval"
                            name="trial_interval"
                            v-model="model.trial_interval"
                            :options="term_formats"
                            class="form-control form-control-format select-box-with-arrow"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('trial_period') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    label="Plan period"
                    label-for="invoice_period"
                    :label-cols="3"
                    :feedback="feedback('invoice_period')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-calendar"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            class="term_max"
                            type="number"
                            id="invoice_period"
                            name="invoice_period"
                            v-model="model.invoice_period"
                            :state="state('invoice_period')"
                          ></b-form-input>
                          <b-form-select
                            id="invoice_interval"
                            name="invoice_interval"
                            v-model="model.invoice_interval"
                            :options="term_formats"
                            class="form-control form-control-format select-box-with-arrow"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('invoice_interval') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    label="Price per period"
                    label-for="price"
                    :label-cols="3"
                    :feedback="feedback('price')"
                  >
                    <b-row>
                      <b-col md="4">
                        <b-input-group>
                          <b-form-input
                            class="price"
                            type="number"
                            id="price"
                            name="price"
                            v-model="model.price"
                            :state="state('price')"
                            step="0.01"
                          ></b-form-input>
                          <b-input-group-append
                            class="input-group-append-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-euro-sign"></i>
                            </b-input-group-text>
                          </b-input-group-append>
                          <b-form-feedback>
                            {{ feedback('price') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <b-form-group
                  label-cols-sm="3"
                  description="If on plan will visible in plan list for selection (Frontend)"
                >
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="show_center"
                        v-model="model.public"
                        description="Public"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  label-cols-sm="3"
                  description="If on plan will visible in plan list for selection (Backend) and if set public on Frontend"
                >
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="is_active"
                        v-model="model.is_active"
                        description="Active"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.order')"
                  label-for="sort_order"
                  label-cols-sm="3"
                >
                  <b-row>
                    <b-col md="3">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-position"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-arrows-alt"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="sort_order"
                          name="sort_order"
                          type="number"
                          v-model="model.sort_order"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab title="Description" id="description">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-seo"
                v-if="locale.backend"
              >
                <h3 class="header-card-title card-title" slot="header">
                  Description - {{ index }}
                </h3>
                <b-form-group
                  label="Description"
                  :label-for="`description.${index}`"
                  label-cols-sm="3"
                  :feedback="feedback('description.' + index)"
                >
                  <p-richtexteditor
                    name="description"
                    v-model="model.description[`${index}`]"
                  ></p-richtexteditor>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab title="Features" id="features">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Features
                </h3>
                <b-form-group
                  label-cols-sm="3"
                  description="VIP feature for plan"
                >
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="feature_vip"
                        v-model="model.feature_vip"
                        description="VIP"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  label-cols-sm="3"
                  description="GOLD feature for plan"
                >
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="feature_gold"
                        v-model="model.feature_gold"
                        description="Gold"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `PlanEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'plan',
      resourceRoute: 'plans',
      listPath: '/plans',
      term_formats: ['day', 'month'],
      model: {
        // Data
        name: {},
        description: {},
        is_active: false,
        public: false,
        comment: '',
        type: '',
        trial_period: '',
        trial_interval: 'day',
        invoice_period: '',
        invoice_interval: 'day',
        price: '',
        price_old: '',
        price_label: '',
        currency: 'EUR',
        sort_order: 0,
        feature_vip: false,
        feature_gold: false,
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('subscriptions.titles.edit-plan')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('subscriptions.titles.unit-plan')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
