export default {
  path: 'orders',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'orders',
      component: require('@orders/js/components/OrderList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'orders_create',
      component: require('@orders/js/components/OrderCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'orders_edit',
      component: require('@orders/js/components/OrderEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
