<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('clients.titles.list') }}
        </h3>
        <!--        <div class="card-options" v-if="this.$app.user.can('create clients')">
          <b-button @click="modalCreate" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('clients.buttons.create') }}
          </b-button>
        </div>-->
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="clients.search"
        delete-route="clients.destroy"
        action-route="clients.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(show_name)="row">
            <router-link
              v-if="row.item.can_edit"
              :to="`/clients/${row.item.id}/edit`"
              v-text="row.value"
              class="item-name-active"
            ></router-link>
            <span v-else v-text="row.value" class="item-name-active"></span>
            <span class="item-name-holder" v-if="row.item.name">{{
              row.item.name
            }}</span>
          </template>
          <template v-slot:cell(blocked)="row">
            <span v-if="row.value" class="status-banned">
              <i class="fas fa-user-slash"></i>
            </span>
            <span v-else class="status-live">
              <i class="fas fa-user"></i>
            </span>
          </template>
          <template v-slot:cell(public)="row">
            <span v-if="row.value" class="badge badge-success">
              {{ trans('core.label.yes') }}
            </span>
            <span v-else class="badge badge-danger">
              {{ trans('core.label.no') }}
            </span>
          </template>
          <template v-slot:cell(balance)="row">
            <span class="coins coins-in-list">
              <span class="balance-in">
                <template v-if="row.value > 0">
                  <span class="balance-pos">{{ row.value }}</span>
                </template>
                <template v-else>
                  <span class="balance-neg">{{ row.value }}</span>
                </template>
              </span>
              <i class="fas fa-coins"></i>
            </span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              v-if="row.item.can_edit"
              size="sm"
              variant="primary"
              :to="`/clients/${row.item.id}/edit`"
              v-b-tooltip.hover
              :title="$t('buttons.edit')"
              class="mr-1"
            >
              <i class="fas fa-edit"></i>
            </b-button>
            <b-dropdown
              size="sm"
              text=""
              class="adm-btn-dropdown action-btn-dropdown"
            >
              <b-dropdown-item
                v-if="row.item.can_impersonate"
                :href="
                  $app.appUrlClient +
                    '/endpoint/access/client/' +
                    row.item.sid +
                    '/' +
                    row.item.access_token
                "
                target="_blank"
              >
                <span class="icon-holder icon-info">
                  <i class="fas fa-sign-in-alt"></i>
                </span>
                <span class="label-holder label-info">{{
                  trans('core.button.login-as')
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="
                  AddCoins(
                    row.item.sid, // id
                    'add-coins', // type
                    '', // module
                    'Client', // module model
                    'App\\Client', // path to model
                    row.item.name, // recipient
                    row.item.balance // balance
                  )
                "
              >
                <span class="icon-holder">
                  <i class="fas fa-coins"></i>
                </span>
                <span class="label-holder">Add coins</span>
              </b-dropdown-item>
              <b-dropdown-item @click.stop="onDelete(row.item.id)">
                <span class="icon-holder icon-danger">
                  <i class="fas fa-trash-alt"></i>
                </span>
                <span class="label-holder label-delete">{{
                  trans('core.button.delete')
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
    <modals-container></modals-container>
  </div>
</template>

<script>
import axios from 'axios'
import ClientCreateForm from './ClientCreateForm.vue'
import AddCoins from '@coins/js/components/Modals/AddCoins'
export default {
  title: '',
  name: 'ClientList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'show_name',
          label: 'Show name / name',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'public',
          label: 'Public',
          sortable: true,
          class: 'data-required'
        },
        {
          key: 'blocked',
          label: 'Blocked',
          sortable: true,
          class: 'data-required'
        },
        {
          key: 'balance',
          label: 'Coins',
          sortable: true,
          class: 'data-required'
        },
        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('clients.actions.destroy'),
        enable: this.$t('clients.actions.enable'),
        disable: this.$t('clients.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('clients.titles.list')
  },
  methods: {
    modalCreate() {
      this.$modal.show(
        ClientCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ client: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('clients.enabled', { client: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    AddCoins(id, type, module, moduleModel, modelPath, recipient, balance) {
      this.$modal.show(
        AddCoins,
        {
          modal: true,
          id: id,
          type: type,
          module: module,
          moduleModel: moduleModel,
          modelPath: modelPath,
          recipient: recipient,
          balance: balance
        },
        {
          name: 'translation-form-edit',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    }
  }
}
</script>
