<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <b-form-group
                  :label="$t('escorts.field.name') + ' *'"
                  label-for="name"
                  :label-cols="3"
                  :feedback="feedback('name')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="model.name"
                          :state="state('name')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('escorts.field.show_name') + ' *'"
                  label-for="show_name"
                  :label-cols="3"
                  :feedback="feedback('show_name')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="show_name"
                          name="show_name"
                          v-model="model.show_name"
                          :state="state('show_name')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('escorts.field.email') + ' *'"
                  label-for="email"
                  :label-cols="3"
                  :feedback="feedback('email')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-at"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="email"
                          name="email"
                          type="email"
                          v-model="model.email"
                          :state="state('email')"
                        ></b-form-input>
                        <b-form-feedback>
                          {{ feedback('email') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="public"
                        v-model="model.public"
                        :description="$t('escorts.field.public')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="blocked"
                        v-model="model.blocked"
                        :description="$t('parlours.field.blocked')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Status
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      Current status:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <span v-html="statusRender(model.status)"></span>
                    </div>
                  </div>
                  <!--                  <b-form-group
                    label="Status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'reviewed'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_reviewed"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'revise'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_revise"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'active'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_active"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'suspended'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_suspended"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Reason for suspension"
                    label-for="canceled_content"
                    :label-cols="3"
                    :feedback="feedback('canceled_content')"
                    v-if="model.status_to == 'suspended'"
                  >
                    <b-input-group>
                      <p-richtexteditor
                        id="canceled_content"
                        name="canceled_content"
                        v-model="model.canceled_content"
                        :rows="10"
                      ></p-richtexteditor>
                    </b-input-group>
                    <b-form-feedback>
                      {{ feedback('canceled_content') }}
                    </b-form-feedback>
                  </b-form-group>
                  <b-form-group
                    label="Reason for adjustment"
                    label-for="revise_content"
                    :label-cols="3"
                    :feedback="feedback('revise_content')"
                    v-if="model.status_to == 'revise'"
                  >
                    <b-input-group>
                      <p-richtexteditor
                        id="revise_content"
                        name="revise_content"
                        v-model="model.revise_content"
                        :rows="10"
                      ></p-richtexteditor>
                    </b-input-group>
                    <b-form-feedback>
                      {{ feedback('revise_content') }}
                    </b-form-feedback>
                  </b-form-group>-->
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab title="Description" id="content">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang group-lang-seo">
                <h3 class="header-card-title card-title" slot="header">
                  Description
                </h3>
                <b-form-group
                  :label="$t('escorts.field.description')"
                  :label-for="`description`"
                  label-cols-sm="3"
                  :feedback="feedback('description')"
                >
                  <b-form-textarea
                    :id="description"
                    :name="description"
                    :rows="20"
                    v-model="model.description"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab title="History" id="history">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang group-lang-seo">
                <h3 class="header-card-title card-title" slot="header">
                  History
                </h3>
                <div class="multi-lang multi-lang-open service-items-wrap">
                  <div
                    :key="index"
                    v-for="(historyRecord, index) in data.history"
                  >
                    <div class="row row-service-item row-service-item-history">
                      <div class="col-3 col-service-item-name">
                        {{ historyRecord.history_at }}
                      </div>
                      <!-- Draft -->
                      <div class="col-9 col-service-item-value">
                        <template v-if="historyRecord.status == 'draft'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <!-- Ordered -->
                        <template v-if="historyRecord.status == 'check'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <!-- Reviewed -->
                        <template v-if="historyRecord.status == 'reviewed'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <!-- Completed -->
                        <template v-if="historyRecord.status == 'correct'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <!-- Canceled -->
                        <template v-if="historyRecord.status == 'canceled'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                          <div class="row row-history-comment">
                            <div class="col-5 col-comment-label">
                              Reason for rejection:
                            </div>
                            <div class="col-7 col-comment-value">
                              <span v-html="historyRecord.content"></span>
                            </div>
                          </div>
                        </template>
                        <!-- Revise -->
                        <template v-if="historyRecord.status == 'revise'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                          <div class="row row-history-comment">
                            <div class="col-5 col-comment-label">
                              Reason for adjustment:
                            </div>
                            <div class="col-7 col-comment-value">
                              <span v-html="historyRecord.content"></span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="row row-history-send-user"
                          v-if="historyRecord.send_to_user"
                        >
                          <div class="col-5 col-send-label">
                            Sent email to user:
                          </div>
                          <div class="col-7 col-send-value">
                            <span class="badge email-send-status">Yes</span>
                            <span class="vline-divider">
                              |
                            </span>
                            <span class="user-email-list">
                              {{ historyRecord.send_email }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="row row-history-send-user"
                          v-if="historyRecord.send_to_managers"
                        >
                          <div class="col-5 col-send-label">
                            Sent email to manager:
                          </div>
                          <div class="col-7 col-send-value">
                            <span class="badge email-send-status">Yes</span>
                            <span class="vline-divider">
                              |
                            </span>
                            <span class="user-email-list">
                              {{ historyRecord.managers_emails }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="$t('labels.url')" id="url">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-url"
                v-if="locale.backend"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.url') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('validation.attributes.url')"
                  :label-for="`routes.${index}.slug`"
                  label-cols-sm="3"
                  :feedback="feedback('routes.' + index + '.slug')"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`routes.${index}.slug`"
                      :name="`routes.${index}.slug`"
                      v-model="model.routes[`${index}`]['slug']"
                      :state="state('routes.' + index + '.slug')"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-link"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('routes.' + index + '.slug') }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.url-target')"
                  label-for="target"
                  :description="$t('validation.attributes.url-target-info')"
                  label-cols-sm="3"
                >
                  <div class="url-link">
                    <a
                      :href="
                        $app.appUrlClient +
                          '/' +
                          meta.routes[`${index}`]['slug']
                      "
                      target="_blank"
                    >
                      {{ $app.appUrlClient }}/{{
                        meta.routes[`${index}`]['slug']
                      }}
                    </a>
                    <template
                      v-if="
                        meta.routes[`${index}`]['slug'] !==
                          model.routes[`${index}`]['slug']
                      "
                    >
                      <span class="url-link-new">
                        <i class="fas fa-long-arrow-alt-right"></i>
                        {{ $app.appUrlClient }}/{{
                          model.routes[`${index}`]['slug']
                        }}
                      </span>
                    </template>
                  </div>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="$t('labels.seo')" id="seo">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-seo"
                v-if="locale.backend"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.seo') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('labels.meta.title')"
                  :label-for="`metadata.title.${index}`"
                  label-cols-sm="3"
                  :description="$t('labels.meta.title-note')"
                  :feedback="feedback('metadata.title.' + index)"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`metadata.title.${index}`"
                      :name="`metadata.title.${index}`"
                      v-model="model.metadata.title[`${index}`]"
                      :state="state('metadata.title.' + index)"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-window-maximize"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('metadata.title.' + index) }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.meta.description')"
                  :label-for="`metadata.description.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`metadata.description.${index}`"
                    :name="`metadata.description.${index}`"
                    :rows="3"
                    v-model="model.metadata.description[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.meta.keywords')"
                  :label-for="`metadata.keywords.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`metadata.keywords.${index}`"
                    :name="`metadata.keywords.${index}`"
                    :rows="3"
                    v-model="model.metadata.keywords[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="false"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `ParlourEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'parlour',
      resourceRoute: 'parlours',
      listPath: '/parlours',
      model: {
        // Data
        name: '',
        show_name: '',
        email: '',
        description: '',
        status: '',
        status_to: '',
        canceled_content: '',
        revise_content: '',
        position: 0,
        public: false,
        blocked: false,
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {},
      history: [],
      statuses_reviewed: {
        active: 'Active',
        revise: 'Revise',
        suspended: 'Suspended'
        // correction: 'Data correction'
      },
      statuses_active: {
        suspended: 'Suspended'
        // correction: 'Data correction'
      },
      statuses_suspended: {
        active: 'Active'
      },
      statuses: {
        suspended: 'Suspended',
        revise: 'Revise'
      }
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('parlours.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('parlours.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {},
    statusRender(status) {
      return (
        '<span class="badge badge-' +
        status +
        '">' +
        this.trans('escorts.status.' + status) +
        '<span>'
      )
    }
  }
}
</script>
